import React from 'react';
import { ControllerRenderProps } from 'react-hook-form';
import Select from 'react-select';
import styled from 'styled-components';
import { CommonSelectItem } from '../../../types/model/commonSelectItem';
import { selectStyles } from '../../../styles/reactSelect';
import { WhiteBorderButton } from '../../common/Buttons';
import { LineDetailsFormInput } from './LineDetailsForm';

const memberGroupOptions: CommonSelectItem[] = [
  { value: 'administrator', label: 'Administrator' },
  { value: 'reseller', label: 'Reseller' },
  { value: 'user', label: 'User' },
];

export const findMemberGroupOption = (value: string) => {
  return memberGroupOptions.find(option => option.value === value);
}

interface Props {
  className?: string;
  field: ControllerRenderProps<LineDetailsFormInput, 'memberGroup'>;
}

const MemberGroup = ({ className, field }: Props) => {
  return (
    <MemberGroupComponents className={className}>
      <Select<CommonSelectItem>
        className='form-item-select'
        options={memberGroupOptions}
        styles={selectStyles}
        placeholder='Autogenerated if blank'
        {...field}
      />
      <WhiteBorderButton
        className='clear-button'
        onClick={() => {
          field.onChange([]);
        }}
      >
        Clear
      </WhiteBorderButton>
    </MemberGroupComponents>
  );
};

const MemberGroupComponents = styled.div`
  .clear-button {
    margin-left: 10px;
  }
`;

export default MemberGroup;
