import { createGlobalStyle } from 'styled-components';

const ElementsStyles = createGlobalStyle`
  *{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    color: var(--white);
    scroll-behavior: smooth;
  }

  html{
    background-color: var(--dark-bg);
    height: 100%;
    overflow: hidden;
  }

  ul,li{
    list-style: none;
  }

  a{
    text-decoration: none;
  }

  button{
    border: none;
    outline: none;
  }

  input, textarea{
    background-color: transparent;
    border: none;

    &::placeholder {
      color: var(--placeholder-dark);
    }
  }

  input[type="number"]{
    text-align: center;
    max-width: 150px;
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
  }

  img {
    object-fit: cover;
  }


  
  /* Scrollbar Styling */

  /* Works on Firefox */

  * {
    scrollbar-width: thin;
    scrollbar-color: var(--light-grey) var(--dark-bg);
  }

  /* Works on Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 6px;
  }

  *::-webkit-scrollbar-track {
    background: var(--dark-bg);
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
  }

  *::-webkit-scrollbar-thumb {
    background-color: var(--light-grey);
    border-radius: 5px;
  }

  *::-webkit-scrollbar-thumb:hover {
    background-color: var(--text-secondary);
  }
`;

export default ElementsStyles;
