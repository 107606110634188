import React from 'react';
import styled from 'styled-components';
import FormTab from '../common/FormTab';
import FormTitle from '../common/FormTitle';
import { TabData } from '../common/Tab';
import CategoriesMoviesForm from './CategoriesMoviesForm';
import CategoriesRadioForm from './CategoriesRadioForm';
import CategoriesSeriesForm from './CategoriesSeriesForm';
import CategoriesStreamsForm from './CategoriesStreamsForm';

const tabData: TabData[] = [
  {
    id: 1,
    tabTitle: 'Streams',
    tabComponent: CategoriesStreamsForm,
  },
  {
    id: 2,
    tabTitle: 'Movies',
    tabComponent: CategoriesMoviesForm,
  },
  {
    id: 3,
    tabTitle: 'Series',
    tabComponent: CategoriesSeriesForm,
  },
  {
    id: 4,
    tabTitle: 'Radio',
    tabComponent: CategoriesRadioForm,
  },
];

interface Props {
  className?: string;
}

const CategoriesForm = ({ className }: Props) => {
  return (
    <CategoriesFormComponent className={className}>
      <FormTitle>Categories</FormTitle>
      <FormTab data={tabData} currentTab={tabData[0].id} />
    </CategoriesFormComponent>
  );
};

const CategoriesFormComponent = styled.div`
  width: 755px;
  max-width: 100%;
  margin: 2px auto;
`;

export default CategoriesForm;
