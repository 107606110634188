import useRequest from '../../lib/hooks/useRequest';
import { deleteCategory } from '../../lib/api';
import { useEffect } from 'react';
import { useCategories } from './useCategories';

export const useDeleteCategory = () => {
  const [_deleteCategory, loading, _deleteCategoryRes, _deleteCategoryErr] =
    useRequest(deleteCategory);
  const {getAllCategories, getAllCategoriesRes} = useCategories();
  useEffect(() => {
    if (!_deleteCategoryRes) return;
    getAllCategories();
  }, [_deleteCategoryRes, getAllCategories]);

  useEffect(() => {
    if (_deleteCategoryErr) {
      console.log('_getAllCategoriesErr: ', _deleteCategoryErr);
    }
  }, [_deleteCategoryErr]);
  return { deleteCategory: _deleteCategory, deleteCategoryRes: getAllCategoriesRes, loading };
};
