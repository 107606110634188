import React from 'react';
import styled from 'styled-components';
import { Controller, useForm, SubmitHandler } from 'react-hook-form';
import { SubmitFormButton } from '../../common/Buttons';
import SlideToggle from '../../common/SlideToggle';
import DateFormat from './DateFormat';
import DatetimeFormat from './DatetimeFormat';
import ShowEntries from './ShowEntries';
import ServerTimeZone from './ServerTimeZone';
import Tooltip from './../../common/Tooltip';
import { settingGeneralTooltipMessage } from '../../../config/content/tooltipData';

export interface SettingsGeneralFormInput {
  serverName: string;
  serverTimeZone: string;
  messageOfDay: string;
  showEntries: string;
  failsPerTime: number;
  dateFormat: string;
  datetimeFormat: string;
  groupStreamsTable: boolean;
  seamlessNavigation: boolean;
  showTicketsIcon: boolean;
  disableRestartCounter: boolean;
  autoCleanupFiles: boolean;
  checkVodCron: boolean;
  showImagesAndPicons: boolean;
  groupButtons: boolean;
  quickEditModal: boolean;
  mySqlSleepTimeout: number;
  showGraphs: boolean;
  showConnectionsMap: boolean;
  alternateServerView: boolean;
  showHeaderStats: boolean;
  showServiceStatus: boolean;
  cpuThreshold: number;
  memoryThreshold: number;
  diskThreshold: number;
  networkThreshold: number;
  clientsThreshold: number;
  enableSearch: boolean;
  numberOfItems: number;
  disableTrials: boolean;
  sslCustomDns: boolean;
  debugMode: boolean;
  autoSendLogsToXui: boolean;
  enableReCaptcha: boolean;
  reCaptchaV2SiteKey: string;
  reCaptchaV2SecretKey: string;
  userAgent: string;
  httpProxy: string;
  cookie: string;
  headers: string;
  onDemandProbeSize: number;
}
interface Props {
  className?: string;
  setVisibleTab?: React.Dispatch<React.SetStateAction<number>>;
}

const SettingsGeneralForm = ({ className }: Props) => {
  const { handleSubmit, control } = useForm<SettingsGeneralFormInput>({
    defaultValues: {
      serverName: 'XUI.one',
      serverTimeZone: 'Europe/London',
      messageOfDay: 'Welcome to XUI.one',
      showEntries: '50',
      failsPerTime: 86400,
      dateFormat: 'Y-m-d',
      datetimeFormat: 'Y-m-d_H:i:s',
      groupStreamsTable: false,
      seamlessNavigation: false,
      showTicketsIcon: false,
      disableRestartCounter: false,
      autoCleanupFiles: false,
      checkVodCron: false,
      showImagesAndPicons: false,
      groupButtons: false,
      quickEditModal: false,
      mySqlSleepTimeout: 21600,
      showGraphs: false,
      showConnectionsMap: false,
      alternateServerView: false,
      showHeaderStats: false,
      showServiceStatus: false,
      cpuThreshold: 67,
      memoryThreshold: 67,
      diskThreshold: 67,
      networkThreshold: 67,
      clientsThreshold: 67,
      enableSearch: false,
      numberOfItems: 20,
      disableTrials: false,
      sslCustomDns: false,
      debugMode: false,
      autoSendLogsToXui: false,
      enableReCaptcha: false,
      reCaptchaV2SiteKey: '',
      reCaptchaV2SecretKey: '',
      userAgent: 'Mozilla/5.0',
      httpProxy: '',
      cookie: '',
      headers: '',
      onDemandProbeSize: 256000,
    },
  });

  const onSubmit: SubmitHandler<SettingsGeneralFormInput> = data => {
    console.log('data: ', data);
  };

  return (
    <SettingsGeneralFormComponent className={className}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='form-sub-title'>Preferences</div>
        <Controller
          control={control}
          name='serverName'
          rules={{ required: true }}
          render={({ field }) => (
            <div className='form-item'>
              <span className='form-item-name'>
                Server Name
                <Tooltip message={settingGeneralTooltipMessage.serverName} />
              </span>
              <div className='form-item-content'>
                <input
                  type='text'
                  className='form-item-input'
                  autoComplete='off'
                  placeholder=''
                  {...field}
                />
              </div>
            </div>
          )}
        />
        <Controller
          control={control}
          name='serverTimeZone'
          rules={{ required: true }}
          render={({ field }) => (
            <div className='form-item'>
              <span className='form-item-name'>
                Server Time Zone
                <Tooltip message={settingGeneralTooltipMessage.serverTimeZone} />
              </span>
              <ServerTimeZone
                className='form-item-content'
                value={field.value}
                onChange={(value: string) => field.onChange(value)}
              />
            </div>
          )}
        />
        <Controller
          control={control}
          name='messageOfDay'
          rules={{ required: true }}
          render={({ field }) => (
            <div className='form-item'>
              <span className='form-item-name'>
                Message Of the Day
                <Tooltip message={settingGeneralTooltipMessage.messageOfDay} />
              </span>
              <div className='form-item-content'>
                <input
                  type='text'
                  className='form-item-input'
                  autoComplete='off'
                  placeholder=''
                  {...field}
                />
              </div>
            </div>
          )}
        />
        <div className='form-item-two-col-card element-card'>
          <Controller
            control={control}
            name='showEntries'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Show Entries
                  <Tooltip message={settingGeneralTooltipMessage.showEntries} />
                </span>
                <ShowEntries
                  className='form-item-two-col-card-item-select'
                  value={field.value}
                  onChange={(value: string) => field.onChange(value)}
                />
              </div>
            )}
          />
          <Controller
            control={control}
            name='failsPerTime'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Fails Per Time
                  <Tooltip message={settingGeneralTooltipMessage.failsPerTime} />
                </span>
                <input
                  type='number'
                  className='form-item-two-col-card-item-input'
                  autoComplete='off'
                  placeholder=''
                  {...field}
                />
              </div>
            )}
          />
          <Controller
            control={control}
            name='dateFormat'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Date Format
                  <Tooltip message={settingGeneralTooltipMessage.dateFormat} />
                </span>
                <DateFormat
                  className='form-item-two-col-card-item-select'
                  value={field.value}
                  onChange={(value: string) => field.onChange(value)}
                />
              </div>
            )}
          />
          <Controller
            control={control}
            name='datetimeFormat'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Datetime Format
                  <Tooltip message={settingGeneralTooltipMessage.datetimeFormat} />
                </span>
                <DatetimeFormat
                  className='form-item-two-col-card-item-select'
                  value={field.value}
                  onChange={(value: string) => field.onChange(value)}
                />
              </div>
            )}
          />
          <Controller
            control={control}
            name='groupStreamsTable'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Group Streams Table
                  <Tooltip message={settingGeneralTooltipMessage.groupStreamsTable} />
                </span>
                <SlideToggle value={field.value} onChange={field.onChange} />
              </div>
            )}
          />
          <Controller
            control={control}
            name='seamlessNavigation'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Seamless Navigation
                  <Tooltip message={settingGeneralTooltipMessage.seamlessNavigation} />
                </span>
                <SlideToggle value={field.value} onChange={field.onChange} />
              </div>
            )}
          />
          <Controller
            control={control}
            name='showTicketsIcon'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Show Tickets Icon
                  <Tooltip message={settingGeneralTooltipMessage.showTicketsIcon} />
                </span>
                <SlideToggle value={field.value} onChange={field.onChange} />
              </div>
            )}
          />
          <Controller
            control={control}
            name='disableRestartCounter'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Disable Restart Counter
                  <Tooltip message={settingGeneralTooltipMessage.disableRestartCounter} />
                </span>
                <SlideToggle value={field.value} onChange={field.onChange} />
              </div>
            )}
          />
          <Controller
            control={control}
            name='autoCleanupFiles'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Auto Cleanup Files
                  <Tooltip message={settingGeneralTooltipMessage.autoCleanupFiles} />
                </span>
                <SlideToggle value={field.value} onChange={field.onChange} />
              </div>
            )}
          />
          <Controller
            control={control}
            name='checkVodCron'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Check VOD Cron
                  <Tooltip message={settingGeneralTooltipMessage.checkVodCron} />
                </span>
                <SlideToggle value={field.value} onChange={field.onChange} />
              </div>
            )}
          />
          <Controller
            control={control}
            name='showImagesAndPicons'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Show Images & Picons
                  <Tooltip message={settingGeneralTooltipMessage.showImagesAndPicons} />
                </span>
                <SlideToggle value={field.value} onChange={field.onChange} />
              </div>
            )}
          />
          <Controller
            control={control}
            name='groupButtons'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Group Buttons
                  <Tooltip message={settingGeneralTooltipMessage.groupButtons} />
                </span>
                <SlideToggle value={field.value} onChange={field.onChange} />
              </div>
            )}
          />
          <Controller
            control={control}
            name='quickEditModal'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Quick Edit Modal
                  <Tooltip message={settingGeneralTooltipMessage.quickEditModal} />
                </span>
                <SlideToggle value={field.value} onChange={field.onChange} />
              </div>
            )}
          />
          <Controller
            control={control}
            name='mySqlSleepTimeout'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  MySQL Sleep Timeout
                  <Tooltip message={settingGeneralTooltipMessage.mySqlSleepTimeout} />
                </span>
                <input
                  type='number'
                  className='form-item-two-col-card-item-input'
                  autoComplete='off'
                  placeholder=''
                  {...field}
                />
              </div>
            )}
          />
        </div>

        <div className='form-sub-title'>Dashboard</div>
        <div className='form-item-two-col-card element-card'>
          <Controller
            control={control}
            name='showGraphs'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Show Graphs
                  <Tooltip message={settingGeneralTooltipMessage.showGraphs} />
                </span>
                <SlideToggle value={field.value} onChange={field.onChange} />
              </div>
            )}
          />
          <Controller
            control={control}
            name='showConnectionsMap'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Show Connections Map
                  <Tooltip message={settingGeneralTooltipMessage.showConnectionsMap} />
                </span>
                <SlideToggle value={field.value} onChange={field.onChange} />
              </div>
            )}
          />
          <Controller
            control={control}
            name='alternateServerView'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Alternate Server View
                  <Tooltip message={settingGeneralTooltipMessage.alternateServerView} />
                </span>
                <SlideToggle value={field.value} onChange={field.onChange} />
              </div>
            )}
          />
          <Controller
            control={control}
            name='showHeaderStats'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Show Header Stats
                  <Tooltip message={settingGeneralTooltipMessage.showHeaderStats} />
                </span>
                <SlideToggle value={field.value} onChange={field.onChange} />
              </div>
            )}
          />
          <Controller
            control={control}
            name='showServiceStatus'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Show Service Status
                  <Tooltip message={settingGeneralTooltipMessage.showServiceStatus} />
                </span>
                <SlideToggle value={field.value} onChange={field.onChange} />
              </div>
            )}
          />
          <Controller
            control={control}
            name='cpuThreshold'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  CPU Threshold %
                  <Tooltip message={settingGeneralTooltipMessage.cpuThreshold} />
                </span>
                <input
                  type='number'
                  className='form-item-two-col-card-item-input'
                  autoComplete='off'
                  placeholder=''
                  {...field}
                />
              </div>
            )}
          />
          <Controller
            control={control}
            name='memoryThreshold'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Memory Threshold %
                  <Tooltip message={settingGeneralTooltipMessage.memoryThreshold} />
                </span>
                <input
                  type='number'
                  className='form-item-two-col-card-item-input'
                  autoComplete='off'
                  placeholder=''
                  {...field}
                />
              </div>
            )}
          />
          <Controller
            control={control}
            name='diskThreshold'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Disk Threshold %
                  <Tooltip message={settingGeneralTooltipMessage.diskThreshold} />
                </span>
                <input
                  type='number'
                  className='form-item-two-col-card-item-input'
                  autoComplete='off'
                  placeholder=''
                  {...field}
                />
              </div>
            )}
          />
          <Controller
            control={control}
            name='networkThreshold'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Network Threshold %
                  <Tooltip message={settingGeneralTooltipMessage.networkThreshold} />
                </span>
                <input
                  type='number'
                  className='form-item-two-col-card-item-input'
                  autoComplete='off'
                  placeholder=''
                  {...field}
                />
              </div>
            )}
          />
          <Controller
            control={control}
            name='clientsThreshold'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Clients Threshold %
                  <Tooltip message={settingGeneralTooltipMessage.clientsThreshold} />
                </span>
                <input
                  type='number'
                  className='form-item-two-col-card-item-input'
                  autoComplete='off'
                  placeholder=''
                  {...field}
                />
              </div>
            )}
          />
        </div>

        <div className='form-sub-title'>Search</div>
        <div className='form-item-two-col-card element-card'>
          <Controller
            control={control}
            name='enableSearch'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Enable Search
                  <Tooltip message={settingGeneralTooltipMessage.enableSearch} />
                </span>
                <SlideToggle value={field.value} onChange={field.onChange} />
              </div>
            )}
          />
          <Controller
            control={control}
            name='numberOfItems'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Number Of Items
                  <Tooltip message={settingGeneralTooltipMessage.numberOfItems} />
                </span>
                <input
                  type='number'
                  className='form-item-two-col-card-item-input'
                  autoComplete='off'
                  placeholder=''
                  {...field}
                />
              </div>
            )}
          />
        </div>

        <div className='form-sub-title'>Reseller</div>
        <div className='form-item-two-col-card element-card'>
          <Controller
            control={control}
            name='disableTrials'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Disable Trials
                  <Tooltip message={settingGeneralTooltipMessage.disableTrials} />
                </span>
                <SlideToggle value={field.value} onChange={field.onChange} />
              </div>
            )}
          />
          <Controller
            control={control}
            name='sslCustomDns'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  SSL Custom DNS
                  <Tooltip message={settingGeneralTooltipMessage.sslCustomDns} />
                </span>
                <SlideToggle value={field.value} onChange={field.onChange} />
              </div>
            )}
          />
        </div>

        <div className='form-sub-title'>Debug</div>
        <div className='form-item-two-col-card element-card'>
          <Controller
            control={control}
            name='debugMode'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Debug Mode
                  <Tooltip message={settingGeneralTooltipMessage.debugMode} />
                </span>
                <SlideToggle value={field.value} onChange={field.onChange} />
              </div>
            )}
          />
          <Controller
            control={control}
            name='autoSendLogsToXui'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Auto-Send Logs To XUI
                  <Tooltip message={settingGeneralTooltipMessage.autoSendLogsToXui} />
                </span>
                <SlideToggle value={field.value} onChange={field.onChange} />
              </div>
            )}
          />
        </div>

        <div className='form-sub-title'>reCaptcha</div>
        <Controller
          control={control}
          name='enableReCaptcha'
          rules={{ required: false }}
          render={({ field }) => (
            <div className='form-item'>
              <span className='form-item-name'>
                Enable reCaptcha
                <Tooltip message={settingGeneralTooltipMessage.enableReCaptcha} />
              </span>
              <SlideToggle value={field.value} onChange={field.onChange} />
            </div>
          )}
        />
        <Controller
          control={control}
          name='reCaptchaV2SiteKey'
          rules={{ required: true }}
          render={({ field }) => (
            <div className='form-item'>
              <span className='form-item-name'>
                reCaptcha V2-Site Key
                <Tooltip message={settingGeneralTooltipMessage.reCaptchaV2SiteKey} />
              </span>
              <div className='form-item-content'>
                <input
                  type='text'
                  className='form-item-input'
                  autoComplete='off'
                  placeholder=''
                  {...field}
                />
              </div>
            </div>
          )}
        />
        <Controller
          control={control}
          name='reCaptchaV2SecretKey'
          rules={{ required: true }}
          render={({ field }) => (
            <div className='form-item'>
              <span className='form-item-name'>
                reCaptcha V2-Secret Key
                <Tooltip message={settingGeneralTooltipMessage.reCaptchaV2SecretKey} />
              </span>
              <div className='form-item-content'>
                <input
                  type='text'
                  className='form-item-input'
                  autoComplete='off'
                  placeholder=''
                  {...field}
                />
              </div>
            </div>
          )}
        />

        <div className='form-sub-title'>Default Arguments</div>
        <Controller
          control={control}
          name='userAgent'
          rules={{ required: true }}
          render={({ field }) => (
            <div className='form-item'>
              <span className='form-item-name'>User Agent</span>
              <div className='form-item-content'>
                <input
                  type='text'
                  className='form-item-input'
                  autoComplete='off'
                  placeholder=''
                  {...field}
                />
              </div>
            </div>
          )}
        />
        <Controller
          control={control}
          name='httpProxy'
          rules={{ required: true }}
          render={({ field }) => (
            <div className='form-item'>
              <span className='form-item-name'>
                HTTP Proxy
                <Tooltip message={settingGeneralTooltipMessage.httpProxy} />
              </span>
              <div className='form-item-content'>
                <input
                  type='text'
                  className='form-item-input'
                  autoComplete='off'
                  placeholder=''
                  {...field}
                />
              </div>
            </div>
          )}
        />
        <Controller
          control={control}
          name='cookie'
          rules={{ required: true }}
          render={({ field }) => (
            <div className='form-item'>
              <span className='form-item-name'>
                Cookie
                <Tooltip message={settingGeneralTooltipMessage.cookie} />
              </span>
              <div className='form-item-content'>
                <input
                  type='text'
                  className='form-item-input'
                  autoComplete='off'
                  placeholder=''
                  {...field}
                />
              </div>
            </div>
          )}
        />
        <Controller
          control={control}
          name='headers'
          rules={{ required: true }}
          render={({ field }) => (
            <div className='form-item'>
              <span className='form-item-name'>
                Headers
                <Tooltip message={settingGeneralTooltipMessage.headers} />
              </span>
              <div className='form-item-content'>
                <input
                  type='text'
                  className='form-item-input'
                  autoComplete='off'
                  placeholder=''
                  {...field}
                />
              </div>
            </div>
          )}
        />
        <Controller
          control={control}
          name='onDemandProbeSize'
          rules={{ required: true }}
          render={({ field }) => (
            <div className='form-item'>
              <span className='form-item-name'>
                On Demand Probesize
                <Tooltip message={settingGeneralTooltipMessage.onDemandProbeSize} />
              </span>
              <div className='form-item-content'>
                <input
                  type='number'
                  className='form-item-input'
                  autoComplete='off'
                  placeholder=''
                  {...field}
                />
              </div>
            </div>
          )}
        />

        <SubmitFormButton className='full-width'>Save</SubmitFormButton>
      </form>
    </SettingsGeneralFormComponent>
  );
};

const SettingsGeneralFormComponent = styled.div``;

export default SettingsGeneralForm;
