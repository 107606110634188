import useRequest from '../../lib/hooks/useRequest';
import { deleteBouquet } from '../../lib/api';
import { useEffect } from 'react';
import { useBouquets } from './useBouquets';

export const useDeleteBouquet = () => {
  const [_deleteBouquet, loading, _deleteBouquetRes, _deleteBouquetErr] = useRequest(deleteBouquet);
  const { getAllBouquets, getAllBouquetsRes } = useBouquets();
  useEffect(() => {
    if (!_deleteBouquetRes) return;
    getAllBouquets();
  }, [_deleteBouquetRes, getAllBouquets]);

  useEffect(() => {
    if (_deleteBouquetErr) {
      console.log('_getAllBouquetsErr: ', _deleteBouquetErr);
    }
  }, [_deleteBouquetErr]);
  return { deleteBouquet: _deleteBouquet, deleteBouquetRes: getAllBouquetsRes, loading };
};
