export const statsURL = {
  getServerStats: '/stats/server',
  getUsersByStream: '/stats',
};

export const usersURL = {
  insertUser: '/user',
  getUser: '/user',
  updateUser: '/user',
  deleteUser: '/user',
  getAllUsers: '/users',
};

export const streamsURL = {
  insertStream: '/stream',
  startStream: '/stream',
  stopStream: '/stream',
  getStream: '/stream',
  updateStream: '/stream',
  deleteStream: '/stream',
  getAllStreams: '/streams',
  importStreams: '/streams/import',
};

export const categoriesURL = {
  insertCategory: '/category',
  getCategory: '/category',
  editCategory: '/category',
  deleteCategory: '/category',
  getAllCategories: '/categories',
};

export const bouquetsURL = {
  insertBouquet: '/bouquet',
  getBouquet: '/bouquet',
  editBouquet: '/bouquet',
  deleteBouquet: '/bouquet',
  getAllBouquets: '/bouquets',
};
