import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Controller, useForm, SubmitHandler } from 'react-hook-form';
import FormFooter from '../common/FormFooter';
import SlideToggle from '../common/SlideToggle';
import { ICategory as Category } from '../../types/model/category';

export interface CategoriesDetailsFormInput {
  categoriesType: string;
  categoriesName: string;
  isAdult: boolean;
}
interface Props {
  className?: string;
  formData?: Category;
  submitFormData?: (data: Category) => void;
  isEdit?: boolean;
}

const CategoriesDetailsForm = ({ className, formData, submitFormData, isEdit = false }: Props) => {
  const { handleSubmit, setValue, control } = useForm<CategoriesDetailsFormInput>({
    defaultValues: {
      categoriesType: formData?.type ?? '',
      categoriesName: formData?.name ?? '',
      isAdult: false,
    },
  });

  useEffect(() => {
    setValue('categoriesType', formData?.type ?? '');
    setValue('categoriesName', formData?.name ?? '');
    setValue('isAdult', formData?.isAdult ?? false);
  }, [formData, setValue]);

  const onSubmit: SubmitHandler<CategoriesDetailsFormInput> = data => {
    if (submitFormData)
      submitFormData({
        ...formData,
        type: data.categoriesType,
        name: data.categoriesName,
        isAdult: data.isAdult,
      });
  };

  return (
    <CategoriesDetailsFormComponent className={className}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={control}
          name='categoriesType'
          rules={{ required: false }}
          render={({ field }) => (
            <div className='form-item'>
              <span className='form-item-name'>Category Type</span>
              <div className='form-item-content'>
                <input type='text' className='form-item-input' autoComplete='off' {...field} />
              </div>
            </div>
          )}
        />
        <Controller
          control={control}
          name='categoriesName'
          rules={{ required: true }}
          render={({ field }) => (
            <div className='form-item'>
              <span className='form-item-name'>Category Name</span>
              <div className='form-item-content'>
                <input type='text' className='form-item-input' autoComplete='off' {...field} />
              </div>
            </div>
          )}
        />
        <Controller
          control={control}
          name='isAdult'
          rules={{ required: false }}
          render={({ field }) => (
            <div className='form-item'>
              <span className='form-item-name'>Adult Contents</span>
              <SlideToggle value={field.value} onChange={field.onChange} />
            </div>
          )}
        />
        <FormFooter
          hidePrev
          noIconOnNext
          nextButtonTitle={`${isEdit ? 'Edit' : 'Add'} Categories`}
        />
      </form>
    </CategoriesDetailsFormComponent>
  );
};

const CategoriesDetailsFormComponent = styled.div`
  .eye-button {
    position: absolute;
    right: 0;
    padding: 0 12px;
  }
`;

export default CategoriesDetailsForm;
