import React from 'react';
import Select from 'react-select';
import styled from 'styled-components';
import { settingSelectStyles } from '../../../styles/reactSelect';
import { CommonSelectItem } from '../../../types/model/commonSelectItem';

const vodParserOptions: CommonSelectItem[] = [
  { value: 'guesslt', label: 'Guesslt' },
  { value: 'ptn', label: 'PTN' },
];

interface Props {
  className?: string;
  value: string;
  onChange: (value: string) => void;
}

const VodParser = ({ className, value, onChange }: Props) => {
  return (
    <VodParserComponents className={className}>
      <Select<CommonSelectItem>
        className='form-item-select'
        options={vodParserOptions}
        styles={settingSelectStyles}
        value={vodParserOptions[vodParserOptions.findIndex(option => option.value === value)]}
        onChange={newValue => onChange(newValue?.value ?? '')}
      />
    </VodParserComponents>
  );
};

const VodParserComponents = styled.div``;

export default VodParser;
