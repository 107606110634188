import { atom } from 'jotai';
import { ServerStats } from '../types/model/serverStats';
import { Category } from '../types/model/category';
import { Bouquet } from '../types/model/bouquet';
import { User } from '../types/model/user';
import { Stream } from '../types/model/stream';

export const serverStatsAtom = atom<ServerStats>({});

export const categoriesAtom = atom<Category[]>([]);

export const bouquetsAtom = atom<Bouquet[]>([]);

export const usersAtom = atom<User[]>([]);

export const streamsAtom = atom<Stream[]>([]);