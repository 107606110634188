import React from 'react';
import styled from 'styled-components';
import FormTab from '../common/FormTab';
import FormTitle from '../common/FormTitle';
import { TabData } from '../common/Tab';
import SettingsApiForm from './api/SettingsApiForm';
import SettingsGeneralForm from './general/SettingsGeneralForm';
import SettingsLogsForm from './logs/SettingsLogsForm';
import SettingsMAGForm from './mag/SettingsMAGForm';
import SettingsSecurityForm from './security/SettingsSecurityForm';
import SettingsStreamingForm from './streaming/SettingsStreamingForm';
import SettingsWebPlayerForm from './webPlayer/SettingsWebPlayerForm';

const tabData: TabData[] = [
  {
    id: 1,
    tabTitle: 'General',
    tabComponent: SettingsGeneralForm,
  },
  {
    id: 2,
    tabTitle: 'Security',
    tabComponent: SettingsSecurityForm,
  },
  {
    id: 3,
    tabTitle: 'API',
    tabComponent: SettingsApiForm,
  },
  {
    id: 4,
    tabTitle: 'Streaming',
    tabComponent: SettingsStreamingForm,
  },
  {
    id: 5,
    tabTitle: 'MAG',
    tabComponent: SettingsMAGForm,
  },
  {
    id: 6,
    tabTitle: 'Web Player',
    tabComponent: SettingsWebPlayerForm,
  },
  {
    id: 7,
    tabTitle: 'Logs',
    tabComponent: SettingsLogsForm,
  },
];

interface Props {
  className?: string;
}

const SettingsForm = ({ className }: Props) => {
  return (
    <SettingsFormComponent className={className}>
      <FormTitle>General Settings</FormTitle>
      <FormTab data={tabData} currentTab={tabData[0].id} />
    </SettingsFormComponent>
  );
};

const SettingsFormComponent = styled.div`
  width: 755px;
  max-width: 100%;
  margin: 2px auto;
`;

export default SettingsForm;
