import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import FormTab from '../common/FormTab';
import FormTitle from '../common/FormTitle';
import { TabData } from '../common/Tab';
import StreamDetailsForm from './details/StreamDetailsForm';
import StreamSourcesForm from './sources/StreamSourcesForm';
// import StreamAdvancedForm from './advanced/StreamAdvancedForm';
// import StreamMapForm from './map/StreamMapForm';
// import StreamServersForm from './servers/StreamServersForm';
// import StreamEPGForm from './epg/StreamEPGForm';
// import StreamRTMPushForm from './rtmPush/StreamRTMPushForm';
import { Stream } from '../../types/model/stream';
import { useNavigate } from 'react-router-dom';
import { useInsertStream } from '../../hooks/stream/useInsertStream';

const tabData: TabData[] = [
  {
    id: 1,
    tabTitle: 'Details',
    tabComponent: StreamDetailsForm,
  },
  {
    id: 2,
    tabTitle: 'Sources',
    tabComponent: StreamSourcesForm,
  },
  // {
  //   id: 3,
  //   tabTitle: 'Advanced',
  //   tabComponent: StreamAdvancedForm,
  // },
  // {
  //   id: 4,
  //   tabTitle: 'Map',
  //   tabComponent: StreamMapForm,
  // },
  // {
  //   id: 5,
  //   tabTitle: 'EPG',
  //   tabComponent: StreamEPGForm,
  // },
  // {
  //   id: 6,
  //   tabTitle: 'RTM Push',
  //   tabComponent: StreamRTMPushForm,
  // },
  // {
  //   id: 7,
  //   tabTitle: 'Servers',
  //   tabComponent: StreamServersForm,
  // },
];

interface Props {
  className?: string;
}

const AddStreamForm = ({ className }: Props) => {
  const navigate = useNavigate();
  const [streamData, setStreamData] = useState<Stream>();
  const { insertStream, completed } = useInsertStream();

  useEffect(() => {
    if (completed) navigate('/streams/manage-streams');
  }, [completed, navigate]);

  const submitStreamData = async (data: Stream) => {
    insertStream(data);
  };
  return (
    <AddStreamFormComponent className={className}>
      <FormTitle>Add Stream</FormTitle>
      <FormTab
        data={tabData}
        formData={streamData}
        currentTab={tabData[0].id}
        setFormData={setStreamData}
        submitFormData={submitStreamData}
      />
    </AddStreamFormComponent>
  );
};

const AddStreamFormComponent = styled.div`
  width: 700px;
  max-width: 100%;
  margin: 2px auto;
`;

export default AddStreamForm;
