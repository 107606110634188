import React from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { CircleExclamationSvg } from '../../assets/svg';
import FormFooter from '../common/FormFooter';
import DragAndDropCard, { DndData } from '../common/DragAndDropCard';

export interface CategoriesSeriesFormInput {
  series: DndData[];
}

interface Props {
  className?: string;
  setVisibleTab?: React.Dispatch<React.SetStateAction<number>>;
}

const CategoriesSeriesForm = ({ className }: Props) => {
  const deleteMessage =
    'Are you sure you want to delete this category? All series attached will be uncategorized.';
  const warningMessage = (
    <p>
      To re-order a category, drag it up or down the list using the &#8803; icon. Click Save Changes
      at the bottom once finished.
    </p>
  );

  const { handleSubmit, control } = useForm<CategoriesSeriesFormInput>({
    defaultValues: {
      series: initialData,
    },
  });

  const onSubmit: SubmitHandler<CategoriesSeriesFormInput> = data => {
    console.log('data: ', data);
  };

  return (
    <CategoriesSeriesFormComponents className={className}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='form-warning element-card'>
          <CircleExclamationSvg style={{ flexShrink: 0 }} />
          {warningMessage}
        </div>

        <Controller
          control={control}
          name='series'
          rules={{ required: false }}
          render={({ field }) => (
            <DragAndDropCard
              value={field.value}
              onChange={field.onChange}
              deleteMessage={deleteMessage}
            />
          )}
        />

        <FormFooter hidePrev noIconOnNext nextButtonTitle='Save Changes' />
      </form>
    </CategoriesSeriesFormComponents>
  );
};

const CategoriesSeriesFormComponents = styled.div``;

export default CategoriesSeriesForm;

const initialData: DndData[] = [
  {
    id: 'spania',
    label: 'Spania',
    description: 'Spania is ...',
  },
  {
    id: 'romania',
    label: 'Romania',
    description: 'Romania is ...',
  },
  {
    id: 'series2',
    label: 'Series 2',
    description: 'Series 2 is ...',
  },
  {
    id: 'series3',
    label: 'Series 3',
    description: 'Series 3 is ...',
  },
];
