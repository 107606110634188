import { createGlobalStyle } from 'styled-components';

const ModalStyles = createGlobalStyle`
  .modal-table-icon-bg {
    width: 76px;
    height: 24px;
    margin: 0 auto;
    border-radius: 8px;
    background-color: var(--white);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }

  .modal-small-form-container {
    width: 100%;
    max-width: 520px;
    margin: 0 auto;
  }

  .modal-form-item-image {
    margin: 12px 0;
    width: 100%;
    padding: 10px;
    margin: 0 auto;
    border-radius: 8px;
    background-color: var(--white);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }

  .modal-warning {
    width: 840px;
    max-width: 85%;
    max-height: 85%;
    overflow: auto;
    padding: 48px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .modal-warning-message {
    font-size: 32px;
    padding: 48px 16px;
    text-align: center;
  }

  .modal-warning-footer {
    margin: 12px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }

  .hidden-button {
    visibility: hidden;
  }
`;

export default ModalStyles;
