import { useEffect, useMemo } from 'react';
import { insertStream } from '../../lib/api';
import useRequest from '../../lib/hooks/useRequest';
import { isAxiosError } from 'axios';
import { useCategories } from '../category/useCategories';
import { useBouquets } from '../bouquet/useBouquets';

export const useInsertStream = () => {
  const { getAllCategories, getAllCategoriesRes } = useCategories();
  const { getAllBouquets, getAllBouquetsRes } = useBouquets();
  const completed = useMemo(
    () => (getAllCategoriesRes && getAllBouquetsRes) as boolean,
    [getAllCategoriesRes, getAllBouquetsRes],
  );
  const [_insertStream, , insertStreamRes, insertStreamErr] = useRequest(insertStream);
  useEffect(() => {
    if (insertStreamRes) {
      getAllCategories();
      getAllBouquets();
    }
  }, [getAllBouquets, getAllCategories, insertStreamRes]);
  useEffect(() => {
    if (insertStreamErr) {
      if (isAxiosError(insertStreamErr)) {
        console.log('insertStreamErrText: ', insertStreamErr.response?.data.error);
        console.log('insertStreamErrMessage: ', insertStreamErr.response?.data.message);
      } else {
        console.log('insertStreamErr: ', insertStreamErr);
      }
    }
  }, [insertStreamErr]);
  return { insertStream: _insertStream, completed };
};
