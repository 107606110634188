import React from 'react';
import styled from 'styled-components';
import Tab, { TabData } from './Tab';

interface Props {
  data: TabData[];
  currentTab: number;
  onChangeTab: (id: number) => void;
}

const PageTab = ({ data, currentTab, onChangeTab }: Props) => {
  return (
    <PageTabComponent className='card'>
      <Tab
        data={data}
        currentTab={currentTab}
        onChangeTab={onChangeTab}
        className='tab'
        tabTitleBarStyle='tab-title-bar'
        tabTitleStyle='tab-title'
        tabTitleActiveStyle='tab-title-active'
        tabContentStyle='tab-content'
      />
    </PageTabComponent>
  );
};

const PageTabComponent = styled.div`
  width: 100%;
  min-height: 100%;

  .tab {
    padding: 18px 20px;
  }

  .tab-title-bar {
    width: fit-content;
    border-radius: 8px;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    background-color: var(--dark-bg);
    padding: 2px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    cursor: pointer;
  }

  .tab-title {
    padding: 10px 14px;
    font-size: 16px;
    border-radius: 8px;

    &:hover {
      background: radial-gradient(
          100% 100% at 50.63% 0%,
          rgba(151, 71, 255, 0.16) 0%,
          rgba(151, 71, 255, 0) 100%
        ),
        radial-gradient(
          45.01% 84.62% at 50.63% 100%,
          rgba(151, 71, 255, 0.6) 0%,
          rgba(151, 71, 255, 0) 100%
        ),
        rgba(255, 255, 255, 0.1);
      box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.47), 0px 5px 11px rgba(151, 71, 255, 0.22);
    }

    &:active {
      background: radial-gradient(50.08% 76.92% at 50.63% 0%, #000000 0%, rgba(0, 0, 0, 0) 100%),
        rgba(61, 60, 60, 0.2);
      box-shadow: inset 0px 9px 10px rgba(0, 0, 0, 0.25);
    }
  }

  .tab-title-active {
    background: radial-gradient(
      45.01% 84.62% at 50.63% 100%,
      rgba(151, 71, 255, 0.6) 0%,
      rgba(151, 71, 255, 0) 100%
    );
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.07));
  }

  .tab-content {
  }
`;

export default PageTab;
