import React from 'react';
import styled from 'styled-components';
import { Controller, useForm, SubmitHandler } from 'react-hook-form';
import { SubmitFormButton } from '../../common/Buttons';
import SlideToggle from '../../common/SlideToggle';
import TmdbLanguage from './TmdbLanguage';
import AppendMovieYear from './AppendMovieYear';
import ApiContainer from './ApiContainer';
import VodParser from './VodParser';
import AppendExtraData from './AppendExtraData';
import Tooltip from '../../common/Tooltip';
import { settingApiTooltipMessage } from '../../../config/content/tooltipData';

export interface SettingsApiFormInput {
  tmdbKey: string;
  tmdbLanguage: string;
  downloadImages: boolean;
  apiRedirect: boolean;
  appendMovieYear: string;
  apiContainer: string;
  cachePlaylistsFor: number;
  grabPlaylistFromMySql: boolean;
  forceEpgToUtcTimezone: boolean;
  keepRequestProtocol: boolean;
  vodParser: string;
  enableCloudflare: boolean;
  legacyPlaylistUrl: boolean;
  legacyXmlTvUrl: boolean;
  legacyPanelApi: boolean;
  duplicateStreamsInLegacyApps: boolean;
  enableEpgApi: boolean;
  appendExtraData: string;
  daysToFetch: number;
  daysToKeep: number;
  adminStreamingIps: string;
  apiIps: string;
  apiPassword: string;
  disableEpgDownloadLine: boolean;
  disableEpgDownloadRestreamer: boolean;
  disablePlaylistDownloadLine: boolean;
  disablePlaylistDownloadRestreamer: boolean;
  disablePlayerApi: boolean;
  disableEnigma2Api: boolean;
  disableMinistraApi: boolean;
  verifyHosts: boolean;
  streamingPassword: string;
}
interface Props {
  className?: string;
  setVisibleTab?: React.Dispatch<React.SetStateAction<number>>;
}

const SettingsApiForm = ({ className }: Props) => {
  const { handleSubmit, control } = useForm<SettingsApiFormInput>({
    defaultValues: {
      tmdbKey: '',
      tmdbLanguage: 'en',
      downloadImages: false,
      apiRedirect: false,
      appendMovieYear: 'brackets',
      apiContainer: 'mpegTs',
      cachePlaylistsFor: 0,
      grabPlaylistFromMySql: false,
      forceEpgToUtcTimezone: false,
      keepRequestProtocol: false,
      vodParser: 'ptn',
      enableCloudflare: false,
      legacyPlaylistUrl: false,
      legacyXmlTvUrl: false,
      legacyPanelApi: false,
      duplicateStreamsInLegacyApps: false,
      enableEpgApi: false,
      appendExtraData: 'extendedDescription',
      daysToFetch: 7,
      daysToKeep: 7,
      adminStreamingIps: '',
      apiIps: '',
      apiPassword: '',
      disableEpgDownloadLine: false,
      disableEpgDownloadRestreamer: false,
      disablePlaylistDownloadLine: false,
      disablePlaylistDownloadRestreamer: false,
      disablePlayerApi: false,
      disableEnigma2Api: false,
      disableMinistraApi: false,
      verifyHosts: false,
      streamingPassword: '',
    },
  });

  const onSubmit: SubmitHandler<SettingsApiFormInput> = data => {
    console.log('data: ', data);
  };

  return (
    <SettingsApiFormComponent className={className}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='form-sub-title'>Preferences</div>
        <Controller
          control={control}
          name='tmdbKey'
          rules={{ required: true }}
          render={({ field }) => (
            <div className='form-item'>
              <span className='form-item-name'>
                TMDb Key
                <Tooltip message={settingApiTooltipMessage.tmdbKey} />
              </span>
              <div className='form-item-content'>
                <input
                  type='text'
                  className='form-item-input'
                  autoComplete='off'
                  placeholder=''
                  {...field}
                />
              </div>
            </div>
          )}
        />
        <Controller
          control={control}
          name='tmdbLanguage'
          rules={{ required: true }}
          render={({ field }) => (
            <div className='form-item'>
              <span className='form-item-name'>
                TMDb Language
                <Tooltip message={settingApiTooltipMessage.tmdbLanguage} />
              </span>
              <TmdbLanguage
                className='form-item-content'
                value={field.value}
                onChange={(value: string) => field.onChange(value)}
              />
            </div>
          )}
        />
        <div className='form-item-two-col-card element-card'>
          <Controller
            control={control}
            name='downloadImages'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Download Images
                  <Tooltip message={settingApiTooltipMessage.downloadImages} />
                </span>
                <SlideToggle value={field.value} onChange={field.onChange} />
              </div>
            )}
          />
          <Controller
            control={control}
            name='apiRedirect'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  API Redirect
                  <Tooltip message={settingApiTooltipMessage.apiRedirect} />
                </span>
                <SlideToggle value={field.value} onChange={field.onChange} />
              </div>
            )}
          />
          <Controller
            control={control}
            name='appendMovieYear'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Append Movie Year
                  <Tooltip message={settingApiTooltipMessage.appendMovieYear} />
                </span>
                <AppendMovieYear
                  className='form-item-two-col-card-item-select'
                  value={field.value}
                  onChange={(value: string) => field.onChange(value)}
                />
              </div>
            )}
          />
          <Controller
            control={control}
            name='apiContainer'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  API Container
                  <Tooltip message={settingApiTooltipMessage.apiContainer} />
                </span>
                <ApiContainer
                  className='form-item-two-col-card-item-select'
                  value={field.value}
                  onChange={(value: string) => field.onChange(value)}
                />
              </div>
            )}
          />
          <Controller
            control={control}
            name='cachePlaylistsFor'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Cache Playlists for
                  <Tooltip message={settingApiTooltipMessage.cachePlaylistsFor} />
                </span>
                <input
                  type='number'
                  className='form-item-two-col-card-item-input'
                  autoComplete='off'
                  placeholder=''
                  {...field}
                />
              </div>
            )}
          />
          <Controller
            control={control}
            name='grabPlaylistFromMySql'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Grab Playlist from MySQL
                  <Tooltip message={settingApiTooltipMessage.grabPlaylistFromMySql} />
                </span>
                <SlideToggle value={field.value} onChange={field.onChange} />
              </div>
            )}
          />
          <Controller
            control={control}
            name='forceEpgToUtcTimezone'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Force EPG to UTC Timezone
                  <Tooltip message={settingApiTooltipMessage.forceEpgToUtcTimezone} />
                </span>
                <SlideToggle value={field.value} onChange={field.onChange} />
              </div>
            )}
          />
          <Controller
            control={control}
            name='keepRequestProtocol'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Keep Request Protocol
                  <Tooltip message={settingApiTooltipMessage.keepRequestProtocol} />
                </span>
                <SlideToggle value={field.value} onChange={field.onChange} />
              </div>
            )}
          />
          <Controller
            control={control}
            name='vodParser'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  VOD Parser
                  <Tooltip message={settingApiTooltipMessage.vodParser} />
                </span>
                <VodParser
                  className='form-item-two-col-card-item-select'
                  value={field.value}
                  onChange={(value: string) => field.onChange(value)}
                />
              </div>
            )}
          />
          <Controller
            control={control}
            name='enableCloudflare'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Enable Cloudflare
                  <Tooltip message={settingApiTooltipMessage.enableCloudflare} />
                </span>
                <SlideToggle value={field.value} onChange={field.onChange} />
              </div>
            )}
          />
        </div>

        <div className='form-sub-title'>Legacy Support</div>
        <div className='form-item-two-col-card element-card'>
          <Controller
            control={control}
            name='legacyPlaylistUrl'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Legacy Playlist URL
                  <Tooltip message={settingApiTooltipMessage.legacyPlaylistUrl} />
                </span>
                <SlideToggle value={field.value} onChange={field.onChange} />
              </div>
            )}
          />
          <Controller
            control={control}
            name='legacyXmlTvUrl'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Legacy XML TV URL
                  <Tooltip message={settingApiTooltipMessage.legacyXmlTvUrl} />
                </span>
                <SlideToggle value={field.value} onChange={field.onChange} />
              </div>
            )}
          />
          <Controller
            control={control}
            name='legacyPanelApi'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Legacy Panel API
                  <Tooltip message={settingApiTooltipMessage.legacyPanelApi} />
                </span>
                <SlideToggle value={field.value} onChange={field.onChange} />
              </div>
            )}
          />
          <Controller
            control={control}
            name='duplicateStreamsInLegacyApps'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Duplicate Streams in Legacy Apps
                  <Tooltip message={settingApiTooltipMessage.duplicateStreamsInLegacyApps} />
                </span>
                <SlideToggle value={field.value} onChange={field.onChange} />
              </div>
            )}
          />
        </div>

        <div className='form-sub-title'>EPG API</div>
        <div className='form-item-two-col-card element-card'>
          <Controller
            control={control}
            name='enableEpgApi'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item grid-full-row'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Enable EPG API
                  <Tooltip message={settingApiTooltipMessage.enableEpgApi} />
                </span>
                <SlideToggle value={field.value} onChange={field.onChange} />
              </div>
            )}
          />
          <Controller
            control={control}
            name='appendExtraData'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item grid-full-row'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Append Extra Data
                  <Tooltip message={settingApiTooltipMessage.appendExtraData} />
                </span>
                <AppendExtraData
                  className='form-item-two-col-card-item-select half-width'
                  value={field.value}
                  onChange={(value: string) => field.onChange(value)}
                />
              </div>
            )}
          />
          <Controller
            control={control}
            name='daysToFetch'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Days to Fetch
                  <Tooltip message={settingApiTooltipMessage.daysToFetch} />
                </span>
                <input
                  type='number'
                  className='form-item-two-col-card-item-input'
                  autoComplete='off'
                  placeholder=''
                  {...field}
                />
              </div>
            )}
          />
          <Controller
            control={control}
            name='daysToKeep'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Days to Keep
                  <Tooltip message={settingApiTooltipMessage.daysToKeep} />
                </span>
                <input
                  type='number'
                  className='form-item-two-col-card-item-input'
                  autoComplete='off'
                  placeholder=''
                  {...field}
                />
              </div>
            )}
          />
        </div>

        <div className='form-sub-title'>API Services</div>
        <Controller
          control={control}
          name='adminStreamingIps'
          rules={{ required: true }}
          render={({ field }) => (
            <div className='form-item'>
              <span className='form-item-name'>
                Admin Streaming IP&#39;s
                <Tooltip message={settingApiTooltipMessage.adminStreamingIps} />
              </span>
              <div className='form-item-content'>
                <input
                  type='text'
                  className='form-item-input'
                  autoComplete='off'
                  placeholder=''
                  {...field}
                />
              </div>
            </div>
          )}
        />
        <Controller
          control={control}
          name='apiIps'
          rules={{ required: true }}
          render={({ field }) => (
            <div className='form-item'>
              <span className='form-item-name'>
                API IP&#39;s
                <Tooltip message={settingApiTooltipMessage.apiIps} />
              </span>
              <div className='form-item-content'>
                <input
                  type='text'
                  className='form-item-input'
                  autoComplete='off'
                  placeholder=''
                  {...field}
                />
              </div>
            </div>
          )}
        />
        <Controller
          control={control}
          name='apiPassword'
          rules={{ required: true }}
          render={({ field }) => (
            <div className='form-item'>
              <span className='form-item-name'>
                API Password
                <Tooltip message={settingApiTooltipMessage.apiPassword} />
              </span>
              <div className='form-item-content'>
                <input
                  type='text'
                  className='form-item-input'
                  autoComplete='off'
                  placeholder=''
                  {...field}
                />
              </div>
            </div>
          )}
        />
        <div className='form-item-two-col-card element-card'>
          <Controller
            control={control}
            name='disableEpgDownloadLine'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item grid-full-row'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Disable EPG Download - Line
                  <Tooltip message={settingApiTooltipMessage.disableEpgDownloadLine} />
                </span>
                <SlideToggle value={field.value} onChange={field.onChange} />
              </div>
            )}
          />
          <Controller
            control={control}
            name='disableEpgDownloadRestreamer'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item grid-full-row'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Disable EPG Download - Restreamer
                  <Tooltip message={settingApiTooltipMessage.disableEpgDownloadRestreamer} />
                </span>
                <SlideToggle value={field.value} onChange={field.onChange} />
              </div>
            )}
          />
          <Controller
            control={control}
            name='disablePlaylistDownloadLine'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item grid-full-row'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Disable Playlist Download - Line
                  <Tooltip message={settingApiTooltipMessage.disablePlaylistDownloadLine} />
                </span>
                <SlideToggle value={field.value} onChange={field.onChange} />
              </div>
            )}
          />
          <Controller
            control={control}
            name='disablePlaylistDownloadRestreamer'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item grid-full-row'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Disable Playlist Download - Restreamer
                  <Tooltip message={settingApiTooltipMessage.disablePlaylistDownloadRestreamer} />
                </span>
                <SlideToggle value={field.value} onChange={field.onChange} />
              </div>
            )}
          />
          <Controller
            control={control}
            name='disablePlayerApi'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Disable Player API
                  <Tooltip message={settingApiTooltipMessage.disablePlayerApi} />
                </span>
                <SlideToggle value={field.value} onChange={field.onChange} />
              </div>
            )}
          />
          <Controller
            control={control}
            name='disableEnigma2Api'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Disable Enigma2 API
                  <Tooltip message={settingApiTooltipMessage.disableEnigma2Api} />
                </span>
                <SlideToggle value={field.value} onChange={field.onChange} />
              </div>
            )}
          />
          <Controller
            control={control}
            name='disableMinistraApi'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Disable Ministra API
                  <Tooltip message={settingApiTooltipMessage.disableMinistraApi} />
                </span>
                <SlideToggle value={field.value} onChange={field.onChange} />
              </div>
            )}
          />
          <Controller
            control={control}
            name='verifyHosts'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Verify Hosts
                  <Tooltip message={settingApiTooltipMessage.verifyHosts} />
                </span>
                <SlideToggle value={field.value} onChange={field.onChange} />
              </div>
            )}
          />
        </div>

        <div className='form-sub-title'>Ministra</div>
        <Controller
          control={control}
          name='streamingPassword'
          rules={{ required: true }}
          render={({ field }) => (
            <div className='form-item'>
              <span className='form-item-name'>Streaming Password</span>
              <div className='form-item-content'>
                <input
                  type='text'
                  className='form-item-input'
                  autoComplete='off'
                  placeholder=''
                  {...field}
                />
              </div>
            </div>
          )}
        />

        <SubmitFormButton className='full-width'>Save</SubmitFormButton>
      </form>
    </SettingsApiFormComponent>
  );
};

const SettingsApiFormComponent = styled.div``;

export default SettingsApiForm;
