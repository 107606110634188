import { useEffect, useMemo } from "react";
import { deleteStream } from "../../lib/api";
import useRequest from "../../lib/hooks/useRequest";
import { useStreams } from "./useStreams";
import { isAxiosError } from "axios";
import { useCategories } from "../category/useCategories";
import { useBouquets } from "../bouquet/useBouquets";

export const useDeleteStream = () => {
    const { getAllStreams } = useStreams(false);
    const { getAllCategories, getAllCategoriesRes } = useCategories();
    const { getAllBouquets, getAllBouquetsRes } = useBouquets();
    const completed = useMemo(
      () => (getAllCategoriesRes && getAllBouquetsRes) as boolean,
      [getAllCategoriesRes, getAllBouquetsRes],
    );
    const [_deleteStream, , _deleteStreamRes, _deleteStreamErr] = useRequest(deleteStream);

    useEffect(() => {
        if (_deleteStreamRes) {
            getAllStreams();
            getAllCategories();
            getAllBouquets();
        }
      }, [_deleteStreamRes, getAllBouquets, getAllCategories, getAllStreams]);
  useEffect(() => {
    if (_deleteStreamErr) {
      if (isAxiosError(_deleteStreamErr)) {
        console.log('_deleteStreamErrText: ', _deleteStreamErr.response?.data.error);
        console.log('_deleteStreamErrMessage: ', _deleteStreamErr.response?.data.message);
      } else {
        console.log('_deleteStreamErr: ', _deleteStreamErr);
      }
    }
  }, [_deleteStreamErr]);
    return {deleteStream: _deleteStream, completed};
}