import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Controller, useForm, SubmitHandler } from 'react-hook-form';
import Urls from './Urls';
import FormFooter from '../../common/FormFooter';
import { Stream } from '../../../types/model/stream';
import SlideToggle from '../../common/SlideToggle';

export interface StreamSourcesFormInput {
  urls: string[];
  isStart: boolean;
}
interface Props {
  className?: string;
  setVisibleTab?: React.Dispatch<React.SetStateAction<number>>;
  formData?: Stream;
  setFormData?: React.Dispatch<React.SetStateAction<Stream>>;
  submitFormData?: (data: Stream) => void;
  isEdit?: boolean;
}

const StreamSourcesForm = ({
  className,
  setVisibleTab,
  formData,
  setFormData,
  submitFormData,
  isEdit = false,
}: Props) => {
  const { handleSubmit, control, setFocus, getValues } = useForm<StreamSourcesFormInput>({
    defaultValues: {
      urls: formData?.url ? [formData?.url] : [],
      isStart: formData?.is_start ?? false,
    },
  });

  const handlePrev = () => {
    if (setVisibleTab) setVisibleTab(1);
  };

  // If setFormData before unmount
  useEffect(() => {
    return () => {
      const data = getValues();
      if (setFormData) {
        setFormData(currentLine => ({
          ...currentLine,
          url: data.urls[0],
          is_start: data.isStart,
        }));
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit: SubmitHandler<StreamSourcesFormInput> = data => {
    if (data.urls.length === 0) {
      setFocus('urls');
      return;
    }
    const newStream = {
      ...formData,
      url: data.urls[0],
      is_start: data.isStart,
    } as Stream;
    if (submitFormData && newStream) submitFormData(newStream);
    // if (setVisibleTab) setVisibleTab(3);
  };

  return (
    <StreamSourcesFormComponent className={className}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='form-card element-card'>
          <div className='form-card-header'>
            <p>URL</p>
            <p>Stream Info</p>
          </div>
          <Controller
            control={control}
            name='urls'
            rules={{ required: false }}
            render={({ field }) => <Urls field={field} />}
          />
          <Controller
          control={control}
          name='isStart'
          rules={{ required: false }}
          render={({ field }) => (
            <div className='form-item'>
              <span className='form-item-name'>Start Stream Now</span>
              <SlideToggle value={field.value} onChange={field.onChange} />
            </div>
          )}
        />
        </div>

        <FormFooter handlePrev={handlePrev} nextButtonTitle={isEdit ? 'Edit' : 'Add'} />
      </form>
    </StreamSourcesFormComponent>
  );
};

const StreamSourcesFormComponent = styled.div`
  .form-card-header {
    margin: 12px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 32px;

    p {
      font-size: 24px;
      color: var(--text-form-content);
    }
  }
`;

export default StreamSourcesForm;
