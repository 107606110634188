import { createGlobalStyle } from 'styled-components';

const Typography = createGlobalStyle`
  * {
    font-family: 'Inter', sans-serif;
    color: var(--white);
  }
`;

export default Typography;
