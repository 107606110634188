import useRequest from '../../lib/hooks/useRequest';
import { useEffect } from 'react';
import { useBouquets } from './useBouquets';
import { editBouquet } from '../../lib/api';

export const useEditBouquet = () => {
  const [_editBouquet, loading, _editBouquetRes, _editBouquetErr] = useRequest(editBouquet);
  const { getAllBouquets: getAllBouquets, completed } = useBouquets();
  useEffect(() => {
    if (!_editBouquetRes) return;
    getAllBouquets();
  }, [_editBouquetRes, getAllBouquets]);

  useEffect(() => {
    if (_editBouquetErr) {
      console.log('_getAllBouquetsErr: ', _editBouquetErr);
    }
  }, [_editBouquetErr]);
  return { editBouquet: _editBouquet, editBouquetRes: _editBouquetRes, loading, completed };
};
