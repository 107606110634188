import React from 'react';
import styled from 'styled-components';
import { NextArrowSvg, PrevArrowSvg } from '../../assets/svg';
import { FormButton, PrimaryFormButton, SubmitFormButton } from './Buttons';

interface Props {
  className?: string;
  hidePrev?: boolean;
  notSubmit?: boolean;
  noIconOnPrev?: boolean;
  noIconOnNext?: boolean;
  prevButtonTitle?: string;
  nextButtonTitle?: string;
  handlePrev?: () => void;
  handleNext?: () => void;
  children?: React.ReactNode;
}

const FormFooter = ({
  className,
  hidePrev = false,
  notSubmit = false,
  noIconOnPrev = false,
  noIconOnNext = false,
  prevButtonTitle = 'Prev',
  nextButtonTitle = 'Next',
  handlePrev,
  handleNext,
  children,
}: Props) => {
  return (
    <FormFooterComponents className={className}>
      <FormButton onClick={handlePrev} className={`${hidePrev ? 'hidden-button' : ''}`}>
        {!noIconOnPrev && <PrevArrowSvg />}
        {prevButtonTitle}
      </FormButton>
      <div className='children-container'>{children}</div>
      {notSubmit ? (
        <PrimaryFormButton onClick={handleNext}>
          {nextButtonTitle}
          {!noIconOnNext && <NextArrowSvg />}
        </PrimaryFormButton>
      ) : (
        <SubmitFormButton>
          {nextButtonTitle}
          {!noIconOnNext && <NextArrowSvg />}
        </SubmitFormButton>
      )}
    </FormFooterComponents>
  );
};

const FormFooterComponents = styled.div`
  margin: 12px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;

  .hidden-button {
    visibility: hidden;
  }

  .children-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }
`;

export default FormFooter;
