import React, { useEffect, useState } from 'react';
import { ControllerRenderProps } from 'react-hook-form';
import styled from 'styled-components';
import {
  AddSvg,
  ArrowRotateRightSvg,
  DownArrowSmallSvg,
  UpArrowSmallSvg,
  XMarkSvg,
} from '../../../assets/svg';
import { SecondaryFormButton, WhiteBorderButton } from '../../common/Buttons';
import { StreamSourcesFormInput } from './StreamSourcesForm';

interface Props {
  className?: string;
  field: ControllerRenderProps<StreamSourcesFormInput, 'urls'>;
}

const Urls = ({ className, field }: Props) => {
  const [inputtedUrls, setInputtedUrls] = useState<string[]>([]);

  useEffect(() => {
    setInputtedUrls(field.value.concat(['']));
  }, [field.value]);

  const handleInputChanged = (url: string, index: number) => {
    if (index !== inputtedUrls.length - 1) return;
    setInputtedUrls(prevUrls => {
      const updatedUrls = [...prevUrls];
      updatedUrls[index] = url;
      return updatedUrls;
    });
  };

  const addUrl = () => {
    const newUrls = new Set(inputtedUrls);
    newUrls.delete('');
    field.onChange(Array.from(newUrls));
  };

  const deleteUrl = (index: number) => {
    const updatedUrls = [...inputtedUrls];
    updatedUrls[index] = '';
    const newUrls = new Set(updatedUrls);
    newUrls.delete('');
    field.onChange(Array.from(newUrls));
  };

  const moveTop = (index: number) => {
    if (index === 0) return;
    const updatedUrls = [...inputtedUrls];
    const moveItem = updatedUrls[index];
    updatedUrls[index] = updatedUrls[index - 1];
    updatedUrls[index - 1] = moveItem;
    const newUrls = new Set(updatedUrls);
    newUrls.delete('');
    field.onChange(Array.from(newUrls));
  };

  const moveBottom = (index: number) => {
    if (index === inputtedUrls.length - 1) return;
    const updatedUrls = [...inputtedUrls];
    const moveItem = updatedUrls[index];
    updatedUrls[index] = updatedUrls[index + 1];
    updatedUrls[index + 1] = moveItem;
    const newUrls = new Set(updatedUrls);
    newUrls.delete('');
    field.onChange(Array.from(newUrls));
  };

  return (
    <UrlsComponents className={className}>
      {inputtedUrls.map((urlData, index) => (
        <div className='row-container' key={index}>
          <div className='main-content'>
            <WhiteBorderButton
              className='icon-button'
              onClick={() => {
                moveTop(index);
              }}
            >
              <UpArrowSmallSvg style={{ height: '14px', width: '14px' }} />
            </WhiteBorderButton>
            <WhiteBorderButton
              className='icon-button'
              onClick={() => {
                moveBottom(index);
              }}
            >
              <DownArrowSmallSvg style={{ height: '14px', width: '14px' }} />
            </WhiteBorderButton>
            <input
              type='text'
              className='form-item-input'
              value={urlData}
              disabled={index !== inputtedUrls.length - 1}
              onChange={e => handleInputChanged(e.target.value, index)}
              autoComplete='off'
            />
            <WhiteBorderButton
              className='icon-button'
              onClick={() => {
                deleteUrl(index);
              }}
            >
              <XMarkSvg style={{ height: '18px', width: '18px' }} />
            </WhiteBorderButton>
          </div>
          <div className='info-content'>Not Scanned</div>
        </div>
      ))}
      <div className='control-container'>
        <WhiteBorderButton className='control-button' onClick={addUrl}>
          <AddSvg style={{ height: '12px', width: '12px' }} />
          Add Row
        </WhiteBorderButton>
        <SecondaryFormButton
          className='secondary-button'
          onClick={() => {
            console.log('click clicked');
          }}
        >
          Providers
        </SecondaryFormButton>
        <WhiteBorderButton
          className='control-button'
          onClick={() => {
            console.log('click clicked');
          }}
        >
          Scan Sourced
          <ArrowRotateRightSvg style={{ height: '12px', width: '12px' }} />
        </WhiteBorderButton>
      </div>
    </UrlsComponents>
  );
};

const UrlsComponents = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;

  .row-container {
    margin: 12px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 32px;

    .main-content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap: 4px;
      overflow: visible;

      .icon-button {
        width: 38px;
        padding: 0;
      }
    }

    .info-content {
      flex-shrink: 0;
      width: 140px;
      font-size: 20px;
      text-align: right;
      color: var(--placeholder-dark);
    }
  }

  .control-container {
    margin: 10px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;

    .control-button {
      gap: 14px;
      padding: 0 10px;
    }

    .secondary-button {
      color: var(--text-form-content);
    }
  }
`;

export default Urls;
