import React from 'react';
import styled from 'styled-components';
import { stateItems } from '../config/data/stateData';
import { logoImage } from '../assets/images';
import { MagnifyingGlassSvg } from '../assets/svg';
import { NavLink } from 'react-router-dom';
import { IconSimpleButton } from '../components/common/Buttons';
import StateItemElement from './StateItem';

const TopBar = () => {
  return (
    <TopBarComponent>
      <NavLink to={'/'}>
        <img src={logoImage} alt='LOGO' />
      </NavLink>
      <div className='search-bar'>
        <input type='search' placeholder='Search...' />
        <IconSimpleButton
          className='magnifying-glass-svg'
          onClick={() => {
            console.log('clicked');
          }}
        >
          <MagnifyingGlassSvg />
        </IconSimpleButton>
      </div>
      <div className='state-bar'>
        {stateItems.map((stateItem, index) => (
          <StateItemElement key={index} className='state-element' stateItem={stateItem} />
        ))}
      </div>
    </TopBarComponent>
  );
};

const TopBarComponent = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: var(--card-bg);
  padding: 10px 20px 10px 60px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 80px;
  z-index: 100;

  img {
    height: 36px;
  }

  .search-bar {
    position: relative;
    width: 700px;
    min-width: 115px;
    display: flex;
    align-items: center;

    input {
      width: 100%;
      font-size: 14px;
      border-radius: 19px;
      padding: 7px 12px;
      background-color: var(--dark-bg);
      box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
      color: var(--text-form-content);

      /* Hide the clear button */
      &::-webkit-search-cancel-button,
      &::-webkit-search-decoration {
        -webkit-appearance: none;
        appearance: none;
        display: none;
      }
    }

    .magnifying-glass-svg {
      position: absolute;
      right: 10px;
    }
  }

  .state-bar {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0;
    gap: 10px;

    .state-element {
      padding: 12px;
      background-color: var(--dark-bg);
      box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      p {
        font-weight: 600;
        font-size: 12px;
      }
    }
  }
`;

export default TopBar;
