import React from 'react';
import ReactModal from 'react-modal';
import { XMarkSvg } from '../../assets/svg';
import { IconButton } from './Buttons';

interface Props {
  className?: string;
  children: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
  title: string;
}

const ModalForm = ({ className, children, isOpen, onClose, title }: Props) => {
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onClose}
      className={`modal-form card ${className}`}
      ariaHideApp={false}
      style={{
        overlay: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          background: 'rgba(0,0,0,0.75)',
          zIndex: 1000,
        },
      }}
    >
      <div className='modal-form-title'>
        {title}
        <IconButton onClick={onClose} size={25}>
          <XMarkSvg />
        </IconButton>
      </div>
      <div className='divide-bar'></div>
      <div className='modal-form-content'>{children}</div>
    </ReactModal>
  );
};

export default ModalForm;
