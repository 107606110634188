import { createGlobalStyle } from 'styled-components';
import { device } from './device';

const ClassesStyles = createGlobalStyle`
  .card {
    background-color: var(--card-bg);
    border-radius: 8px;
  }

  .element-card {
    background-color: var(--element-card-bg);
    border-radius: 8px;
  }

  .narrow-modal-form {
    max-width: 50%;
    width: 800px !important;
    overflow: visible !important;
  }

  .narrower-modal-form {
    max-width: 35%;
    width: 600px !important;
    overflow: visible !important;
  }

  .modal-form {
    width: 1090px;
    max-width: 85%;
    max-height: 85%;
    overflow: auto;
    display: flex;
    flex-direction: column;

    .modal-form-title{
      padding: 20px 30px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: 500;
      font-size: 32px;
    }
    
    .divide-bar{
      flex-shrink: 0;
      width: 100%;
      height: 1px;
      background-color: var(--black);
    }
    
    .modal-form-content{      
      padding: 20px 30px;
    }
  }

  .form-sub-title{
    margin: 64px 0 24px;
    display: flex;
    align-items: center;
    gap: 4px;
    font-weight: 600;
    color: var(--text-form-content);
    font-size: 24px;
  }

  .form-full-row-item {
    margin: 24px 0;
    width: 100%;
  }

  .form-item {
    margin: 24px 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 4px;

    .form-item-name {
      flex-shrink: 0;
      width: fix-content;
      display: flex;
      align-items: center;
      gap: 4px;
      font-size: 22px;
    }
    
    .form-item-content {
      position: relative;
      width: 70%;
      min-width: 200px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap: 4px;
    }
  }

  .form-item-two-col-card {
    margin: 24px 0;
    width: 100%;
    padding: 10px 14px;
    display: grid;
    grid-template-columns: repeat(2, minmax(min-content, 350px));
    justify-content: space-between;
    gap: 25px 20px;

    @media ${device.mobileL} {
      grid-template-columns: 1fr;
    }

    .form-item-two-col-card-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 20px;

      .form-item-two-col-card-item-name {
        display: flex;
        align-items: center;
        gap: 4px;
        font-size: 24px;
        color: var(--text-form-content);
      }

      .form-item-two-col-card-item-input {
        flex-shrink: 0;
        width: 80px;
        padding: 7px 8px;
        border-radius: 8px;
        background-color: var(--dark-bg);
        font-size: 20px;
        color: var(--text-form-content);

        &[disabled]{
          background-color: var(--dark-grey);
          color: var(--text-secondary);
        }
      }
      
      .form-item-two-col-card-item-select{
        flex-shrink: 0;
        width: 180px;
        font-size: 20px;
      }
    }
  }

  .form-item-input {
    width: 100%;
    min-width: 115px;
    padding: 7px 20px;
    border-radius: 8px;
    background-color: var(--dark-bg);
    font-size: 20px;
    color: var(--text-form-content);
    
    &[disabled]{
      background-color: var(--dark-grey);
      color: var(--text-secondary);
    }
  }

  .form-item-select {
    width: 100%;
    font-size: 20px;
  }

  .form-item-multi-select {
    width: 100%;
    height: 80px;
    background-color: var(--dark-bg);
    border-radius: 8px;
    overflow: auto;
    border: none;

    .form-select-option {
      width: 100%;
      padding: 7px 20px;
      border-radius: 8px;
      background-color: var(--dark-bg);
      font-size: 20px;
      color: var(--text-form-content);
    }
  }

  .form-sub-item {
    margin-left: 16px;
    display: flex;
    align-items: center;
    gap: 8px;

    span {
      width: max-content;
      font-size: 24px;
      color: var(--text-form-content);
    }

    .form-sub-item-input {
      flex-shrink: 0;
      width: 80px;
      padding: 7px 0;
      border-radius: 8px;
      background-color: var(--dark-bg);
      font-size: 20px;
      color: var(--text-form-content);
    }
  }

  .form-card {
    margin: 12px 0;
    padding: 18px 15px;
  }

  .form-column-item {
    font-size: 14px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .form-warning {
    margin: 12px 0;
    padding: 18px 28px;
    display: flex;
    align-items: center;
    gap: 14px;
    font-size: 16px;
  }
  
  .form-notice {
    margin: 12px 0;
    padding: 10px 14px;
    text-align: center;
    font-size: 18px;
    color: var(--text-form-content);
  }

  .popup-menu {

    .popup-menu-item {     
      padding: 9px;

      &:hover {
        background: var(--card-bg);
        cursor: pointer;
      }
    }
  }

  .two-col-container {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;

    @media ${device.mobileL} {
      grid-template-columns: 1fr;
    }
  }

  .flex-container {
    display: flex;
    align-items: center;
    gap: 10px;
  }
`;

export default ClassesStyles;
