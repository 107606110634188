import React from 'react';
import styled from 'styled-components';

interface Props {
  className?: string;
  children: React.ReactNode;
}

const FormTitle = ({ className, children }: Props) => {
  return <FormTitleComponent className={className}>{children}</FormTitleComponent>;
};

const FormTitleComponent = styled.div`
  font-weight: 500;
  font-size: 32px;
`;

export default FormTitle;
