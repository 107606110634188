import React, { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { DownArrowSmallSvg, UpArrowSmallSvg } from '../../assets/svg';
import { MenuItem } from '../../types/menuData';

interface Props {
  menuItems: MenuItem[];
  className: string;
}

const DownMenu = ({ menuItems, className }: Props) => {
  const location = useLocation();
  const [activatedMenuItemId, setActivatedMenuItemId] = useState<string>();
  const [overedMenuItemId, setOveredMenuItemId] = useState<string>();

  useEffect(() => {
    const pathSegments = location.pathname.split('/');
    const path = pathSegments[1];
    setActivatedMenuItemId(path);
  }, [location]);

  return (
    <DownMenuComponent className={className}>
      {menuItems.map(menuItem => {
        const activated = menuItem.id === activatedMenuItemId;
        const overed = menuItem.id === overedMenuItemId;
        return (
          <div
            key={menuItem.id}
            onMouseOver={() => setOveredMenuItemId(menuItem.id)}
            onMouseLeave={() => setOveredMenuItemId(undefined)}
          >
            <NavLink to={menuItem.type === 'link' ? menuItem.route : '#'}>
              <div className={`menu-item ${activated && 'menu-item-selected'}`}>
                <menuItem.icon className='item-icon' />
                <p>{menuItem.name}</p>
                {menuItem.type === 'sub' &&
                  (activated ? <UpArrowSmallSvg /> : <DownArrowSmallSvg />)}
              </div>
            </NavLink>
            {(activated || overed) && menuItem.subMenuItems && menuItem.subMenuItems.length > 0 && (
              <div className='dropdown-menu'>
                {menuItem.subMenuItems.map(subMenuItem => (
                  <NavLink
                    key={subMenuItem.id}
                    className={({ isActive }) =>
                      `dropdown-menu-item ${isActive && 'dropdown-menu-item-selected'}`
                    }
                    to={subMenuItem.route}
                  >
                    <subMenuItem.icon className='dropdown-menu-item-icon' />
                    <p>{subMenuItem.name}</p>
                  </NavLink>
                ))}
              </div>
            )}
          </div>
        );
      })}
    </DownMenuComponent>
  );
};

const DownMenuComponent = styled.div`
  padding: 17px 7px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  gap: 10px;

  .menu-item {
    height: 31px;
    padding: 0 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    cursor: pointer;

    &.menu-item-selected {
      border-width: 2px 0;
      border-style: solid;
      border-image: linear-gradient(to left, #ffffff00, #9747ff, #ffffff00);
      border-image-slice: 1;

      background: radial-gradient(
          46.63% 99.89% at 47.59% 99.89%,
          rgba(122, 51, 173, 0.48) 0%,
          rgba(151, 71, 255, 0) 100%
        ),
        radial-gradient(
          100% 100% at 50.63% 0%,
          rgba(151, 71, 255, 0.16) 0%,
          rgba(151, 71, 255, 0) 100%
        ),
        #2b2b4b;
    }

    &:hover {
      box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.5);
    }

    .item-icon {
      height: 18px;
      width: 18px;
    }

    p {
      width: 100%;
      font-weight: 600;
      font-size: 16px;
    }
  }

  .dropdown-menu {
    margin: 2px 0 13px 35px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    gap: 2px;

    .dropdown-menu-item {
      height: 29px;
      padding: 0 10px;
      border-radius: 8px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      gap: 12px;
      cursor: pointer;
      animation: dropdown-menu-item-animation 200ms ease-in;

      &.dropdown-menu-item-selected {
        background: radial-gradient(
            46.63% 99.89% at 47.59% 99.89%,
            rgba(122, 51, 173, 0.48) 0%,
            rgba(151, 71, 255, 0) 100%
          ),
          #2b2b4b;
      }

      &:hover {
        box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.5);
      }

      .dropdown-menu-item-icon {
        height: 10px;
        width: 10px;
      }

      p {
        width: 100%;
        font-weight: 600;
        font-size: 14px;
      }
    }
  }

  @keyframes dropdown-menu-item-animation {
    from {
      height: 10px;
    }
    to {
      height: 29px;
    }
  }
`;

export default DownMenu;
