import { useEffect } from 'react';
import { updateUser } from '../../lib/api';
import useRequest from '../../lib/hooks/useRequest';
import { isAxiosError } from 'axios';
import { useLines } from './useLines';

export const useEditLine = () => {
  const { getAllUsers } = useLines(false);
  const [_updateUser, , _updateUserRes, _updateUserErr] = useRequest(updateUser);
  useEffect(() => {
    if (_updateUserRes) {
      // TODO check data object
      getAllUsers();
    }
  }, [_updateUserRes, getAllUsers]);

  useEffect(() => {
    if (_updateUserErr) {
      if (isAxiosError(_updateUserErr)) {
        console.log('updateUserErrText: ', _updateUserErr.response?.data.error);
        console.log('updateUserErrMessage: ', _updateUserErr.response?.data.message);
      } else {
        console.log('updateUserErr: ', _updateUserErr);
      }
    }
  }, [_updateUserErr]);
  return { editLine: _updateUser, editLineRes: _updateUserRes, editLineErr: _updateUserErr };
};
