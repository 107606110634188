import React, { useEffect, useRef } from 'react';
import ModalForm from '../../common/ModalForm';
import Hls from 'hls.js';

interface Props {
  sourceURL: string;
  isOpen: boolean;
  onClose: () => void;
}

const HLSPlayerModal = ({ sourceURL, isOpen, onClose }: Props) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);

  useEffect(() => {
    const initializeHls = () => {
      if (videoRef.current) {
        if (Hls.isSupported()) {
          const hls = new Hls();
          hls.loadSource(sourceURL);
          hls.attachMedia(videoRef.current);

          return () => {
            hls.destroy();
          };
        } else if (videoRef.current.canPlayType('application/vnd.apple.mpegurl')) {
          videoRef.current.src = sourceURL;
        } else {
          console.error('HLS is not supported in this browser.');
        }
      }
    };

    initializeHls();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sourceURL, videoRef.current]);

  return (
    <ModalForm isOpen={isOpen} onClose={onClose} title={'Player'}>
      <video style={{ width: '100%', height: '100%' }} ref={videoRef} controls autoPlay />
    </ModalForm>
  );
};

export default HLSPlayerModal;
