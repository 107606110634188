import React from 'react';
import { Control, useWatch } from 'react-hook-form';
import Select from 'react-select';
import { settingSelectStyles } from '../../../styles/reactSelect';
import { CommonSelectItem } from '../../../types/model/commonSelectItem';
import { SettingsLogsFormInput } from './SettingsLogsForm';

interface Props {
  control: Control<SettingsLogsFormInput>;
  watchedName: keyof SettingsLogsFormInput;
  value: string;
  onChange: (value: string) => void;
}

const LogKeepPeriod = ({ control, watchedName, value, onChange }: Props) => {
  const isEnabled = useWatch({ control, name: watchedName });
  return (
    <div className='form-item-two-col-card-item'>
      <span className='form-item-two-col-card-item-name font-size-20'>Keep Logs for</span>
      <div className='form-item-two-col-card-item-select'>
        <Select<CommonSelectItem>
          className='form-item-select'
          options={logKeepPeriodOptions}
          styles={settingSelectStyles}
          value={
            logKeepPeriodOptions[logKeepPeriodOptions.findIndex(option => option.value === value)]
          }
          onChange={newValue => onChange(newValue?.value ?? '')}
          isDisabled={!isEnabled}
        />
      </div>
    </div>
  );
};

export default LogKeepPeriod;

const logKeepPeriodOptions: CommonSelectItem[] = [
  { value: 'forever', label: 'Forever' },
  { value: '1h', label: '1 Hours' },
  { value: '6h', label: '6 Hours' },
  { value: '12h', label: '12 Hours' },
  { value: '1d', label: '1 Day' },
  { value: '3d', label: '3 Days' },
  { value: '7d', label: '7 Days' },
  { value: '14d', label: '14 Days' },
  { value: '28d', label: '28 Days' },
  { value: '180d', label: '180 Days' },
  { value: '365d', label: '365 Days' },
];
