import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import Select from 'react-select';
import { CommonSelectItem } from '../../types/model/commonSelectItem';
import FormTitle from '../common/FormTitle';
import ManageTable from '../common/ManageTable';
import { CellProps, Column } from 'react-table';
import { headerSelectStyles } from '../../styles/reactSelect';
import Popup from 'reactjs-popup';
import Paginator from '../common/Paginator';
import { User } from '../../types/model/user';
import moment from 'moment';
import ModalWarning from '../common/ModalWarning';
import EditLineModal from './modal/EditLineModal';
import { PopupActions } from 'reactjs-popup/dist/types';
import DownloadPlaylistModal from './modal/DownloadPlaylistModal';
import { useEditLine } from '../../hooks/user/useEditLine';
import { serverStatsAtom, usersAtom } from '../../store/jotai';
import { useAtom } from 'jotai';
import { useLines } from '../../hooks/user/useLines';
import { useDeleteLine } from '../../hooks/user/useDeleteLine';
import { ConnectedUser } from '../../types/model/serverStats';

const deleteMessage = 'Are you sure you want to delete this line? All connections will be deleted.';

const filterOptions: CommonSelectItem[] = [
  { value: 'active', label: 'Active' },
  { value: 'disabled', label: 'Disabled' },
  { value: 'banned', label: 'Banned' },
  { value: 'expired', label: 'Expired' },
  { value: 'trial', label: 'Trial' },
  { value: 'restreamer', label: 'Restreamer' },
  { value: 'ministra', label: 'Ministra' },
  { value: 'expiringSoon', label: 'Expiring Soon' },
];

const showOptions: CommonSelectItem[] = [
  { value: '10', label: '10' },
  { value: '25', label: '25' },
  { value: '50', label: '50' },
  { value: '250', label: '250' },
  { value: '500', label: '500' },
  { value: '1000', label: '1000' },
];

interface Props {
  className?: string;
}

const ManageLinesForm = ({ className }: Props) => {
  const [serverStats] = useAtom(serverStatsAtom);
  const [users] = useAtom(usersAtom);
  useLines();
  const { editLine } = useEditLine();
  const { deleteLine } = useDeleteLine();

  const [selectedFilter, setSelectedFilter] = useState<CommonSelectItem | null>(null);
  const [showNumber, setShowNumber] = useState<CommonSelectItem | null>(showOptions[0]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDownloadPlaylistModal, setShowDownloadPlaylistModal] = useState(false);
  const selectedUserRef = useRef<User | null>(null);
  const [data, setData] = useState<User[]>([]);
  const [page, setPage] = useState(0);
  useEffect(() => {
    const tempData = data.map((user: User) => {
      user.online = false;
      user.connections = '0/' + (user.max_connections ?? 0).toString();
      const tempUser = serverStats.connected_users?.find(
        (item: ConnectedUser) => item.username === user.username,
      );
      if (tempUser && tempUser.connection_count) {
        user.online = true;
        user.connections = tempUser.connection_count + '/' + (user.max_connections ?? 0).toString();
      }
      return user;
    });
    setData(tempData);
  }, [data, serverStats]);
  useEffect(() => {
    setData(
      users.slice(
        (page - 1) * Number(showNumber?.value ?? 10),
        page * Number(showNumber?.value ?? 10),
      ),
    );
    setShowEditModal(false);
  }, [page, showNumber?.value, users]);

  const onPageChange = (page: number) => {
    setPage(page);
  };

  const onEditUser = () => {
    setShowEditModal(true);
  };

  const onDownloadPlaylist = () => {
    setShowDownloadPlaylistModal(true);
  };

  const onKillConnections = () => {
    // TODO Kill Connections
  };

  const onBanUser = () => {
    // TODO Ban User
  };

  const onDisableUser = () => {
    // TODO Disable User
  };

  const onDeleteUser = () => {
    // Show Confirm Modal to Delete User
    setShowDeleteModal(true);
  };

  const onConfirmDeleteUser = () => {
    setShowDeleteModal(false);
    // Call delete user api
    deleteLine(selectedUserRef.current?.username ?? '');
  };

  const onSubmitEditModal = (user: User) => {
  //  onCloseEditModal();
    editLine(user);
  };

  const onCloseEditModal =() => {
    setShowEditModal(false);
  } 

  const onCloseDownloadPlaylistModal = () => {
    setShowDownloadPlaylistModal(false);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const columns: Column<User>[] = useMemo(
    () =>
      getLinesHeader(
        (user: User) => {
          selectedUserRef.current = user;
        },
        onEditUser,
        onDownloadPlaylist,
        onKillConnections,
        onBanUser,
        onDisableUser,
        onDeleteUser,
      ),
    [],
  );

  return (
    <ManageLinesFormComponents className={className}>
      <FormTitle>Manage Lines</FormTitle>
      <div className='form-header'>
        <div className='filter-container'>
          <input
            type='search'
            className='form-header-input'
            autoComplete='on'
            placeholder='Search Lines...'
          />
          <input
            type='search'
            className='form-header-input'
            autoComplete='off'
            placeholder='Search Lines...'
          />
          <Select<CommonSelectItem>
            isClearable
            className='form-header-select'
            options={filterOptions}
            styles={headerSelectStyles}
            placeholder='No filter'
            value={selectedFilter}
            onChange={value => {
              setSelectedFilter(value);
            }}
          />
        </div>
        <div className='show-container'>
          <span className='form-header-name'>Show</span>
          <Select<CommonSelectItem>
            className='form-header-select'
            options={showOptions}
            styles={headerSelectStyles}
            value={showNumber}
            onChange={value => {
              setShowNumber(value);
            }}
          />
        </div>
      </div>
      <div className='table-container'>
        <ManageTable<User> columns={columns} data={data} />
      </div>
      <Paginator
        totalRecords={users.length}
        pageSize={Number(showNumber?.value ?? 10)}
        onPageChange={onPageChange}
      />
      <ModalWarning
        isOpen={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        noIconOnOk
        nextButtonTitle='Delete'
        handleCancel={() => setShowDeleteModal(false)}
        handleOk={onConfirmDeleteUser}
      >
        {deleteMessage}
      </ModalWarning>
      {selectedUserRef.current && (
        <EditLineModal
          user={selectedUserRef.current}
          isOpen={showEditModal}
          onSubmit={onSubmitEditModal}
          onClose={onCloseEditModal}
        />
      )}
      {selectedUserRef.current && (
        <DownloadPlaylistModal
          user={selectedUserRef.current}
          isOpen={showDownloadPlaylistModal}
          onClose={onCloseDownloadPlaylistModal}
        />
      )}
    </ManageLinesFormComponents>
  );
};

const ManageLinesFormComponents = styled.div`
  width: 100%;
  max-width: 2500px;
  margin: 30px auto 0;

  .form-header {
    margin: 40px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 25px;

    .filter-container {
      flex: 1 1 auto;
      max-width: 1000px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 4px;

      .form-header-input {
        flex-grow: 1;
        max-width: 280px;
        padding: 11px 12px;
        border-radius: 19px;
        background-color: var(--dark-bg);
        font-size: 14px;
        color: var(--text-form-content);
      }

      .form-header-select {
        flex-grow: 1;
        max-width: 280px;
        min-width: 200px;
        font-size: 14px;
        color: var(--text-form-content);
      }
    }

    .show-container {
      display: flex;
      align-items: center;
      gap: 16px;

      .form-header-select {
        flex-shrink: 0;
        width: 100px;
        font-size: 14px;
        color: var(--text-form-content);
      }

      .form-header-name {
        font-size: 14px;
        color: var(--text-form-content);
      }
    }
  }

  .table-container {
    width: 100%;
    overflow: auto;
  }

  .actions-button {
    padding: 6px 0;
    border-radius: 16px;
    background: var(--dark-bg);
    font-size: 16px;
    box-shadow: 0px 2px 13px 1px #1e1e3f;

    &:hover {
      filter: brightness(2);
      cursor: pointer;
    }
    &:focus {
      outline: white solid 2px;
      outline-offset: 2px;
    }
    &:active {
      box-shadow: 0px 2px 13px 0px #1e1e3f;
      opacity: 0.7;
    }
  }
`;

export default ManageLinesForm;

// const renderStatus = (cell: CellProps<User, boolean>): JSX.Element => (
//   <div style={{ fontSize: '16px', color: cell.value ? '#4bd075' : '#f8cc6b' }}>
//     {' '}
//     {cell.value ? 'Active' : 'Inactive'}
//   </div>
// );

const renderOnline = (cell: CellProps<User, boolean>): JSX.Element => (
  <div
    style={{
      margin: '0 auto',
      width: '28px',
      height: '28px',
      borderRadius: '8px',
      background: cell.value ? '#7bffa5' : 'white',
    }}
  ></div>
);

const renderActive = (cell: CellProps<User, string>): JSX.Element => (
  <div
    style={{
      margin: '0 auto',
      height: '28px',
      width: 'fit-content',
      padding: '0 9px',
      borderRadius: '8px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      // background: cell.value ? '#7bffa5' : 'yellow',
      fontSize: '16px',
      color: '#white',
    }}
  >
    {cell.value}
  </div>
);

const renderDate = (cell: CellProps<User, number>): JSX.Element => {
  const dateStr = moment(new Date(cell.value * 1000)).format('yyyy-MM-DD HH:mm:ss');
  return (
    <div>
      <div style={{ fontSize: '16px' }}> {dateStr.split(' ')[0]}</div>
      <div> {dateStr.split(' ')[1]}</div>
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const popupBody: any = (
  onEditUser: () => void,
  onDownloadPlaylist: () => void,
  onKillConnections: () => void,
  onBanUser: () => void,
  onDisableUser: () => void,
  onDeleteUser: () => void,
) => (
  <div className='popup-menu'>
    <div className='popup-menu-item' onClick={onEditUser}>
      Edit Line
    </div>
    <div className='popup-menu-item' onClick={onDownloadPlaylist}>
      Download Playlist
    </div>
    {/* <div className='popup-menu-item' onClick={onKillConnections}>
      Kill connections
    </div>
    <div className='popup-menu-item' onClick={onBanUser}>
      Ban Line
    </div>
    <div className='popup-menu-item' onClick={onDisableUser}>
      Disable Line
    </div> */}
    <div className='popup-menu-item' onClick={onDeleteUser}>
      Delete Line
    </div>
  </div>
);

const RenderPopup = (
  cell: CellProps<User, number>,
  onSelectUser: (user: User) => void,
  onEditUser: () => void,
  onDownloadPlaylist: () => void,
  onKillConnections: () => void,
  onBanUser: () => void,
  onDisableUser: () => void,
  onDeleteUser: () => void,
): JSX.Element => {
  const user = cell.row.original;
  const popupRef = useRef<PopupActions | null>(null);

  const _onEditUser = () => {
    popupRef.current?.close();
    onEditUser();
  };
  const _onDownloadPlaylist = () => {
    popupRef.current?.close();
    onDownloadPlaylist();
  };
  const _onKillConnections = () => {
    popupRef.current?.close();
    onKillConnections();
  };
  const _onBanUser = () => {
    popupRef.current?.close();
    onBanUser();
  };
  const _onDisableUser = () => {
    popupRef.current?.close();
    onDisableUser();
  };
  const _onDeleteUser = () => {
    popupRef.current?.close();
    onDeleteUser();
  };

  return (
    <Popup
      ref={popupRef}
      trigger={<div className='actions-button'> Actions </div>}
      position='left center'
      on='click'
      closeOnDocumentClick
      onOpen={() => onSelectUser(user)}
      closeOnEscape
      mouseLeaveDelay={300}
      mouseEnterDelay={0}
      arrow={false}
    >
      {popupBody(
        _onEditUser,
        _onDownloadPlaylist,
        _onKillConnections,
        _onBanUser,
        _onDisableUser,
        _onDeleteUser,
      )}
    </Popup>
  );
};

const getLinesHeader: (
  onSelectUser: (user: User) => void,
  onEditUser: () => void,
  onDownloadPlaylist: () => void,
  onKillConnections: () => void,
  onBanUser: () => void,
  onDisableUser: () => void,
  onDeleteUser: () => void,
) => Column<User>[] = (
  onSelectUser,
  onEditUser,
  onDownloadPlaylist,
  onKillConnections,
  onBanUser,
  onDisableUser,
  onDeleteUser,
) => [
  {
    Header: 'ID',
    accessor: 'id',
  },
  {
    Header: 'UserName',
    accessor: 'username',
  },
  {
    Header: 'Password',
    accessor: 'password',
  },
  // {
  //   Header: 'Owner',
  //   accessor: 'group',
  // },
  // {
  //   Header: 'Status',
  //   accessor: 'status',
  //   Cell: renderStatus,
  // },
  {
    Header: 'Online',
    accessor: 'online',
    Cell: renderOnline,
  },
  // {
  //   Header: 'Trial',
  //   accessor: 'trial',
  //   Cell: renderOnline,
  // },
  // {
  //   Header: 'Restreamer',
  //   accessor: 'restreamer',
  //   Cell: renderOnline,
  // },
  // {
  //   Header: 'Active',
  //   accessor: 'active',
  //   Cell: renderActive,
  // },
  {
    Header: 'Connections',
    accessor: 'connections',
    Cell: renderActive,
  },
  {
    Header: 'Expirations',
    accessor: 'expiry_date',
    Cell: renderDate,
  },
  // {
  //   Header: 'Last Connections',
  //   accessor: 'lastConnections',
  //   Cell: renderDate,
  // },
  {
    Header: 'Actions',
    Cell: (cell: CellProps<User, number>) =>
      RenderPopup(
        cell,
        onSelectUser,
        onEditUser,
        onDownloadPlaylist,
        onKillConnections,
        onBanUser,
        onDisableUser,
        onDeleteUser,
      ),
  },
];
