import useRequest from '../../lib/hooks/useRequest';
import { editCategory } from '../../lib/api';
import { useEffect } from 'react';
import { useCategories } from './useCategories';

export const useEditCategory = () => {
  const [_editCategory, loading, _editCategoryRes, _editCategoryErr] = useRequest(editCategory);
  const { getAllCategories, completed } = useCategories();
  useEffect(() => {
    if (!_editCategoryRes) return;
    getAllCategories();
  }, [_editCategoryRes, getAllCategories]);

  useEffect(() => {
    if (_editCategoryErr) {
      console.log('_getAllCategoriesErr: ', _editCategoryErr);
    }
  }, [_editCategoryErr]);
  return { editCategory: _editCategory, editCategoryRes: _editCategoryRes, loading, completed };
};
