import React from 'react';
import styled from 'styled-components';
import { menuItems, settingMenuItems } from '../config/data/menuData';
import DownMenu from '../components/layout/DownMenu';
import UserInfo from '../components/layout/UserInfo';
import { device } from '../styles/device';

const SideBar = () => {
  return (
    <SideBarComponent>
      <UserInfo className='user-info-card card' />
      <DownMenu menuItems={menuItems} className='menu-card card' />
      <DownMenu menuItems={settingMenuItems} className='settings-card card' />
    </SideBarComponent>
  );
};

const SideBarComponent = styled.nav`
  flex: 0 0 217px;
  padding: 0;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .user-info-card {
    flex-shrink: 0;
    flex-grow: 0;
    height: 124px;
    width: 100%;
  }

  .menu-card {
    flex-shrink: 0;
    flex-grow: 0;
    width: 100%;
  }

  .settings-card {
    flex-shrink: 0;
    flex-grow: 0;
    width: 100%;
  }

  @media ${device.mobileM} {
    display: none;
  }
`;

export default SideBar;
