import React, { useEffect } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import MainLayout from './layout/MainLayout';
import LoginPage from './pages/LoginPage';
import Dashboard from './pages/Dashboard';
import HomePage from './pages/HomePage';
import BouquetsPage from './pages/BouquetsPage';
import CategoriesPage from './pages/CategoriesPage';
import Page404 from './pages/Page404';
import Page500 from './pages/Page500';
import StreamsPage from './pages/StreamsPage';
import UserPage from './pages/UserPage';
import SettingsPage from './pages/SettingsPage';
import { useCategories } from './hooks/category/useCategories';
import { useBouquets } from './hooks/bouquet/useBouquets';
import { useServerStats } from './hooks/status/useServerStats';

const router = createBrowserRouter([
  { path: 'login', Component: LoginPage },
  { path: '404', Component: Page404 },
  { path: '500', Component: Page500 },
  {
    Component: MainLayout,
    children: [
      { index: true, Component: HomePage },
      { path: 'dashboard', Component: Dashboard },
      {
        path: 'user',
        children: [
          { path: 'add-line', Component: UserPage },
          { path: 'manage-lines', Component: UserPage },
        ],
      },
      {
        path: 'streams',
        children: [
          { path: 'add-stream', Component: StreamsPage },
          { path: 'import-streams', Component: StreamsPage },
          { path: 'manage-streams', Component: StreamsPage },
        ],
      },
      {
        path: 'management',
        children: [
          { path: 'categories', Component: CategoriesPage },
          { path: 'bouquets', Component: BouquetsPage },
          { path: 'bouquets/manage-bouquets', Component: BouquetsPage },
        ],
      },
      { path: 'settings', Component: SettingsPage },
    ],
  },
  { path: '*', Component: Page404 },
]);

function App() {
  const { getAllCategories } = useCategories();
  const { getAllBouquets } = useBouquets();
  useServerStats();

  useEffect(() => {
    getAllCategories();
    getAllBouquets();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <RouterProvider router={router} />;
}

export default App;
