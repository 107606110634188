import React from 'react';
import Select from 'react-select';
import styled from 'styled-components';
import { settingSelectStyles } from '../../../styles/reactSelect';
import { CommonSelectItem } from '../../../types/model/commonSelectItem';

const fpsCheckTypeOptions: CommonSelectItem[] = [
  { value: 'progressInfo', label: 'Progress Info' },
  { value: 'avgFrameRate', label: 'avg_frame_rate' },
];

interface Props {
  className?: string;
  value: string;
  onChange: (value: string) => void;
}

const FpsCheckType = ({ className, value, onChange }: Props) => {
  return (
    <FpsCheckTypeComponents className={className}>
      <Select<CommonSelectItem>
        className='form-item-select'
        options={fpsCheckTypeOptions}
        styles={settingSelectStyles}
        value={fpsCheckTypeOptions[fpsCheckTypeOptions.findIndex(option => option.value === value)]}
        onChange={newValue => onChange(newValue?.value ?? '')}
      />
    </FpsCheckTypeComponents>
  );
};

const FpsCheckTypeComponents = styled.div``;

export default FpsCheckType;
