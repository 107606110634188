import React, { useEffect, useState } from 'react';
import FormTab from '../../common/FormTab';
import { TabData } from '../../common/Tab';
import BouquetsDetailsForm from './../BouquetsDetailsForm';
import { Bouquet } from '../../../types/model/bouquet';
import ModalForm from '../../common/ModalForm';
import { useEditBouquet } from '../../../hooks/bouquet/useEditBouquet';

const tabData: TabData[] = [
  {
    id: 1,
    tabTitle: 'Details',
    tabComponent: ({ ...props }) => BouquetsDetailsForm({ ...props, isEdit: true }),
  },
];

interface Props {
  bouquet: Bouquet;
  isOpen: boolean;
  onClose: () => void;
}

const EditBouquetForm = ({ bouquet, isOpen, onClose }: Props) => {
  const [bouquetData, setBouquetData] = useState<Bouquet>(bouquet);
  const [submitted, setSubmitted] = useState(false);
  const { editBouquet, completed } = useEditBouquet();

  useEffect(() => {
    setBouquetData(bouquet);
  }, [bouquet]);

  useEffect(() => {
    if (submitted && completed) {
      setSubmitted(false);
      onClose();
    }
  }, [completed, onClose, submitted]);

  const submitBouquetData = async (data: string) => {
    const newBoquet: Bouquet = {
      id: bouquet.id,
      name: data,
    };
    editBouquet(newBoquet);
    setSubmitted(true);
  };

  return (
    <ModalForm
      className='narrow-modal-form'
      isOpen={isOpen}
      onClose={() => onClose()}
      title={'Edit Bouquet'}
    >
      <FormTab
        data={tabData}
        formData={bouquetData}
        setFormData={setBouquetData}
        currentTab={tabData[0].id}
        submitFormData={submitBouquetData}
      />
    </ModalForm>
  );
};

export default EditBouquetForm;
