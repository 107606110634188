import React from 'react';
import styled from 'styled-components';
import { ServerStatusItem } from '../../types/serverStatusData';
import { useAtom } from 'jotai';
import { serverStatsAtom } from '../../store/jotai';
import { ServerStats } from '../../types/model/serverStats';
import { intervalToDuration } from 'date-fns';

interface Props {
  data: ServerStatusItem;
  className: string;
}

const StatusTableCard = ({ data, className }: Props) => {
  const [serverStats] = useAtom(serverStatsAtom);

  let value: ServerStats;
  switch (data.id) {
    case 'main':
      value = serverStats;
      break;
    default:
      value = {};
  }

  let uptimeStr = '';

  if (value.up_time) {
    const duration = intervalToDuration({ start: 0, end: value.up_time * 1000 });
    if (duration.years) uptimeStr += `${duration.years}y `;
    if (duration.months) uptimeStr += `${duration.months}m `;
    if (duration.days) uptimeStr += `${duration.days}d `;
    if (duration.hours) uptimeStr += `${duration.hours}h `;
    uptimeStr += `${duration.minutes ?? 0}m `;
  }

  return (
    <StatusTableCardComponent className={className}>
      <p className='server-name'>{data.serverName}</p>
      <div className='status-table'>
        <table>
          <tbody>
            <tr>
              <th>Connections</th>
              <td>{value.total_connections ?? 0}</td>
            </tr>
            <tr>
              <th>Users</th>
              <td>{value.connected_users?.length ?? 0}</td>
            </tr>
            <tr>
              <th>CPU</th>
              <td>
                <ProgressBar
                  value={value.cpu_percent ?? 0}
                  color='linear-gradient(180deg, #15B547 0%, #A8FFC5 100%)'
                />
              </td>
            </tr>
          </tbody>
        </table>
        <hr />
        <table>
          <tbody>
            <tr>
              <th>Streams Live</th>
              <td>{value.total_running_streams}</td>
            </tr>
            <tr>
              <th>Down</th>
              <td>{(value.total_streams ?? 0) - (value.total_running_streams ?? 0)}</td>
            </tr>
            <tr>
              <th>MEM</th>
              <td>
                <ProgressBar
                  value={value.memory_percent ?? 0}
                  color='linear-gradient(177.44deg, #6F45E9 2.14%, #63AAFD 72.98%)'
                />
              </td>
            </tr>
          </tbody>
        </table>
        <hr />
        <table>
          <tbody>
            <tr>
              <th>Request /sec</th>
              <td>{value.requests_per_sec}</td>
            </tr>
            <tr>
              <th>Uptime</th>
              <td>{uptimeStr}</td>
            </tr>
            <tr>
              <th>IO</th>
              <td>
                <ProgressBar
                  value={value.io_percent ?? 0}
                  color='linear-gradient(177.44deg, #B538F7 2.14%, #FB8CBD 72.98%)'
                />
              </td>
            </tr>
          </tbody>
        </table>
        <hr />
        <table>
          <tbody>
            <tr>
              <th>Input (Mbps)</th>
              <td>{value.input_speed}</td>
            </tr>
            <tr>
              <th>Output (Mbs)</th>
              <td>{value.output_speed}</td>
            </tr>
            <tr>
              <th>DISK</th>
              <td>
                <ProgressBar
                  value={value.disk_percent ?? 0}
                  color='linear-gradient(177.44deg, #FF2C2C 2.14%, #FFAB2D 72.98%)'
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </StatusTableCardComponent>
  );
};

const StatusTableCardComponent = styled.div`
  padding-top: 20px;
  .server-name {
    padding-left: 30px;
    font-weight: 500;
    font-size: 16px;
  }

  .status-table {
    margin-top: 20px;
    width: 100%;
    padding: 0;

    table {
      width: 100%;
      height: 40px;
      display: flex;
      align-items: center;

      tbody {
        width: 100%;
        padding: 0 30px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
        text-align: left;

        tr {
          th {
            font-size: 13px;
          }

          td {
            font-weight: 300;
            font-size: 10px;
          }

          &:nth-child(1) {
            width: 145px;
            th {
              width: 100px;
            }
          }

          &:nth-child(2) {
            width: 130px;
            th {
              width: 85px;
            }
          }

          &:nth-child(3) {
            width: 50%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            th {
              width: 45px;
              font-weight: 700;
            }

            td {
              width: 100%;
            }
          }
        }
      }
    }

    hr {
      border: 1px solid var(--card-bg);
    }
  }
`;

const ProgressBar = ({ value, color }: { value: number; color: string }) => {
  return (
    <ProgressBarComponent value={value} color={color}>
      <div className='value'></div>
    </ProgressBarComponent>
  );
};

const ProgressBarComponent = styled.div<{ value: number; color: string }>`
  position: relative;
  width: 100%;
  height: 10px;
  border-radius: 5px;
  background-color: var(--dark-bg);

  .value {
    position: absolute;
    top: 0;
    left: 0;
    width: ${props => props.value + '%'};
    height: 10px;
    border-radius: 5px;
    background: ${props => props.color};
  }
`;

export default StatusTableCard;
