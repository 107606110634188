import React from 'react';
import styled from 'styled-components';
import { Controller, useForm, SubmitHandler } from 'react-hook-form';
import { SubmitFormButton } from '../../common/Buttons';
import SlideToggle from '../../common/SlideToggle';
import Tooltip from '../../common/Tooltip';
import { settingWebPlayerTooltipMessage } from '../../../config/content/tooltipData';

export interface SettingsWebPlayerFormInput {
  allowPlaylistDownload: boolean;
  allowBouquetOrdering: boolean;
  hideIncompatibleStreams: boolean;
  markHevcAsCompatible: boolean;
  backgroundBlurPx: number;
  backgroundOpacity: number;
  extractSubtitles: boolean;
}
interface Props {
  className?: string;
  setVisibleTab?: React.Dispatch<React.SetStateAction<number>>;
}

const SettingsWebPlayerForm = ({ className }: Props) => {
  const { handleSubmit, control } = useForm<SettingsWebPlayerFormInput>({
    defaultValues: {
      allowPlaylistDownload: false,
      allowBouquetOrdering: false,
      hideIncompatibleStreams: false,
      markHevcAsCompatible: false,
      backgroundBlurPx: 0,
      backgroundOpacity: 10,
      extractSubtitles: false,
    },
  });

  const onSubmit: SubmitHandler<SettingsWebPlayerFormInput> = data => {
    console.log('data: ', data);
  };

  return (
    <SettingsWebPlayerFormComponent className={className}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='form-sub-title'>Preferences</div>
        <div className='form-item-two-col-card element-card'>
          <Controller
            control={control}
            name='allowPlaylistDownload'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Allow Playlist Download
                  <Tooltip message={settingWebPlayerTooltipMessage.allowPlaylistDownload} />
                </span>
                <SlideToggle value={field.value} onChange={field.onChange} />
              </div>
            )}
          />
          <Controller
            control={control}
            name='allowBouquetOrdering'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Allow Bouquet Ordering
                  <Tooltip message={settingWebPlayerTooltipMessage.allowBouquetOrdering} />
                </span>
                <SlideToggle value={field.value} onChange={field.onChange} />
              </div>
            )}
          />
          <Controller
            control={control}
            name='hideIncompatibleStreams'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Hide Incompatible Streams
                  <Tooltip message={settingWebPlayerTooltipMessage.hideIncompatibleStreams} />
                </span>
                <SlideToggle value={field.value} onChange={field.onChange} />
              </div>
            )}
          />
          <Controller
            control={control}
            name='markHevcAsCompatible'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Mark HEVC as Compatible
                  <Tooltip message={settingWebPlayerTooltipMessage.markHevcAsCompatible} />
                </span>
                <SlideToggle value={field.value} onChange={field.onChange} />
              </div>
            )}
          />
          <Controller
            control={control}
            name='backgroundBlurPx'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Background Blur px
                  <Tooltip message={settingWebPlayerTooltipMessage.backgroundBlurPx} />
                </span>
                <input
                  type='number'
                  className='form-item-two-col-card-item-input'
                  autoComplete='off'
                  placeholder=''
                  {...field}
                />
              </div>
            )}
          />
          <Controller
            control={control}
            name='backgroundOpacity'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Background Opacity %
                  <Tooltip message={settingWebPlayerTooltipMessage.backgroundOpacity} />
                </span>
                <input
                  type='number'
                  className='form-item-two-col-card-item-input'
                  autoComplete='off'
                  placeholder=''
                  {...field}
                />
              </div>
            )}
          />
          <Controller
            control={control}
            name='extractSubtitles'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Extract Subtitles
                  <Tooltip message={settingWebPlayerTooltipMessage.extractSubtitles} />
                </span>
                <SlideToggle value={field.value} onChange={field.onChange} />
              </div>
            )}
          />
        </div>

        <SubmitFormButton className='full-width'>Save</SubmitFormButton>
      </form>
    </SettingsWebPlayerFormComponent>
  );
};

const SettingsWebPlayerFormComponent = styled.div``;

export default SettingsWebPlayerForm;
