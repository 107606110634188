import { useAtom } from 'jotai';
import { useEffect, useState } from 'react';
import { bouquetsAtom } from '../../store/jotai';
import useRequest from '../../lib/hooks/useRequest';
import { getAllBouquets } from '../../lib/api';
import { Bouquet } from '../../types/model/bouquet';

export const useBouquets = () => {
  const [_getAllBouquets, loading, _getAllBouquetsRes, _getAllBouquetsErr] =
    useRequest(getAllBouquets);
  const [, setBouquets] = useAtom(bouquetsAtom);
  const [completed, setCompleted] = useState(false);
  useEffect(() => {
    if (!_getAllBouquetsRes) return;
    const bouquets = (_getAllBouquetsRes.Records as Bouquet[]) ?? [];
    setBouquets(bouquets);
    setTimeout(() => {
      setCompleted(true);
    }, 100);
  }, [_getAllBouquetsRes, setBouquets]);

  useEffect(() => {
    if (_getAllBouquetsErr) {
      console.log('_getAllBouquetsErr: ', _getAllBouquetsErr);
    }
  }, [_getAllBouquetsErr]);
  return {
    getAllBouquets: _getAllBouquets,
    getAllBouquetsRes: _getAllBouquetsRes,
    loading,
    completed,
  };
};
