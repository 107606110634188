import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import Select from 'react-select';
import { CommonSelectItem } from '../../types/model/commonSelectItem';
import FormTitle from '../common/FormTitle';
import ManageTable from '../common/ManageTable';
import { CellProps, Column } from 'react-table';
import { headerSelectStyles } from '../../styles/reactSelect';
import Popup from 'reactjs-popup';
import {
  AudioSvg,
  FpsSvg,
  RunPlayerSvg,
  SpeedSvg,
  StopPlayerSvg,
  VideoSvg,
} from '../../assets/svg';
import { MiddleButton } from '../common/Buttons';
import Paginator from '../common/Paginator';
import { Stream } from '../../types/model/stream';
import ModalWarning from '../common/ModalWarning';
import { PopupActions } from 'reactjs-popup/dist/types';
import { getCategoriesOptions } from './details/Categories';
import { intervalToDuration } from 'date-fns';
import HLSPlayerModal from './modal/HLSPlayerModal';
import { useAtom } from 'jotai';
import { categoriesAtom, streamsAtom } from '../../store/jotai';
import EditStreamModal from './modal/EditStreamModal';
import { useStreams } from '../../hooks/stream/useStreams';
import { useStartStream } from '../../hooks/stream/useStartStream';
import { useStopStream } from '../../hooks/stream/useStopStream';
import { useDeleteStream } from '../../hooks/stream/useDeleteStream';
import { useEditStream } from '../../hooks/stream/useEditStream';
import { useGetUsersByStream } from '../../hooks/status/useGetUsersByStream';

const deleteMessage =
  'Are you sure you want to delete this stream? All connections will be deleted.';

const serverFilterOptions: CommonSelectItem[] = [
  { value: 'allServers', label: 'All Servers' },
  { value: 'noServers', label: 'No Servers' },
  { value: 'mainServers', label: 'Main Servers' },
];
const categoryFilterOptions: CommonSelectItem[] = [
  { value: 'allCategories', label: 'All Categories' },
  { value: 'noCategories', label: 'No Categories' },
  { value: 'spania', label: 'Spania' },
];
const onlineFilterOptions: CommonSelectItem[] = [
  { value: 'noFilter', label: 'No Filter' },
  { value: 'online', label: 'Online' },
  { value: 'down', label: 'Down' },
  { value: 'stopped', label: 'Stopped' },
  { value: 'starting', label: 'Starting' },
  { value: 'onDemand', label: 'On Demand' },
  { value: 'direct', label: 'Direct' },
  { value: 'timeshift', label: 'Timeshift' },
  { value: 'looping', label: 'Looping' },
  { value: 'hasEPG', label: 'Has EPG' },
  { value: 'noEPG', label: 'No EPG' },
  { value: 'adaptiveLink', label: 'Adaptive Link' },
  { value: 'titleSync', label: 'Title Sync' },
  { value: 'transcoding', label: 'Transcoding' },
];
const audioFilterOptions: CommonSelectItem[] = [
  { value: 'audio', label: 'Audio' },
  { value: 'none', label: 'None' },
  { value: 'aac', label: 'aac' },
];
const videoFilterOptions: CommonSelectItem[] = [
  { value: 'video', label: 'Video' },
  { value: 'none', label: 'None' },
  { value: 'h264', label: 'h264' },
];
const qualityFilterOptions: CommonSelectItem[] = [
  { value: 'quality', label: 'Quality' },
  { value: '240p', label: '240p' },
  { value: '360p', label: '360p' },
  { value: '480p', label: '480p' },
  { value: '576p', label: '576p' },
  { value: '720p', label: '720p' },
  { value: '1080p', label: '1080p' },
  { value: '1440p', label: '1440p' },
  { value: '2160p', label: '2160p' },
];

const showOptions: CommonSelectItem[] = [
  { value: '10', label: '10' },
  { value: '25', label: '25' },
  { value: '50', label: '50' },
  { value: '250', label: '250' },
  { value: '500', label: '500' },
  { value: '1000', label: '1000' },
];

interface Props {
  className?: string;
}

const ManageStreamsForm = ({ className }: Props) => {
  const [streams] = useAtom(streamsAtom);
  useStreams();
  const { startStream } = useStartStream();
  const { stopStream } = useStopStream();
  const { deleteStream } = useDeleteStream();
  const { editStream } = useEditStream();
  const [selectedServerFilter, setSelectedServerFilter] = useState<CommonSelectItem | null>(null);
  const [selectedCategoryFilter, setSelectedCategoryFilter] = useState<CommonSelectItem | null>(
    null,
  );
  const [selectedOnlineFilter, setSelectedOnlineFilter] = useState<CommonSelectItem | null>(null);
  const [selectedAudioFilter, setSelectedAudioFilter] = useState<CommonSelectItem | null>(null);
  const [selectedVideoFilter, setSelectedVideoFilter] = useState<CommonSelectItem | null>(null);
  const [selectedQualityFilter, setSelectedQualityFilter] = useState<CommonSelectItem | null>(null);
  const [showNumber, setShowNumber] = useState<CommonSelectItem | null>(showOptions[0]);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showPlayerModal, setShowPlayerModal] = useState(false);
  const [data, setData] = useState<Stream[]>(streams.slice(0, Number(showNumber?.value ?? 10)));
  const [page, setPage] = useState(0);

  const selectedStreamRef = useRef<Stream | null>(null);

  useEffect(() => {
    setData(
      streams.slice(
        (page - 1) * Number(showNumber?.value ?? 10),
        page * Number(showNumber?.value ?? 10),
      ),
    );
    setShowEditModal(false);
  }, [page, showNumber?.value, streams]);

  const onPageChange = (page: number) => {
    setPage(page);
  };

  const onEditStream = () => {
    setShowEditModal(true);
  };

  const onStopStream = () => {
    stopStream(selectedStreamRef.current?.id ?? '');
  };

  const onRestartStream = () => {
    startStream(selectedStreamRef.current?.id ?? '');
  };

  const onKillConnections = () => {
    // TODO Kill Connections
  };

  const onDeleteStream = () => {
    // Show Confirm Modal to Delete Stream
    setShowDeleteModal(true);
  };

  const onConfirmDeleteUser = () => {
    setShowDeleteModal(false);
    // Call delete user api
    deleteStream(selectedStreamRef.current?.id ?? '');
  };

  const onPlayer = () => {
    setShowPlayerModal(true);
  };

  const onSubmitStream = (stream: Stream) => {
  //  onCloseEditModal();
    editStream(stream);
  };

  const onCloseEditModal = () => {
    setShowEditModal(false);
  };

  const columns: Column<Stream>[] = useMemo(
    () =>
      getStreamsHeader(
        (stream: Stream) => (selectedStreamRef.current = stream),
        onStopStream,
        onRestartStream,
        onKillConnections,
        onEditStream,
        onDeleteStream,
        onPlayer,
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <ManageStreamsFormComponents className={className}>
      <FormTitle>Manage Streams</FormTitle>
      <div className='form-header'>
        <div className='filter-container'>
          <input
            type='search'
            className='form-header-input'
            autoComplete='on'
            placeholder='Search Streams...'
          />

          <Select<CommonSelectItem>
            isClearable
            className='form-header-select'
            options={serverFilterOptions}
            styles={headerSelectStyles}
            placeholder='Servers...'
            value={selectedServerFilter}
            onChange={value => {
              setSelectedServerFilter(value);
            }}
          />
          <Select<CommonSelectItem>
            isClearable
            className='form-header-select'
            options={categoryFilterOptions}
            styles={headerSelectStyles}
            placeholder='Categories...'
            value={selectedCategoryFilter}
            onChange={value => {
              setSelectedCategoryFilter(value);
            }}
          />
          <Select<CommonSelectItem>
            isClearable
            className='form-header-select'
            options={onlineFilterOptions}
            styles={headerSelectStyles}
            placeholder='Online...'
            value={selectedOnlineFilter}
            onChange={value => {
              setSelectedOnlineFilter(value);
            }}
          />
          <Select<CommonSelectItem>
            isClearable
            className='form-header-select'
            options={audioFilterOptions}
            styles={headerSelectStyles}
            placeholder='Audio...'
            value={selectedAudioFilter}
            onChange={value => {
              setSelectedAudioFilter(value);
            }}
          />
          <Select<CommonSelectItem>
            isClearable
            className='form-header-select'
            options={videoFilterOptions}
            styles={headerSelectStyles}
            placeholder='Video...'
            value={selectedVideoFilter}
            onChange={value => {
              setSelectedVideoFilter(value);
            }}
          />
          <Select<CommonSelectItem>
            isClearable
            className='form-header-select'
            options={qualityFilterOptions}
            styles={headerSelectStyles}
            placeholder='Quality...'
            value={selectedQualityFilter}
            onChange={value => {
              setSelectedQualityFilter(value);
            }}
          />
        </div>
        <div className='show-container'>
          <span className='form-header-name'>Show</span>
          <Select<CommonSelectItem>
            className='form-header-select'
            options={showOptions}
            styles={headerSelectStyles}
            value={showNumber}
            onChange={value => {
              setShowNumber(value);
            }}
          />
        </div>
      </div>
      <div className='table-container'>
        <ManageTable<Stream> columns={columns} data={data} />
      </div>
      <Paginator
        totalRecords={streams.length}
        pageSize={Number(showNumber?.value ?? 10)}
        onPageChange={onPageChange}
      />
      <ModalWarning
        isOpen={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        noIconOnOk
        nextButtonTitle='Delete'
        handleCancel={() => setShowDeleteModal(false)}
        handleOk={onConfirmDeleteUser}
      >
        {deleteMessage}
      </ModalWarning>
      <HLSPlayerModal
        sourceURL={selectedStreamRef.current?.url ?? ''}
        isOpen={showPlayerModal}
        onClose={() => setShowPlayerModal(false)}
      />
      {selectedStreamRef.current && (
        <EditStreamModal
          stream={selectedStreamRef.current}
          isOpen={showEditModal}
          onClose={onCloseEditModal}
          onSubmit={onSubmitStream}
        />
      )}
    </ManageStreamsFormComponents>
  );
};

const ManageStreamsFormComponents = styled.div`
  width: 100%;
  max-width: 2500px;
  margin: 30px auto 0;

  .form-header {
    margin: 40px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 25px;

    .filter-container {
      flex: 1 1 auto;
      max-width: 1000px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 4px;

      .form-header-input {
        flex-grow: 1;
        max-width: 280px;
        min-width: 200px;
        padding: 11px 12px;
        border-radius: 19px;
        background-color: var(--dark-bg);
        font-size: 14px;
        color: var(--text-form-content);
      }

      .form-header-select {
        flex-grow: 1;
        max-width: 280px;
        font-size: 14px;
        color: var(--text-form-content);

        &:nth-child(-n + 4) {
          min-width: 150px;
        }

        &:nth-child(n + 5) {
          min-width: 130px;
        }
      }
    }

    .show-container {
      display: flex;
      align-items: center;
      gap: 16px;

      .form-header-select {
        flex-shrink: 0;
        width: 100px;
        font-size: 14px;
        color: var(--text-form-content);
      }

      .form-header-name {
        font-size: 14px;
        color: var(--text-form-content);
      }
    }
  }

  .table-container {
    width: 100%;
    overflow: auto;
  }

  .actions-button {
    padding: 6px 0;
    border-radius: 16px;
    background: var(--dark-bg);
    font-size: 16px;
    box-shadow: 0px 2px 13px 1px #1e1e3f;

    &:hover {
      filter: brightness(2);
      cursor: pointer;
    }
    &:focus {
      outline: white solid 2px;
      outline-offset: 2px;
    }
    &:active {
      box-shadow: 0px 2px 13px 0px #1e1e3f;
      opacity: 0.7;
    }
  }

  .player-button {
    margin: 0 auto;
    padding: 7px 10px;
  }

  .stream-info-container {
    height: 100%;
    width: 100%;
    max-width: 400px;
    background-color: var(--dark-bg);
    border-bottom: 1px solid var(--placeholder-dark);
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 10px;
    font-size: 14px;

    .col-container {
      padding-top: 10px;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      gap: 8px;
    }

    .main-info {
      padding: 0;
      flex-grow: 3;
    }
  }
`;

export default ManageStreamsForm;

const renderIcon = (cell: CellProps<Stream, string>): JSX.Element => (
  <div className='modal-table-icon-bg'>
    <img src={cell.value} alt='icon' height={'100%'} />
  </div>
);
const RenderClients = (cell: CellProps<Stream, number>): JSX.Element => {
  const { clients } = useGetUsersByStream(cell.row.original.id, cell.value ?? 0);
  return (
    <div
      style={{
        margin: '0 auto',
        height: '28px',
        width: 'fit-content',
        padding: '0 9px',
        borderRadius: '8px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: cell.value ? '#7bffa5' : 'white',
        fontSize: '16px',
        color: '#13132c',
      }}
    >
      {clients}
    </div>
  );
};
const renderUptime = (cell: CellProps<Stream, number>): JSX.Element => {
  const status = cell.row.original.status;
  let statusLabel = '';
  let statusBgColor = '';
  if (status === 'stopped') {
    statusLabel = 'STOPPED';
    statusBgColor = 'yellow';
  } else if (status === 'running') {
    let uptimeStr = '';

    if (cell.value) {
      const duration = intervalToDuration({ start: cell.value * 1000, end: new Date() });
      if (duration.years) uptimeStr += `${duration.years}y `;
      if (duration.months) uptimeStr += `${duration.months}m `;
      if (duration.days) uptimeStr += `${duration.days}d `;
      if (duration.hours) uptimeStr += `${duration.hours}h `;
      if (duration.minutes) uptimeStr += `${duration.minutes ?? 0}m `;
      uptimeStr += `${duration.seconds ?? 0}s `;
    }
    statusLabel = uptimeStr;
    statusBgColor = '#7bffa5';
  } else {
    statusLabel = 'DOWN';
    statusBgColor = '#ff7b7b';
  }
  return (
    <div
      style={{
        margin: '0 auto',
        height: '28px',
        width: '88px',
        borderRadius: '8px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: statusBgColor,
        fontSize: '12px',
        color: '#13132c',
      }}
    >
      {statusLabel}
    </div>
  );
};

const RenderName = (cell: CellProps<Stream, string>): JSX.Element => {
  const [categories] = useAtom(categoriesAtom);
  return (
    <div>
      <div style={{ fontSize: '16px' }}>{cell.value}</div>
      <div style={{ fontSize: '11px' }}>
        {getCategoriesOptions(categories, cell.row.original.categories)[0]?.name ?? 'No Category'}
      </div>
    </div>
  );
};
const renderServers = (cell: CellProps<Stream, string>): JSX.Element => (
  <div>
    <div style={{ fontSize: '16px' }}>Main Server</div>
    <div style={{ fontSize: '11px' }}>{cell.value}</div>
  </div>
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const popupBody: any = (
  onStopStream: () => void,
  onRestartPlaylist: () => void,
  onKillConnections: () => void,
  onEditStream: () => void,
  onDeleteStream: () => void,
) => (
  <div className='popup-menu'>
    <div className='popup-menu-item' onClick={onStopStream}>
      Stop
    </div>
    <div className='popup-menu-item' onClick={onRestartPlaylist}>
      Restart
    </div>
    {/* <div className='popup-menu-item' onClick={onKillConnections}>
      Kill Connections
    </div> */}
    <div className='popup-menu-item' onClick={onEditStream}>
      Edit
    </div>
    <div className='popup-menu-item' onClick={onDeleteStream}>
      Delete
    </div>
  </div>
);

const RenderPopup = (
  cell: CellProps<Stream, number>,
  onSelectStream: (stream: Stream) => void,
  onStopStream: () => void,
  onRestartPlaylist: () => void,
  onKillConnections: () => void,
  onEditStream: () => void,
  onDeleteStream: () => void,
): JSX.Element => {
  const stream = cell.row.original;
  const popupRef = useRef<PopupActions | null>(null);
  const _onStopStream = () => {
    popupRef.current?.close();
    onStopStream();
  };
  const _onRestartPlaylist = () => {
    popupRef.current?.close();
    onRestartPlaylist();
  };
  const _onKillConnections = () => {
    popupRef.current?.close();
    onKillConnections();
  };
  const _onEditStream = () => {
    popupRef.current?.close();
    onEditStream();
  };
  const _onDeleteStream = () => {
    popupRef.current?.close();
    onDeleteStream();
  };
  return (
    <Popup
      ref={popupRef}
      trigger={<div className='actions-button'> Actions </div>}
      position='left center'
      on='click'
      onOpen={() => onSelectStream(stream)}
      closeOnDocumentClick
      closeOnEscape
      mouseLeaveDelay={300}
      mouseEnterDelay={0}
      arrow={false}
    >
      {popupBody(
        _onStopStream,
        _onRestartPlaylist,
        _onKillConnections,
        _onEditStream,
        _onDeleteStream,
      )}
    </Popup>
  );
};

const renderPlayer = (
  cell: CellProps<Stream, number>,
  onSelectStream: (stream: Stream) => void,
  onPlayer: () => void,
): JSX.Element => {
  const stream = cell.row.original;

  const handleOnClick = () => {
    onSelectStream(stream);
    onPlayer();
  };

  return (
    <MiddleButton bgColor={'#7bffa5'} className='player-button' onClick={handleOnClick}>
      <RunPlayerSvg />
    </MiddleButton>
  );
};
const renderEpg = (): JSX.Element => (
  <MiddleButton bgColor={'#7bffa5'} className='player-button'>
    <StopPlayerSvg />
  </MiddleButton>
);

const renderStreamInfo = (
  cell: CellProps<
    Stream,
    {
      width?: string;
      height?: string;
      speed?: string;
      video_codec?: string;
      frame_rate?: string;
      audio_codec?: string;
      sample_rate?: string;
    }
  >,
): JSX.Element => {
  const status = cell.row.original.status;
  if (status !== 'running') {
    return <div className='stream-info-container'>-</div>;
  }

  return (
    <div className='stream-info-container'>
      <div className='col-container main-info'>
        <div> {`${Number(cell.value?.sample_rate) / 1000 ?? '-'} Kbps`}</div>
        <div>
          {cell.value?.width ?? '-'}&times;{cell.value?.height ?? '-'}
        </div>
      </div>
      <div className='col-container'>
        <VideoSvg />
        <div> {cell.value?.video_codec ?? '-'}</div>
      </div>
      <div className='col-container'>
        <AudioSvg />
        <div> {cell.value?.audio_codec ?? '-'}</div>
      </div>
      <div className='col-container'>
        <SpeedSvg />
        <div> {`${cell.value?.speed ?? 0} x`}</div>
      </div>
      <div className='col-container'>
        <FpsSvg />
        <div> {`${cell.value?.frame_rate ?? 0} FPS`}</div>
      </div>
    </div>
  );
};

const getStreamsHeader: (
  onSelectStream: (stream: Stream) => void,
  onStopStream: () => void,
  onRestartPlaylist: () => void,
  onKillConnections: () => void,
  onEditStream: () => void,
  onDeleteStream: () => void,
  onPlayer: () => void,
) => Column<Stream>[] = (
  onSelectStream,
  onStopStream,
  onRestartPlaylist,
  onKillConnections,
  onEditStream,
  onDeleteStream,
  onPlayer,
) => [
  {
    Header: 'ID',
    accessor: 'id',
  },
  {
    Header: 'Icon',
    accessor: 'stream_icon',
    Cell: renderIcon,
  },
  {
    Header: 'Name',
    accessor: 'name',
    Cell: RenderName,
  },
  {
    Header: 'Servers',
    accessor: 'url',
    Cell: renderServers,
  },
  {
    Header: 'Clients',
    accessor: 'clients',
    Cell: RenderClients,
  },
  {
    Header: 'Uptime',
    accessor: 'start_time',
    Cell: renderUptime,
  },
  {
    Header: 'Actions',
    Cell: (cell: CellProps<Stream, number>) =>
      RenderPopup(
        cell,
        onSelectStream,
        onStopStream,
        onRestartPlaylist,
        onKillConnections,
        onEditStream,
        onDeleteStream,
      ),
  },
  {
    Header: 'Player',
    Cell: (cell: CellProps<Stream, number>) => renderPlayer(cell, onSelectStream, onPlayer),
  },
  {
    Header: 'EPG',
    Cell: renderEpg,
  },
  {
    Header: 'Stream Info',
    accessor: 'info',
    Cell: renderStreamInfo,
  },
];
