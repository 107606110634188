import React from 'react';
import styled from 'styled-components';
import { StateItem } from '../../types/stateData';
import { useAtom } from 'jotai';
import { serverStatsAtom } from '../../store/jotai';

interface Props {
  data: StateItem;
  borderColor: [string, string];
}

const DashboardStateItem = ({ data, borderColor }: Props) => {
  const [serverStats] = useAtom(serverStatsAtom);

  let value: number;
  switch (data.id) {
    case 'onlineConnections':
      value = serverStats.total_connections ?? -1;
      break;
    case 'activeLines':
      value = serverStats.connected_users?.length ?? -1;
      break;
    case 'liveStreams':
      value = serverStats.total_running_streams ?? -1;
      break;
    case 'downStreams':
      value = (serverStats.total_streams ?? 0) - (serverStats.total_running_streams ?? 0);
      break;
    case 'networkInput':
      value = serverStats.input_speed ?? -1;
      break;
    case 'networkOutput':
      value = serverStats.output_speed ?? -1;
      break;
    default:
      value = -1;
  }

  return (
    <DashboardStateItemComponent $borderColor={borderColor}>
      <div className='goal-box'>
        <p className='goal'>{value}</p>
        <p className='unit'>{data.unit ?? ''}</p>
      </div>
      <div className='detail-box'>
        <data.icon />
        <div className={data.state ? 'online-mark' : 'offline-mark'}></div>
        <div className='item-name'>{data.title}</div>
      </div>
    </DashboardStateItemComponent>
  );
};

const DashboardStateItemComponent = styled.div<{ $borderColor: [string, string] }>`
  width: 100%;
  height: 189px;
  background: ${props => `padding-box linear-gradient(var(--middle-grey), var(--middle-grey)),
    border-box linear-gradient(to bottom, ${props.$borderColor[0]}, ${props.$borderColor[1]})`};
  border-radius: 8px;
  border: 3px solid transparent;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;

  .goal-box {
    position: relative;
    height: 101px;
    min-width: 123px;
    padding: 8px 11px;
    border-radius: 100px;
    background-color: var(--placeholder-dark);
    border: 4px solid #515a8b;

    display: flex;
    justify-content: center;
    align-items: center;

    .goal {
      font-size: 64px;
    }

    .unit {
      position: absolute;
      bottom: 8px;
      font-size: 10px;
    }
  }

  .detail-box {
    height: 28px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;

    .online-mark {
      flex-shrink: 0;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: var(--online-state-1);
    }

    .offline-mark {
      flex-shrink: 0;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: var(--offline-state-1);
    }

    .item-name {
      width: 79px;
      font-size: 14px;
    }
  }
`;

export default DashboardStateItem;
