import React from 'react';
import styled from 'styled-components';
import {
  AddSvg,
  ArrowRotateLeftSvg,
  ArrowRotateRightSvg,
  AttentionSvg,
  AudioSvg,
  CheckBoxFalseSvg,
  CheckBoxTrueSvg,
  CheckGreenSvg,
  CircleExclamationSvg,
  CloudArrowDownSvg,
  CopySvg,
  DashboardSvg,
  DownArrowSmallSvg,
  DownArrowSvg,
  DownRateSvg,
  EditSvg,
  ExitSvg,
  EyeSvg,
  FlowRateSvg,
  FpsSvg,
  ListSvg,
  ListUlSvg,
  LiveSvg,
  MagnifyingGlassSvg,
  MagnifyingGlassWhiteSvg,
  ManagementSvg,
  NextArrowSvg,
  OnlineConnectionsSvg,
  PrevArrowSvg,
  QuestionMarkSvg,
  RunPlayerSvg,
  RunSvg,
  ServerSvg,
  SettingSvg,
  SortAscSvg,
  SortDescSvg,
  SortSvg,
  SpeedSvg,
  StopPlayerSvg,
  StopSvg,
  StreamsSvg,
  TreeLine1Svg,
  UpArrowSmallSvg,
  UpRateSvg,
  UserSvg,
  VideoSvg,
  WarningSvg,
  XMarkCircleSvg,
  XMarkRedSvg,
  XMarkSvg,
} from '../assets/svg';
import {
  ChipsButton,
  ColorableButton,
  ComboboxButton,
  CommonButton,
  FormButton,
  IconButton,
  IconSimpleButton,
  MiddleButton,
  PrimaryFormButton,
  RadiusButton,
  SecondaryFormButton,
  SubmitFormButton,
  WhiteBorderButton,
} from '../components/common/Buttons';

const HomePage = () => {
  return (
    <HomePageComponents>
      <div className='buttons'>
        <CommonButton>CommonButton</CommonButton>
        <RadiusButton>RadiusButton</RadiusButton>
        <ComboboxButton>ComboboxButton</ComboboxButton>
        <WhiteBorderButton>WhiteBorderButton</WhiteBorderButton>
        WhiteBorderButton
        <WhiteBorderButton
          className='icon-button'
          onClick={() => {
            console.log('clicked');
          }}
        >
          <XMarkSvg style={{ height: '18px', width: '18px' }} />
        </WhiteBorderButton>
        <MiddleButton>MiddleButton</MiddleButton>
        <MiddleButton bgColor={'#4bd075'}>MiddleButton</MiddleButton>
        <MiddleButton bgColor={'#4C4C6C'}>MiddleButton</MiddleButton>
        <FormButton>FormButton</FormButton>
        <ColorableButton bgColor={'#d34848'}>ColorableButton</ColorableButton>
        <PrimaryFormButton>PrimaryFormButton</PrimaryFormButton>
        <SecondaryFormButton>SecondaryFormButton</SecondaryFormButton>
        <SubmitFormButton>SubmitFormButton</SubmitFormButton>
        IconButton
        <IconButton
          onClick={() => {
            console.log('clicked');
          }}
          size={32}
          color='#DC6B00'
        >
          <ArrowRotateLeftSvg />
        </IconButton>
        <IconButton
          onClick={() => {
            console.log('help');
          }}
        >
          <QuestionMarkSvg />
        </IconButton>
        <IconButton
          onClick={() => {
            console.log('clicked');
          }}
          size={25}
        >
          <XMarkSvg />
        </IconButton>
        ChipsButton
        <ChipsButton
          onClick={() => {
            console.log('clicked');
          }}
          height={32}
          color='#55556f'
        >
          <CloudArrowDownSvg />
          <p>.xlsx</p>
        </ChipsButton>
        IconSimpleButton
        <IconSimpleButton size={28} onClick={() => console.log('clicked')}>
          <EditSvg style={{ height: '18px', width: '18px' }} />
        </IconSimpleButton>
        <IconSimpleButton size={28} onClick={() => console.log('clicked')}>
          <XMarkCircleSvg />
        </IconSimpleButton>
      </div>
      <hr />

      <div className='icons'>
        <MagnifyingGlassSvg />
        MagnifyingGlassSvg
        <MagnifyingGlassWhiteSvg />
        MagnifyingGlassWhiteSvg
        <OnlineConnectionsSvg />
        OnlineConnectionsSvg
        <FlowRateSvg />
        FlowRateSvg
        <LiveSvg />
        LiveSvg
        <DownRateSvg />
        DownRateSvg
        <UpRateSvg />
        UpRateSvg
        <WarningSvg />
        WarningSvg
        <DownArrowSvg />
        DownArrowSvg
        <DownArrowSmallSvg />
        DownArrowSmallSvg
        <UpArrowSmallSvg />
        UpArrowSmallSvg
        <AddSvg />
        AddSvg
        <EditSvg />
        EditSvg
        <DashboardSvg />
        DashboardSvg
        <UserSvg />
        UserSvg
        <StreamsSvg />
        StreamsSvg
        <ManagementSvg />
        ManagementSvg
        <SettingSvg />
        SettingSvg
        <ExitSvg />
        ExitSvg
        <AttentionSvg />
        AttentionSvg
        <XMarkRedSvg />
        XMarkRedSvg
        <XMarkSvg />
        XMarkSvg
        <CheckGreenSvg />
        CheckGreenSvg
        <ArrowRotateLeftSvg />
        ArrowRotateLeftSvg
        <ArrowRotateRightSvg />
        ArrowRotateRightSvg
        <CloudArrowDownSvg />
        CloudArrowDownSvg
        <NextArrowSvg />
        NextArrowSvg
        <PrevArrowSvg />
        PrevArrowSvg
        <QuestionMarkSvg />
        QuestionMarkSvg
        <SortSvg />
        SortSvg
        <SortAscSvg />
        SortAscSvg
        <SortDescSvg />
        SortDescSvg
        <EyeSvg />
        EyeSvg
        <CircleExclamationSvg />
        CircleExclamationSvg
        <CheckBoxTrueSvg />
        CheckBoxTrueSvg
        <CheckBoxFalseSvg />
        CheckBoxFalseSvg
        <RunSvg />
        RunSvg
        <StopSvg />
        StopSvg
        <ServerSvg />
        ServerSvg
        <TreeLine1Svg />
        TreeLine1Svg
        <RunPlayerSvg />
        RunPlayerSvg
        <StopPlayerSvg />
        StopPlayerSvg
        <VideoSvg />
        VideoSvg
        <AudioSvg />
        AudioSvg
        <SpeedSvg />
        SpeedSvg
        <FpsSvg />
        FpsSvg
        <ListSvg />
        ListSvg
        <XMarkCircleSvg />
        XMarkCircleSvg
        <CopySvg />
        CopySvg
        <ListUlSvg />
        ListUlSvg
      </div>
      <hr />

      <div className='colors'>
        <div style={{ backgroundColor: '#13132C' }} className='color-container'></div>
        <p>--dark-bg</p>
        <p>#13132C</p>

        <div style={{ backgroundColor: '#2B2B4B' }} className='color-container'></div>
        <p>--card-bg</p>
        <p>#2B2B4B</p>

        <div style={{ backgroundColor: '#363659' }} className='color-container'></div>
        <p>--element-card-bg</p>
        <p>#363659</p>

        <div style={{ backgroundColor: '#4C4C6C' }} className='color-container'></div>
        <p>--placeholder-dark</p>
        <p>#4C4C6C</p>

        <div style={{ backgroundColor: '#7345ea' }} className='color-container'></div>
        <p>--combobox-on</p>
        <p>#7345ea</p>

        <div style={{ backgroundColor: '#38385f' }} className='color-container'></div>
        <p>--table-odd-row</p>
        <p>#38385f</p>

        <div style={{ backgroundColor: '#7345ea' }} className='color-container'></div>
        <p>--primary-button-bg</p>
        <p>#7345ea</p>

        <div style={{ backgroundColor: '#55556F' }} className='color-container'></div>
        <p>--light-grey</p>
        <p>#55556F</p>

        <div style={{ backgroundColor: '#232349' }} className='color-container'></div>
        <p>--middle-grey</p>
        <p>#232349</p>

        <div style={{ backgroundColor: '#1f1f41' }} className='color-container'></div>
        <p>--dark-grey</p>
        <p>#1f1f41</p>

        <div style={{ backgroundColor: '#6d6d87' }} className='color-container'></div>
        <p style={{ color: '#6d6d87' }}>--text-secondary</p>
        <p>#6d6d87</p>

        <div style={{ backgroundColor: '#d6d6ff' }} className='color-container'></div>
        <p style={{ color: '#d6d6ff' }}>--text-form-content</p>
        <p>#d6d6ff</p>

        <div style={{ backgroundColor: '#d9d9d9' }} className='color-container'></div>
        <p style={{ color: '#d9d9d9' }}>--text-button</p>
        <p>#d9d9d9</p>

        <div style={{ backgroundColor: '#a8ffc5' }} className='color-container'></div>
        <p>--online-state</p>
        <p>#a8ffc5</p>

        <div style={{ backgroundColor: '#26ca88' }} className='color-container'></div>
        <p>--online-state-1</p>
        <p>#26ca88</p>

        <div style={{ backgroundColor: '#dc6b00' }} className='color-container'></div>
        <p>--offline-state-1</p>
        <p>#dc6b00</p>

        <div style={{ backgroundColor: '#304ad2' }} className='color-container'></div>
        <p>--online-state-2</p>
        <p>#304ad2</p>

        <div style={{ backgroundColor: 'white' }} className='color-container'></div>
        <p>--white</p>
        <p>white</p>

        <div style={{ backgroundColor: 'black' }} className='color-container'></div>
        <p>--black</p>
        <p>black</p>
      </div>
    </HomePageComponents>
  );
};

const HomePageComponents = styled.div`
  background-color: var(--card-bg);

  .buttons {
    max-width: 800px;
    margin: 0 auto;
    padding: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 20px;
    color: green;

    .icon-button {
      width: 38px;
      padding: 0;
    }
  }

  .icons {
    padding: 10px;
    display: grid;
    grid-template-columns: repeat(6, auto);
    justify-content: center;
    justify-items: center;
    align-items: center;
    gap: 16px;
  }

  .colors {
    padding: 10px;
    display: grid;
    grid-template-columns: repeat(6, auto);
    justify-content: center;
    justify-items: center;
    align-items: center;
    gap: 16px;

    .color-container {
      width: 64px;
      height: 32px;
      border: 1px solid white;
      border-top: none;
    }
  }
`;

export default HomePage;
