import React from 'react';
import Select from 'react-select';
import styled from 'styled-components';
import { settingSelectStyles } from '../../../styles/reactSelect';
import { CommonSelectItem } from '../../../types/model/commonSelectItem';

const datetimeFormatOptions: CommonSelectItem[] = [
  { value: 'Y-m-d_H:i:s', label: 'Y-m-d H:i:s' },
  { value: 'Y/m/d_H:i:s', label: 'Y/m/d H:i:s' },
  { value: 'm/d/Y_H:i:s', label: 'm/d/Y H:i:s' },
];

interface Props {
  className?: string;
  value: string;
  onChange: (value: string) => void;
}

const DatetimeFormat = ({ className, value, onChange }: Props) => {
  return (
    <DatetimeFormatComponents className={className}>
      <Select<CommonSelectItem>
        className='form-item-select'
        options={datetimeFormatOptions}
        styles={settingSelectStyles}
        value={
          datetimeFormatOptions[datetimeFormatOptions.findIndex(option => option.value === value)]
        }
        onChange={newValue => onChange(newValue?.value ?? '')}
      />
    </DatetimeFormatComponents>
  );
};

const DatetimeFormatComponents = styled.div``;

export default DatetimeFormat;
