import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import { CommonSelectItem } from '../../types/model/commonSelectItem';
import { selectStyles } from '../../styles/reactSelect';
import { User } from '../../types/model/user';
import { baseURL } from '../../lib/api';
import { WhiteBorderButton } from '../common/Buttons';
import { CopySvg, DownloadSvg } from '../../assets/svg';

export interface DownloadPlaylistFormInput {
  format: string;
  limitOutput: CommonSelectItem[];
  downloadURL: string;
}
interface Props {
  className?: string;
  user?: User;
}
const outputFormatOptions: CommonSelectItem[] = [
  { value: 'm3u?output=hls', label: 'M3U Standard - HLS' },
  { value: 'm3u', label: 'M3U Standard - MPEGTS' },
  { value: 'm3u?output=rtmp', label: 'M3U Standard - RTMP' },
  { value: 'm3u_plus?output=hls', label: 'M3U Plus - HLS' },
];

// const limitOutputOptions: CommonSelectItem[] = [
//   { value: 'live', label: 'Live Streams' },
//   { value: 'movie', label: 'Movies' },
//   { value: 'created_live', label: 'Created Channels' },
//   { value: 'radio_streams', label: 'Radio Stations' },
//   { value: 'series', label: 'TV Series' },
// ];

const DownloadPlaylistForm = ({ className, user }: Props) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { control, setValue, getValues } = useForm<DownloadPlaylistFormInput>({
    defaultValues: {
      format: '',
      limitOutput: [],
      downloadURL: '',
    },
  });
  const { limitOutput, format } = getValues();

  useEffect(() => {
    if (format && limitOutput) {
      setValue(
        'downloadURL',
        `${baseURL.replace('localhost', '127.0.0.1')}/playlist/${user?.username ?? ''}/${
          user?.password ?? ''
        }/${format}${
          limitOutput.length > 0
            ? `&key=${limitOutput.map((output: CommonSelectItem) => output.value).join(',')}`
            : ''
        }`,
      );
    }
  }, [limitOutput, setValue, user?.username, user?.password, format]);

  const copyToClipboard = () => {
    inputRef.current?.select();
    document.execCommand('copy');
    inputRef.current?.focus();
  };
  return (
    <DownloadPlaylistFormComponent className={className}>
      <form>
        <Controller
          control={control}
          name='format'
          rules={{ required: false }}
          render={({ field }) => (
            <div className='form-item'>
              <span className='form-item-name'>Format</span>
              <div className='form-item-content'>
                <div style={{ width: '100%' }}>
                  <Select<CommonSelectItem>
                    className='form-item-select'
                    options={outputFormatOptions}
                    styles={selectStyles}
                    value={
                      outputFormatOptions[
                        outputFormatOptions.findIndex(option => option.value === field.value)
                      ]
                    }
                    onChange={newValue => {
                      setValue('format', newValue?.value ?? '');
                    }}
                  />
                </div>
              </div>
            </div>
          )}
        />

        {/* <Controller
          control={control}
          name='limitOutput'
          rules={{ required: true }}
          render={({ field }) => (
            <div className='form-item'>
              <span className='form-item-name'>Limit Output</span>
              <div className='form-item-content'>
                <div style={{ width: '100%' }}>
                  <Multiselect
                    displayValue='label'
                    className='form-item-select'
                    options={limitOutputOptions}
                    selectedValues={field.value}
                    onSelect={field.onChange}
                    onRemove={field.onChange}
                    style={multiSelectStyle}
                    showCheckbox
                    avoidHighlightFirstOption
                  />
                </div>
              </div>
            </div>
          )}
        /> */}
        <Controller
          control={control}
          name='downloadURL'
          rules={{ required: false }}
          render={({ field }) => (
            <div className='form-item'>
              <div className='form-item-content' style={{ width: '100%' }}>
                <input
                  {...field}
                  ref={e => {
                    field.ref(e);
                    inputRef.current = e;
                  }}
                  type='text'
                  className='form-item-input'
                  autoComplete='off'
                />
                <WhiteBorderButton
                  className='white-button'
                  onClick={copyToClipboard}
                >
                  <CopySvg />
                </WhiteBorderButton>
                <WhiteBorderButton
                  className='white-button'
                  onClick={() => {
                    window.open(field.value);
                  }}
                >
                  <DownloadSvg />
                </WhiteBorderButton>
              </div>
            </div>
          )}
        />
      </form>
    </DownloadPlaylistFormComponent>
  );
};

const DownloadPlaylistFormComponent = styled.div`
  .white-button {
    padding: 0 12px;
  }
`;

export default DownloadPlaylistForm;
