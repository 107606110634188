import React from 'react';
import styled from 'styled-components';
import Tab, { TabData } from './Tab';

interface Props {
  className?: string;
  data: TabData[];
  currentTab: number;
  onChangeTab?: (id: number) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formData?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setFormData?: React.Dispatch<React.SetStateAction<any>>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  submitFormData?: (data: any) => void;
  role?: 'add' | 'edit';
}

const FormTab = ({
  className,
  data,
  currentTab,
  onChangeTab,
  formData,
  setFormData,
  submitFormData,
  role = 'add',
}: Props) => {
  return (
    <FormTabComponent className={className}>
      <Tab
        data={data}
        currentTab={currentTab}
        onChangeTab={onChangeTab}
        formData={formData}
        setFormData={setFormData}
        submitFormData={submitFormData}
        className='form-tab'
        isAllowTabClick={true}
        role={role}
        tabTitleBarStyle='form-tab-title-bar'
        tabTitleStyle='form-tab-title'
        tabTitleActiveStyle='form-tab-title-active'
        tabContentStyle='form-tab-content'
      />
    </FormTabComponent>
  );
};

const FormTabComponent = styled.div`
  .form-tab {
    width: 100%;
    margin: 20px 0 0 0;
  }

  .form-tab-title-bar {
    width: 100%;
    border-radius: 8px;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    background-color: var(--dark-bg);
    padding: 1px 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    cursor: pointer;
  }

  .form-tab-title {
    padding: 10px 14px;
    font-size: 16px;
    border-radius: 8px;

    &:hover {
      background: radial-gradient(
          100% 100% at 50.63% 0%,
          rgba(151, 71, 255, 0.16) 0%,
          rgba(151, 71, 255, 0) 100%
        ),
        radial-gradient(
          45.01% 84.62% at 50.63% 100%,
          rgba(151, 71, 255, 0.6) 0%,
          rgba(151, 71, 255, 0) 100%
        ),
        rgba(255, 255, 255, 0.1);
      box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.47), 0px 5px 11px rgba(151, 71, 255, 0.22);
    }

    &:active {
      background: radial-gradient(50.08% 76.92% at 50.63% 0%, #000000 0%, rgba(0, 0, 0, 0) 100%),
        rgba(61, 60, 60, 0.2);
      box-shadow: inset 0px 9px 10px rgba(0, 0, 0, 0.25);
    }
  }

  .form-tab-title-active {
    background: radial-gradient(
      45.01% 84.62% at 50.63% 100%,
      rgba(151, 71, 255, 0.6) 0%,
      rgba(151, 71, 255, 0) 100%
    );
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.07));
  }

  .form-tab-content {
    width: 100%;
    margin: 8px 0;
  }
`;

export default FormTab;
