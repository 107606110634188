import { useEffect } from 'react';
import { useCategories } from './useCategories';
import useRequest from '../../lib/hooks/useRequest';
import { insertCategory } from '../../lib/api';
import { isAxiosError } from 'axios';

export const useInsertCategory = () => {
  const [_insertCategory, , insertCategoryRes, insertCategoryErr] = useRequest(insertCategory);
  const { getAllCategories, completed } = useCategories();
  useEffect(() => {
    if (insertCategoryRes) {
      console.log('insertCategoryRes: ', insertCategoryRes);
      // Todo: go to categories page
      getAllCategories();
    }
  }, [getAllCategories, insertCategoryRes]);

  useEffect(() => {
    if (insertCategoryErr) {
      if (isAxiosError(insertCategoryErr)) {
        console.log('insertCategoryErrText: ', insertCategoryErr.response?.data.error);
        console.log('insertCategoryErrMessage: ', insertCategoryErr.response?.data.message);
      } else {
        console.log('insertCategoryErr: ', insertCategoryErr);
      }
    }
  }, [insertCategoryErr]);
  return { insertCategory: _insertCategory, completed };
};
