import React from 'react';
import styled from 'styled-components';
import { CloudArrowDownSvg } from '../../assets/svg';
import { ChipsButton } from '../common/Buttons';

const DashboardHeader = () => {
  return (
    <DashBoardComponent>
      <div className='chips-button'>
        <ChipsButton
          onClick={() => {
            console.log('clicked');
          }}
          height={32}
          color='#55556f'
        >
          <CloudArrowDownSvg />
          <p>.xlsx</p>
        </ChipsButton>
      </div>
    </DashBoardComponent>
  );
};

const DashBoardComponent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .chips-button {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 8px;
  }
  .report-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 8px;

    p {
      font-weight: 600;
      font-size: 12px;
    }
  }
`;

export default DashboardHeader;
