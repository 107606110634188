import React from 'react';
import ReactModal from 'react-modal';
import { NextArrowSvg, PrevArrowSvg } from '../../assets/svg';
import { ColorableButton, FormButton } from './Buttons';

interface Props {
  className?: string;
  children: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
  hideCancel?: boolean;
  hideOk?: boolean;
  noIconOnCancel?: boolean;
  noIconOnOk?: boolean;
  prevButtonTitle?: string;
  nextButtonTitle?: string;
  handleCancel?: () => void;
  handleOk: (selectedItemId: string) => void;
  selectedItemId?: string;
}

const ModalWarning = ({
  className,
  children,
  isOpen,
  onClose,
  hideCancel = false,
  hideOk = false,
  noIconOnCancel = false,
  noIconOnOk = false,
  prevButtonTitle = 'Cancel',
  nextButtonTitle = 'OK',
  handleCancel,
  handleOk,
  selectedItemId,
}: Props) => {
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onClose}
      className={`modal-warning card ${className}`}
      ariaHideApp={false}
      style={{
        overlay: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          background: 'rgba(0,0,0,0.75)',
          zIndex: 1000,
        },
      }}
    >
      <div className='modal-warning-message'>{children}</div>
      <div className='modal-warning-footer'>
        <FormButton onClick={handleCancel} className={`${hideCancel ? 'hidden-button' : ''}`}>
          {!noIconOnCancel && <PrevArrowSvg />}
          {prevButtonTitle}
        </FormButton>
        <ColorableButton
          bgColor={'#d34848'}
          onClick={() => handleOk(selectedItemId ?? '')}
          className={`${hideOk ? 'hidden-button' : ''}`}
        >
          {nextButtonTitle}
          {!noIconOnOk && <NextArrowSvg />}
        </ColorableButton>
      </div>
    </ReactModal>
  );
};

export default ModalWarning;
