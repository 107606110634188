import React from 'react';
import styled from 'styled-components';
import { StateItem } from '../types/stateData';
import { serverStatsAtom } from '../store/jotai';
import { useAtom } from 'jotai';

interface Props {
  className?: string;
  stateItem: StateItem;
}

const StateItemElement = ({ className, stateItem }: Props) => {
  const [serverStats] = useAtom(serverStatsAtom);

  let value: number;
  let label: string;
  switch (stateItem.id) {
    case 'onlineConnections':
      value = serverStats.total_connections ?? -1;
      label = 'CONNECTIONS';
      break;
    case 'activeLines':
      value = serverStats.connected_users?.length ?? -1;
      label = 'ACTIVE LINES';
      break;
    case 'liveStreams':
      value = serverStats.total_running_streams ?? -1;
      label = 'LIVE STREAMS';
      break;
    case 'downStreams':
      value = (serverStats.total_streams ?? 0) - (serverStats.total_running_streams ?? 0);
      label = 'DOWN STREAMS';
      break;
    case 'networkInput':
      value = serverStats.input_speed ?? -1;
      label = 'NETWORK UP';
      break;
    case 'networkOutput':
      value = serverStats.output_speed ?? -1;
      label = 'NETWORK DOWN';
      break;
    default:
      value = -1;
      label = '';
  }
  return (
    <StateItemElementComponent className={className}>
      <div className='state-container'>
        <stateItem.icon className='state-icon' />
        <p>{value}</p>
      </div>
        <p className='label'>{label}</p>
    </StateItemElementComponent>
  );
};

const StateItemElementComponent = styled.div`
  .state-icon {
    height: 20px;
  }

  .state-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;

    p {
      width: 30px;
      text-align: center;
    }
  }

  .label {
    font-size: 10px;
    color: #4c4c6c;
    text-align: center;
    padding-top: 5px;
  }
`;

export default StateItemElement;
