import React from 'react';
import styled from 'styled-components';
import { Controller, useForm, SubmitHandler } from 'react-hook-form';
import { SubmitFormButton } from '../../common/Buttons';
import SlideToggle from '../../common/SlideToggle';
import Tooltip from './../../common/Tooltip';
import { settingSecurityTooltipMessage } from '../../../config/content/tooltipData';

export interface SettingsSecurityFormInput {
  matchSubnetOfIp: boolean;
  logoutOnIpChange: boolean;
  restrictToSameIp: boolean;
  randomRtmpIp: boolean;
  disallow2ndIp: boolean;
  disallowIfConnections: number;
  XuiDetectDeny: boolean;
  XuiDetectBanLines: boolean;
  blockHostingServers: boolean;
  blockProxiesVpns: boolean;
  floodLimit: number;
  perSeconds: number;
  authFloodLimit: number;
  authFloodSeconds: number;
  authFloodSleep: number;
  floodIpExclusions: string;
  detectMacBruteforce: number;
  detectUsernameBruteforce: number;
  brueforceFrequency: number;
  maximumLoginAttempts: number;
  maxSimultaneousDownloads: number;
}
interface Props {
  className?: string;
  setVisibleTab?: React.Dispatch<React.SetStateAction<number>>;
}

const SettingsSecurityForm = ({ className }: Props) => {
  const { handleSubmit, control } = useForm<SettingsSecurityFormInput>({
    defaultValues: {
      matchSubnetOfIp: false,
      logoutOnIpChange: false,
      restrictToSameIp: false,
      randomRtmpIp: false,
      disallow2ndIp: false,
      disallowIfConnections: 1,
      XuiDetectBanLines: false,
      blockHostingServers: false,
      blockProxiesVpns: false,
      floodLimit: 40,
      perSeconds: 2,
      authFloodLimit: 30,
      authFloodSeconds: 10,
      authFloodSleep: 1,
      floodIpExclusions: '',
      detectMacBruteforce: 10,
      detectUsernameBruteforce: 10,
      brueforceFrequency: 300,
      maximumLoginAttempts: 20,
      maxSimultaneousDownloads: 2,
    },
  });

  const onSubmit: SubmitHandler<SettingsSecurityFormInput> = data => {
    console.log('data: ', data);
  };

  return (
    <SettingsSecurityFormComponent className={className}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='form-sub-title'>IP Security</div>
        <div className='form-item-two-col-card element-card'>
          <Controller
            control={control}
            name='matchSubnetOfIp'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Match Subnet of IP
                  <Tooltip message={settingSecurityTooltipMessage.matchSubnetOfIp} />
                </span>
                <SlideToggle value={field.value} onChange={field.onChange} />
              </div>
            )}
          />
          <Controller
            control={control}
            name='logoutOnIpChange'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Logout On IP Change
                  <Tooltip message={settingSecurityTooltipMessage.logoutOnIpChange} />
                </span>
                <SlideToggle value={field.value} onChange={field.onChange} />
              </div>
            )}
          />
          <Controller
            control={control}
            name='restrictToSameIp'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Restrict to Same IP
                  <Tooltip message={settingSecurityTooltipMessage.restrictToSameIp} />
                </span>
                <SlideToggle value={field.value} onChange={field.onChange} />
              </div>
            )}
          />
          <Controller
            control={control}
            name='randomRtmpIp'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Random RTMP IP
                  <Tooltip message={settingSecurityTooltipMessage.randomRtmpIp} />
                </span>
                <SlideToggle value={field.value} onChange={field.onChange} />
              </div>
            )}
          />
          <Controller
            control={control}
            name='disallow2ndIp'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Disallow 2nd IP
                  <Tooltip message={settingSecurityTooltipMessage.disallow2ndIp} />
                </span>
                <SlideToggle value={field.value} onChange={field.onChange} />
              </div>
            )}
          />
          <Controller
            control={control}
            name='disallowIfConnections'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Disallow if Connections &#60;=
                  <Tooltip message={settingSecurityTooltipMessage.disallowIfConnections} />
                </span>
                <input
                  type='number'
                  className='form-item-two-col-card-item-input'
                  autoComplete='off'
                  placeholder=''
                  {...field}
                />
              </div>
            )}
          />
        </div>

        <div className='form-sub-title'>Restream Prevention</div>
        <div className='form-item-two-col-card element-card'>
          <Controller
            control={control}
            name='XuiDetectDeny'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  XUI Detect-Deny
                  <Tooltip message={settingSecurityTooltipMessage.XuiDetectDeny} />
                </span>
                <SlideToggle value={field.value} onChange={field.onChange} />
              </div>
            )}
          />
          <Controller
            control={control}
            name='XuiDetectBanLines'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  XUI Detect-Ban Lines
                  <Tooltip message={settingSecurityTooltipMessage.XuiDetectBanLines} />
                </span>
                <SlideToggle value={field.value} onChange={field.onChange} />
              </div>
            )}
          />
          <Controller
            control={control}
            name='blockHostingServers'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Block Hosting Servers
                  <Tooltip message={settingSecurityTooltipMessage.blockHostingServers} />
                </span>
                <SlideToggle value={field.value} onChange={field.onChange} />
              </div>
            )}
          />
          <Controller
            control={control}
            name='blockProxiesVpns'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Block Proxies / VPN&#39;s
                  <Tooltip message={settingSecurityTooltipMessage.blockProxiesVpns} />
                </span>
                <SlideToggle value={field.value} onChange={field.onChange} />
              </div>
            )}
          />
        </div>

        <div className='form-sub-title'>Spam Prevention</div>
        <div className='form-item-two-col-card element-card'>
          <Controller
            control={control}
            name='floodLimit'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Flood Limit
                  <Tooltip message={settingSecurityTooltipMessage.floodLimit} />
                </span>
                <input
                  type='number'
                  className='form-item-two-col-card-item-input'
                  autoComplete='off'
                  placeholder=''
                  {...field}
                />
              </div>
            )}
          />
          <Controller
            control={control}
            name='perSeconds'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Per Seconds
                  <Tooltip message={settingSecurityTooltipMessage.perSeconds} />
                </span>
                <input
                  type='number'
                  className='form-item-two-col-card-item-input'
                  autoComplete='off'
                  placeholder=''
                  {...field}
                />
              </div>
            )}
          />
          <Controller
            control={control}
            name='authFloodLimit'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Auth Flood Limit
                  <Tooltip message={settingSecurityTooltipMessage.authFloodLimit} />
                </span>
                <input
                  type='number'
                  className='form-item-two-col-card-item-input'
                  autoComplete='off'
                  placeholder=''
                  {...field}
                />
              </div>
            )}
          />
          <Controller
            control={control}
            name='authFloodSeconds'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Auth Flood Seconds
                  <Tooltip message={settingSecurityTooltipMessage.authFloodSeconds} />
                </span>
                <input
                  type='number'
                  className='form-item-two-col-card-item-input'
                  autoComplete='off'
                  placeholder=''
                  {...field}
                />
              </div>
            )}
          />
          <Controller
            control={control}
            name='authFloodSleep'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Auth Flood Sleep
                  <Tooltip message={settingSecurityTooltipMessage.authFloodSleep} />
                </span>
                <input
                  type='number'
                  className='form-item-two-col-card-item-input'
                  autoComplete='off'
                  placeholder=''
                  {...field}
                />
              </div>
            )}
          />
          <Controller
            control={control}
            name='floodIpExclusions'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Flood IP Exclusions
                  <Tooltip message={settingSecurityTooltipMessage.floodIpExclusions} />
                </span>
                <input
                  type='text'
                  className='form-item-two-col-card-item-input'
                  autoComplete='off'
                  placeholder=''
                  {...field}
                />
              </div>
            )}
          />
          <Controller
            control={control}
            name='detectMacBruteforce'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Detect MAC Bruteforce
                  <Tooltip message={settingSecurityTooltipMessage.detectMacBruteforce} />
                </span>
                <input
                  type='number'
                  className='form-item-two-col-card-item-input'
                  autoComplete='off'
                  placeholder=''
                  {...field}
                />
              </div>
            )}
          />
          <Controller
            control={control}
            name='detectUsernameBruteforce'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Detect Username Bruteforce
                  <Tooltip message={settingSecurityTooltipMessage.detectUsernameBruteforce} />
                </span>
                <input
                  type='number'
                  className='form-item-two-col-card-item-input'
                  autoComplete='off'
                  placeholder=''
                  {...field}
                />
              </div>
            )}
          />
          <Controller
            control={control}
            name='brueforceFrequency'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Brueforce Frequency
                  <Tooltip message={settingSecurityTooltipMessage.brueforceFrequency} />
                </span>
                <input
                  type='number'
                  className='form-item-two-col-card-item-input'
                  autoComplete='off'
                  placeholder=''
                  {...field}
                />
              </div>
            )}
          />
          <Controller
            control={control}
            name='maximumLoginAttempts'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Maximum Login Attempts
                  <Tooltip message={settingSecurityTooltipMessage.maximumLoginAttempts} />
                </span>
                <input
                  type='number'
                  className='form-item-two-col-card-item-input'
                  autoComplete='off'
                  placeholder=''
                  {...field}
                />
              </div>
            )}
          />
          <Controller
            control={control}
            name='maxSimultaneousDownloads'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Max Simultaneous Downloads
                  <Tooltip message={settingSecurityTooltipMessage.maxSimultaneousDownloads} />
                </span>
                <input
                  type='number'
                  className='form-item-two-col-card-item-input'
                  autoComplete='off'
                  placeholder=''
                  {...field}
                />
              </div>
            )}
          />
        </div>

        <SubmitFormButton className='full-width'>Save</SubmitFormButton>
      </form>
    </SettingsSecurityFormComponent>
  );
};

const SettingsSecurityFormComponent = styled.div``;

export default SettingsSecurityForm;
