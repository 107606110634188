import { createGlobalStyle } from 'styled-components';

const VariablesStyles = createGlobalStyle`
  :root{
    --dark-bg: #13132C;
    --card-bg: #2B2B4B;
    --element-card-bg: #363659;
    --placeholder-dark: #4C4C6C;

    --combobox-on: #7345ea;
    --combobox-off: #4C4C6C;

    --table-odd-row: #38385f;

    --primary-button-bg: #7345ea;

    --light-grey: #55556F;
    --middle-grey: #232349;
    --dark-grey: #1f1f41;

    --text-secondary: #6d6d87;
    --text-form-content: #d6d6ff;
    --text-button: #d9d9d9;

    --online-state: #a8ffc5;

    --online-state-1: #26ca88;
    --offline-state-1: #dc6b00;

    --online-state-2: #304ad2;

    --white : white;
    --black: black;
  }
`;

export default VariablesStyles;
