import React from 'react';
import Popup from 'reactjs-popup';
import { QuestionMarkSvg } from '../../assets/svg';

interface Props {
  message: string;
}

const Tooltip = ({ message }: Props) => {
  return (
    <Popup
      trigger={<QuestionMarkSvg style={{ flexShrink: '0' }} />}
      on='hover'
      position='top center'
      closeOnDocumentClick
    >
      <div style={{ whiteSpace: 'pre-line' }}>{message}</div>
    </Popup>
  );
};

export default Tooltip;
