import React from 'react';
import Select from 'react-select';
import styled from 'styled-components';
import { settingSelectStyles } from '../../../styles/reactSelect';
import { CommonSelectItem } from '../../../types/model/commonSelectItem';

const appendExtraDataOptions: CommonSelectItem[] = [
  { value: 'defaultFormat', label: 'Default Format' },
  { value: 'extendedTitle', label: 'Extended Title' },
  { value: 'extendedDescription', label: 'Extended Description' },
];

interface Props {
  className?: string;
  value: string;
  onChange: (value: string) => void;
}

const AppendExtraData = ({ className, value, onChange }: Props) => {
  return (
    <AppendExtraDataComponents className={className}>
      <Select<CommonSelectItem>
        className='form-item-select'
        options={appendExtraDataOptions}
        styles={settingSelectStyles}
        value={
          appendExtraDataOptions[appendExtraDataOptions.findIndex(option => option.value === value)]
        }
        onChange={newValue => onChange(newValue?.value ?? '')}
      />
    </AppendExtraDataComponents>
  );
};

const AppendExtraDataComponents = styled.div``;

export default AppendExtraData;
