import { useEffect } from 'react';
import { stopStream } from '../../lib/api';
import useRequest from '../../lib/hooks/useRequest';
import { useStreams } from './useStreams';
import { isAxiosError } from 'axios';

export const useStopStream = () => {
  const { getAllStreams } = useStreams(false);
  const [_stopStream, , _stopStreamRes, _stopStreamErr] = useRequest(stopStream);

  useEffect(() => {
    if (_stopStreamRes) {
      console.log('_stopStreamRes: ', _stopStreamRes);
      getAllStreams();
    }
  }, [getAllStreams, _stopStreamRes]);
  useEffect(() => {
    if (_stopStreamErr) {
      if (isAxiosError(_stopStreamErr)) {
        console.log('_stopStreamErrText: ', _stopStreamErr.response?.data.error);
        console.log('_stopStreamErrMessage: ', _stopStreamErr.response?.data.message);
      } else {
        console.log('_stopStreamErr: ', _stopStreamErr);
      }
    }
  }, [_stopStreamErr]);
  return { stopStream: _stopStream };
};
