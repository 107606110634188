import { createGlobalStyle } from 'styled-components';

const CssClassesStyles = createGlobalStyle`
  .font-size-16 {
    font-size: 16px !important;
  }

  .font-size-20 {
    font-size: 20px !important;
  }
  
  .full-width {
    width: 100% !important;
  }

  .half-width {
    width: 50% !important;
  }

  .grid-full-row {
    grid-column: 1 / -1;
  }
`;

export default CssClassesStyles;
