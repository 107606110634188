import { useEffect } from 'react';
import { deleteUser } from '../../lib/api';
import useRequest from '../../lib/hooks/useRequest';
import { useLines } from './useLines';
import { isAxiosError } from 'axios';

export const useDeleteLine = () => {
  const { getAllUsers } = useLines(false);
  const [_deleteUser, , _deleteUserRes, _deleteUserErr] = useRequest(deleteUser);

  useEffect(() => {
    if (_deleteUserRes) {
      // Refetch All users
      getAllUsers();
    }
  }, [_deleteUserRes, getAllUsers]);

  useEffect(() => {
    if (_deleteUserErr) {
      if (isAxiosError(_deleteUserErr)) {
        console.log('_deleteUserErrText: ', _deleteUserErr.response?.data.error);
        console.log('_deleteUserErrMessage: ', _deleteUserErr.response?.data.message);
      } else {
        console.log('_deleteUserErr: ', _deleteUserErr);
      }
    }
  }, [_deleteUserErr]);
  return { deleteLine: _deleteUser };
};
