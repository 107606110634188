import { useEffect } from 'react';
import useRequest from '../../lib/hooks/useRequest';
import { insertBouquet } from '../../lib/api';
import { isAxiosError } from 'axios';
import { useBouquets } from './useBouquets';

export const useInsertBouquet = () => {
  const [_insertBouquet, , insertBouquetRes, insertBouquetErr] = useRequest(insertBouquet);
  const { getAllBouquets, completed } = useBouquets();
  useEffect(() => {
    if (insertBouquetRes) {
      console.log('insertBouquetRes: ', insertBouquetRes);
      // Todo: go to categories page
      getAllBouquets();
    }
  }, [getAllBouquets, insertBouquetRes]);

  useEffect(() => {
    if (insertBouquetErr) {
      if (isAxiosError(insertBouquetErr)) {
        console.log('insertBouquetErrText: ', insertBouquetErr.response?.data.error);
        console.log('insertBouquetErrMessage: ', insertBouquetErr.response?.data.message);
      } else {
        console.log('insertBouquetErr: ', insertBouquetErr);
      }
    }
  }, [insertBouquetErr]);
  return { insertBouquet: _insertBouquet, completed };
};