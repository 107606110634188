import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useImportStreams } from '../../hooks/stream/useImportStreams';
import { ImportStreamFormData } from '../../types/model/stream';
import FormTab from '../common/FormTab';
import FormTitle from '../common/FormTitle';
import { TabData } from '../common/Tab';
import ImportStreamDetailsForm from './details/ImportStreamDetailsForm';
import ImportStreamsServersForm from './servers/ImportStreamsServersForm';

const tabData: TabData[] = [
  {
    id: 1,
    tabTitle: 'Details',
    tabComponent: ImportStreamDetailsForm,
  },
  {
    id: 2,
    tabTitle: 'StreamStart',
    tabComponent: ImportStreamsServersForm,
  },
];

interface Props {
  className?: string;
}

const ImportStreamsForm = ({ className }: Props) => {
  const navigate = useNavigate();
  const [streamData, setStreamData] = useState<ImportStreamFormData>();
  const { importStreams, completed } = useImportStreams(); //Import

  useEffect(() => {
    if (completed) navigate('/streams/manage-streams');
  }, [completed, navigate]);

  const submitStreamData = async (data: ImportStreamFormData) => {
    console.log('data: ', data);
    const formData = new FormData();
    formData.append('file', data.file ?? '');
    formData.append('categories', data.categories?.toString() ?? '');
    formData.append('bouquets', data.bouquets?.toString() ?? '');
    formData.append('notes', data.notes ?? '');
    formData.append('isStart', data.isStart.toString());

    importStreams(formData);
  };
  return (
    <ImportStreamsFormComponent className={className}>
      <FormTitle>Import Streams</FormTitle>
      <FormTab
        data={tabData}
        formData={streamData}
        currentTab={tabData[0].id}
        setFormData={setStreamData}
        submitFormData={submitStreamData}
      />
    </ImportStreamsFormComponent>
  );
};

const ImportStreamsFormComponent = styled.div`
  width: 700px;
  max-width: 100%;
  margin: 2px auto;
`;

export default ImportStreamsForm;
