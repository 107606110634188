import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { NextArrowSvg, PrevArrowSvg } from '../../assets/svg';

interface Props {
  totalRecords: number;
  pageSize: number;
  onPageChange?: (page: number) => void;
}
const Paginator = ({ totalRecords, pageSize, onPageChange }: Props) => {
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(totalRecords / pageSize);
  const from = (currentPage - 1) * pageSize + 1;
  const to = Math.min(currentPage * pageSize, totalRecords);
  useEffect(() => {
    onPageChange && onPageChange(currentPage);
  }, [currentPage, onPageChange]);
  const onPrevClick = () => {
    setCurrentPage(Math.max(currentPage - 1, 1));
  };
  const onNextClick = () => {
    setCurrentPage(Math.min(currentPage + 1, totalPages));
  };
  return (
    <PaginatorComponents>
      <div className='paginator-notice'>
        Showing {from} to {to} of {totalRecords} entries
      </div>
      <div className='paginator-controller'>
        <button className='paginator-button' onClick={onPrevClick}>
          <PrevArrowSvg />
        </button>
        <div className='paginator-label'>
          {currentPage} of {totalPages}
        </div>
        <button className='paginator-button' onClick={onNextClick}>
          <NextArrowSvg />
        </button>
      </div>
    </PaginatorComponents>
  );
};

const PaginatorComponents = styled.div`
  width: 100%;
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;

  .paginator-notice {
    font-size: 16px;
    color: var(--text-form-content);
  }

  .paginator-controller {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 6px;

    .paginator-button {
      flex-shrink: 0;
      width: 24px;
      height: 28px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      background-color: var(--table-odd-row);
      border-radius: 8px;

      &:hover {
        opacity: 0.8;
      }

      &:focus {
        border: 1px solid white;
      }

      &:active {
        box-shadow: 0px 2px 13px 0px #1e1e3f;
        opacity: 0.7;
      }
    }

    .paginator-label {
      flex-shrink: 0;
      height: 28px;
      padding: 0 22px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: var(--table-odd-row);
      border-radius: 8px;
      font-size: 16px;
    }
  }
`;

export default Paginator;
