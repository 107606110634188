import React, { useState } from 'react';
import styled from 'styled-components';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import { EditSvg, ListSvg, XMarkCircleSvg } from '../../assets/svg';
import { IconSimpleButton } from './Buttons';
import ModalWarning from './ModalWarning';

export interface DndData {
  id: string;
  label: string;
  description: string;
}

interface Props {
  value: DndData[];
  onChange: (value: DndData[]) => void;
  deleteMessage: string;
  onDelete?: (selectedItemId: string) => void;
  onEdit?: (selectedItem: DndData) => void;
}

const DragAndDropCard = ({ value, onChange, deleteMessage, onDelete, onEdit }: Props) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState<string>();

  const openDeleteModal = (item: DndData) => {
    setShowDeleteModal(true);
    setSelectedItemId(item.id);
  };

  const handleDeleteItem = (selectedItemId: string) => {
    onDelete && onDelete(selectedItemId);
    setShowDeleteModal(false);
  };

  const handleDragEnd = (result: DropResult) => {
    const { destination, source } = result;
    if (!destination) return;
    if (destination.index === source.index) return;

    const workValue = value.slice();
    const [deletedItem] = workValue.splice(source.index, 1);
    workValue.splice(destination.index, 0, deletedItem);
    onChange(workValue);
  };

  return (
    <DragAndDropCardComponents>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId='ROOT'>
          {provided => (
            <div className='dnd-container' {...provided.droppableProps} ref={provided.innerRef}>
              {value.map((item, index) => (
                <Draggable draggableId={item.id} key={item.id} index={index}>
                  {provided => (
                    <div className='dnd-list' {...provided.draggableProps} ref={provided.innerRef}>
                      <div className='dnd-list-left'>
                        <div className='dnd-list-handler' {...provided.dragHandleProps}>
                          <ListSvg />
                        </div>
                        {item.label}
                      </div>
                      <div className='dnd-list-right'>
                        <IconSimpleButton size={28} onClick={() => onEdit && onEdit(item)}>
                          <EditSvg style={{ height: '18px', width: '18px' }} />
                        </IconSimpleButton>
                        <IconSimpleButton size={28} onClick={() => openDeleteModal(item)}>
                          <XMarkCircleSvg />
                        </IconSimpleButton>
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <ModalWarning
        isOpen={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        noIconOnOk
        nextButtonTitle='Delete'
        handleCancel={() => setShowDeleteModal(false)}
        handleOk={selectedItemId => handleDeleteItem(selectedItemId)}
        selectedItemId={selectedItemId}
      >
        {deleteMessage}
      </ModalWarning>
    </DragAndDropCardComponents>
  );
};

const DragAndDropCardComponents = styled.div`
  .dnd-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1px;

    .dnd-list {
      width: 100%;
      height: 64px;
      padding: 0 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: var(--element-card-bg);

      .dnd-list-left,
      .dnd-list-right {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 22px;

        .dnd-list-handler {
          width: 36px;
          height: 32px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: var(--dark-bg);
          border-radius: 8px;
        }
      }
    }
  }
`;

export default DragAndDropCard;
