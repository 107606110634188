import React from 'react';
import styled from 'styled-components';

interface Props {
  className?: string;
  value: boolean;
  onChange: (value: boolean) => void;
}

const SlideToggle = ({ className, value, onChange }: Props) => {
  const handleClick = () => {
    onChange(!value);
  };

  return (
    <SlideToggleComponent type='button' onClick={handleClick} className={className} $state={value}>
      <div className='circle'></div>
    </SlideToggleComponent>
  );
};

const SlideToggleComponent = styled.button<{ $state?: boolean }>`
  position: relative;
  flex-shrink: 0;
  min-width: 50px;
  height: 18px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 9px;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  background-color: ${props => (props.$state ? '#545488' : '#13132C;')};
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }

  &:focus {
    outline: white solid 1px;
    outline-offset: 0px;
  }

  &:active {
    opacity: 0.7;
  }

  .circle {
    position: absolute;
    left: ${props => (props.$state ? '' : '0')};
    right: ${props => (props.$state ? '0' : '')};
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background: #d6d6ff;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
`;

export default SlideToggle;
