import { useEffect } from 'react';
import { getAllUsers } from '../../lib/api';
import useRequest from '../../lib/hooks/useRequest';
import { useAtom } from 'jotai';
import { usersAtom } from '../../store/jotai';
import { User } from '../../types/model/user';
import { isAxiosError } from 'axios';

export const useLines = (prefetch = true) => {
  const [, setUsers] = useAtom(usersAtom);
  const [_getAllUsers, , _getAllUsersRes, _getAllUsersErr] = useRequest(getAllUsers);
  useEffect(() => {
    if (_getAllUsersRes && setUsers) {
      setUsers(_getAllUsersRes.Records as User[]);
    }
  }, [_getAllUsersRes, setUsers]);

  useEffect(() => {
    if (prefetch) _getAllUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (_getAllUsersErr) {
      if (isAxiosError(_getAllUsersErr)) {
        console.log('_getAllUsersErrText: ', _getAllUsersErr.response?.data.error);
        console.log('_getAllUsersErrMessage: ', _getAllUsersErr.response?.data.message);
      } else {
        console.log('_getAllUsersErr: ', _getAllUsersErr);
      }
    }
  }, [_getAllUsersErr]);
  return { getAllUsers: _getAllUsers };
};
