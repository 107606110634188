import { useEffect, useState } from 'react';
import { getUsersByStream } from '../../lib/api';
import useRequest from '../../lib/hooks/useRequest';

export const useGetUsersByStream = (streamId: string, numClients: number) => {
  const [clients, setClients] = useState(numClients);
  const [_getUsersByStream, , _getUsersByStreamRes, _getUsersByStreamErr] =
    useRequest(getUsersByStream);

  useEffect(() => {
    if (_getUsersByStreamRes) {
      setClients(_getUsersByStreamRes.connected_users?.length ?? 0);
    }
  }, [_getUsersByStreamRes]);
  useEffect(() => {
    if (_getUsersByStreamErr) {
      console.log('_getUsersByStreamErr: ', _getUsersByStreamErr);
      setClients(0);
    }
  }, [_getUsersByStreamErr]);
  useEffect(() => {
    const intervalId = setInterval(() => {
      _getUsersByStream(streamId);
    }, 10000);
    return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { clients };
};
