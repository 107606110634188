import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Select from 'react-select';
import { CommonSelectItem } from '../../types/model/commonSelectItem';
import FormTitle from '../common/FormTitle';
import ManageTable from '../common/ManageTable';
import { CellProps, Column } from 'react-table';
import { headerSelectStyles } from '../../styles/reactSelect';
import { CopySvg, EditSvg, ListUlSvg, XMarkCircleSvg } from '../../assets/svg';
import { IconSimpleButton } from '../common/Buttons';
import ModalWarning from '../common/ModalWarning';
import Paginator from '../common/Paginator';
import { useAtom } from 'jotai';
import { bouquetsAtom } from '../../store/jotai';
import { Bouquet } from '../../types/model/bouquet';
import EditBouquetModal from './modal/EditBouquetModal';
import { useDeleteBouquet } from '../../hooks/bouquet/useDeleteBouquet';

const showOptions: CommonSelectItem[] = [
  { value: '10', label: '10' },
  { value: '25', label: '25' },
  { value: '50', label: '50' },
  { value: '250', label: '250' },
  { value: '500', label: '500' },
  { value: '1000', label: '1000' },
];

interface Props {
  className?: string;
}

const ManageBouquetForm = ({ className }: Props) => {
  const [selectedBouquet, setSelectedBouquet] = useState<Bouquet>();
  const { deleteBouquet, deleteBouquetRes } = useDeleteBouquet();
  const [bouquets] = useAtom(bouquetsAtom);
  const [showEditMoal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showNumber, setShowNumber] = useState<CommonSelectItem | null>(showOptions[0]);

  const [data, setData] = useState<Bouquet[]>(bouquets.slice(0, Number(showNumber?.value ?? 10)));
  const onPageChange = (page: number) => {
    setData(
      bouquets.slice(
        (page - 1) * Number(showNumber?.value ?? 10),
        page * Number(showNumber?.value ?? 10),
      ),
    );
  };
  useEffect(() => {
    if (deleteBouquetRes) {
      console.log('deleteBoquetRes: ', deleteBouquetRes);
    }
  }, [deleteBouquetRes]);

  const onConfirmDeleteBouquet = () => {
    setShowDeleteModal(false);
    deleteBouquet(selectedBouquet?.id ?? '');
  };

  const onCloseEditModal = () => {
    setShowEditModal(false);
  };
  const renderNumber = (cell: CellProps<Bouquet, number | undefined>): JSX.Element => (
    <div
      style={{
        margin: '0 auto',
        height: '28px',
        width: 'fit-content',
        padding: '0 9px',
        borderRadius: '8px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: cell.value ? '#7bffa5' : 'white',
        fontSize: '16px',
        color: '#13132c',
      }}
    >
      {cell.value ?? 0}
    </div>
  );

  const renderActions = (cell: CellProps<Bouquet>): JSX.Element => {
    const bouquet = cell.row.original;
    return (
      <div className='bouquet-action-container'>
        <IconSimpleButton size={28} onClick={() => console.log('clicked')}>
          <ListUlSvg />
        </IconSimpleButton>
        <IconSimpleButton size={28} onClick={() => openEditModal(bouquet)}>
          <EditSvg style={{ height: '18px', width: '18px' }} />
        </IconSimpleButton>
        <IconSimpleButton size={28} onClick={() => console.log('clicked')}>
          <CopySvg />
        </IconSimpleButton>
        <IconSimpleButton size={28} onClick={() => openDeleteModal(bouquet)}>
          <XMarkCircleSvg />
        </IconSimpleButton>
      </div>
    );
  };
  const bouquetHeader: Column<Bouquet>[] = [
    {
      Header: 'ID',
      accessor: 'id',
    },
    {
      Header: 'Bouquet Name',
      accessor: 'name',
    },
    {
      Header: 'Streams',
      accessor: 'streams',
      Cell: renderNumber,
    },
    {
      Header: 'Movies',
      accessor: 'movies',
      Cell: renderNumber,
    },
    {
      Header: 'Series',
      accessor: 'series',
      Cell: renderNumber,
    },
    {
      Header: 'Stations',
      accessor: 'stations',
      Cell: renderNumber,
    },
    {
      Header: 'Actions',
      Cell: renderActions,
    },
  ];

  const deleteMessage = 'Are you sure you want to delete this bouquet?';

  const columns: Column<Bouquet>[] = bouquetHeader;

  const openDeleteModal = (bouquet: Bouquet) => {
    setShowDeleteModal(true);
    setSelectedBouquet(bouquet);
  };

  const openEditModal = (bouquet: Bouquet) => {
    setShowEditModal(true);
    setSelectedBouquet(bouquet);
  };

  return (
    <ManageBouquetFormComponents className={className}>
      <FormTitle>Manage Bouquets</FormTitle>
      <div className='form-header'>
        <div className='filter-container'>
          <input
            type='search'
            className='form-header-input'
            autoComplete='on'
            placeholder='Search Streams...'
          />
        </div>
        <div className='show-container'>
          <span className='form-header-name'>Show</span>
          <Select<CommonSelectItem>
            className='form-header-select'
            options={showOptions}
            styles={headerSelectStyles}
            value={showNumber}
            onChange={value => {
              setShowNumber(value);
            }}
          />
        </div>
      </div>
      <div className='table-container'>
        <ManageTable<Bouquet> columns={columns} data={data} />
      </div>
      <Paginator
        totalRecords={bouquets.length}
        pageSize={Number(showNumber?.value ?? 10)}
        onPageChange={onPageChange}
      />

      <ModalWarning
        isOpen={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        noIconOnOk
        nextButtonTitle='Delete'
        handleCancel={() => setShowDeleteModal(false)}
        handleOk={onConfirmDeleteBouquet}
      >
        {deleteMessage}
      </ModalWarning>
      {selectedBouquet && (
        <EditBouquetModal
          bouquet={selectedBouquet}
          isOpen={showEditMoal}
          onClose={onCloseEditModal}
        />
      )}
    </ManageBouquetFormComponents>
  );
};

const ManageBouquetFormComponents = styled.div`
  width: 100%;
  max-width: 2500px;
  margin: 30px auto 0;

  .form-header {
    margin: 40px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 25px;

    .filter-container {
      flex: 1 1 auto;
      max-width: 1000px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 4px;

      .form-header-input {
        flex-grow: 1;
        max-width: 280px;
        min-width: 200px;
        padding: 11px 12px;
        border-radius: 19px;
        background-color: var(--dark-bg);
        font-size: 14px;
        color: var(--text-form-content);
      }

      .form-header-select {
        flex-grow: 1;
        max-width: 280px;
        font-size: 14px;
        color: var(--text-form-content);

        &:nth-child(-n + 4) {
          min-width: 220px;
        }

        &:nth-child(n + 5) {
          min-width: 170px;
        }
      }
    }

    .show-container {
      display: flex;
      align-items: center;
      gap: 16px;

      .form-header-select {
        flex-shrink: 0;
        width: 100px;
        font-size: 14px;
        color: var(--text-form-content);
      }

      .form-header-name {
        font-size: 14px;
        color: var(--text-form-content);
      }
    }
  }

  .table-container {
    width: 100%;
    overflow: auto;
  }

  .bouquet-action-container {
    height: 100%;
    width: 100%;
    max-width: 400px;
    background-color: var(--dark-bg);
    border-bottom: 1px solid var(--placeholder-dark);
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 10px;
  }
`;

export default ManageBouquetForm;
