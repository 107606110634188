import React from 'react';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';
import SideBar from './SideBar';
import TopBar from './TopBar';

const MainLayout = () => {
  return (
    <MainLayoutComponent>
      <TopBar />
      <div className='main-container'>
        <SideBar />
        <div className='main-content'>
          <Outlet />
        </div>
      </div>
    </MainLayoutComponent>
  );
};

const MainLayoutComponent = styled.div`
  .main-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 96px 14px 55px 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 24px;
  }
  .main-content {
    flex: 1 1 auto;
    overflow-y: auto;
    scrollbar-gutter: stable;
  }
`;

export default MainLayout;
