import React from 'react';
import { Column, useSortBy, useTable } from 'react-table';
import styled from 'styled-components';
import { SortAscSvg, SortDescSvg, SortSvg } from '../../assets/svg';

export interface Props<T extends object> {
  columns: Column<T>[];
  data: T[];
}

const ManageTable = <T extends object>({ columns, data }: Props<T>) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    { columns, data },
    useSortBy,
  );
  return (
    <ManageTableComponents>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr
              {...headerGroup.getHeaderGroupProps()}
              key={headerGroup.getHeaderGroupProps()['key']}
            >
              {headerGroup.headers.map(column => {
                return (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    key={column.getHeaderProps()['key']}
                  >
                    <div className='th-container'>
                      {column.render('Header')}
                      {column.id !== 'actions' &&
                        (column.isSorted ? (
                          column.isSortedDesc ? (
                            <SortDescSvg />
                          ) : (
                            <SortAscSvg />
                          )
                        ) : (
                          <SortSvg />
                        ))}
                    </div>
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} key={row.getRowProps()['key']}>
                {row.cells.map(cell => (
                  <td {...cell.getCellProps()} key={cell.getCellProps()['key']}>
                    {cell.render('Cell')}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </ManageTableComponents>
  );
};

const ManageTableComponents = styled.div`
  table {
    margin: 12px 0;
    width: 100%;
    min-width: 900px;
    text-align: center;
    border-collapse: collapse;

    thead {
      background: var(--card-bg);

      tr {
        width: 100%;
        border-bottom: 1px solid var(--element-card-bg);
        box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 8px;

        th {
          .th-container {
            padding: 7px 0;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 4px;
            font-size: 18px;
          }
        }
      }
    }

    tbody {
      &::before {
        content: '';
        display: block;
        height: 16px;
      }

      tr {
        background: var(--card-bg);

        &:nth-of-type(odd) {
          background: var(--table-odd-row);
        }

        td {
          height: 64px;
          font-size: 13px;
        }
      }
    }
  }
`;

export default ManageTable;
