import React from 'react';
import styled from 'styled-components';
import { userPhotoImage } from '../../assets/images';
import { DownArrowSvg } from '../../assets/svg';

interface Props {
  className: string;
}

const UserInfo = ({ className }: Props) => {
  return (
    <UserInfoComponent className={className}>
      <div className='user-info'>
        <img src={userPhotoImage} alt='User Photo' />
        <div className='user-info-content'>
          <div className='user-role'>
            <p>Super Admin</p>
            <DownArrowSvg />
          </div>
          <div className='date'>
            <div className='online-info'></div>
            <p>2023.12.10</p>
          </div>
        </div>
      </div>
      <div className='version-info'>TurboFlow Dashboard V1.0</div>
    </UserInfoComponent>
  );
};

const UserInfoComponent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;

  .user-info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 8px;

    img {
      width: 45px;
      height: 45px;
      border-radius: 50%;
      border: 2px solid #1e1e3f;
    }

    .user-info-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 8px;

      .user-role {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 8px;

        p {
          font-size: 16px;
        }
      }

      .date {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: 5px;

        .online-info {
          width: 7px;
          height: 7px;
          border-radius: 50%;
          background-color: var(--online-state);
        }

        p {
          font-size: 10px;
        }
      }
    }
  }

  .version-info {
    font-size: 11px;
    color: var(--text-secondary);
  }
`;

export default UserInfo;
