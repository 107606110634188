import { useEffect, useMemo } from 'react';
import { updateStream } from '../../lib/api';
import useRequest from '../../lib/hooks/useRequest';
import { useStreams } from './useStreams';
import { isAxiosError } from 'axios';
import { useCategories } from '../category/useCategories';
import { useBouquets } from '../bouquet/useBouquets';

export const useEditStream = () => {
  const { getAllCategories, getAllCategoriesRes } = useCategories();
  const { getAllBouquets, getAllBouquetsRes } = useBouquets();
  const completed = useMemo(
    () => (getAllCategoriesRes && getAllBouquetsRes) as boolean,
    [getAllCategoriesRes, getAllBouquetsRes],
  );
  const { getAllStreams } = useStreams(false);
  const [_updateStream, , _updateStreamRes, _updateStreamErr] = useRequest(updateStream);

  useEffect(() => {
    if (_updateStreamRes && getAllStreams) {
      // TODO check data object
      getAllStreams();
      getAllCategories();
      getAllBouquets();
    }
  }, [_updateStreamRes, getAllBouquets, getAllCategories, getAllStreams]);

  useEffect(() => {
    if (_updateStreamErr) {
      if (isAxiosError(_updateStreamErr)) {
        console.log('updateStreamErrText: ', _updateStreamErr.response?.data.error);
        console.log('updateStreamErrMessage: ', _updateStreamErr.response?.data.message);
      } else {
        console.log('updateStreamErr: ', _updateStreamErr);
      }
    }
  }, [_updateStreamErr]);

  return { editStream: _updateStream, completed };
};
