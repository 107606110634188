import { useAtom } from 'jotai';
import React, { useEffect } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import TextareaAutosize from 'react-textarea-autosize';
import styled from 'styled-components';
import { bouquetsAtom, categoriesAtom } from '../../../store/jotai';
import FormFooter from '../../common/FormFooter';
import Bouquets, { getBouquetsOptions } from './Bouquets';
import Categories, { getCategoriesOptions } from './Categories';
import { StreamDetailsFormInput as ImportStreamDetailsFormInput } from './StreamDetailsForm';
import { ImportStreamFormData } from '../../../types/model/stream';

interface Props {
  className?: string;
  setVisibleTab?: React.Dispatch<React.SetStateAction<number>>;
  formData?: ImportStreamFormData;
  setFormData?: React.Dispatch<React.SetStateAction<ImportStreamFormData>>;
}

const ImportStreamDetailsForm = ({ className, setVisibleTab, formData, setFormData }: Props) => {
  const [categories] = useAtom(categoriesAtom);
  const [bouquets] = useAtom(bouquetsAtom);
  const { handleSubmit, control, getValues } = useForm<ImportStreamDetailsFormInput>({
    defaultValues: {
      file: formData?.file ?? undefined,
      categories: formData?.categories ? getCategoriesOptions(categories, formData.categories) : [],
      bouquets: formData?.bouquets ? getBouquetsOptions(bouquets, formData.bouquets) : [],
      notes: formData?.notes ?? '',
    },
  });

  // If setFormData before unmount
  useEffect(() => {
    return () => {
      const data = getValues();
      if (setFormData) {
        setFormData(currentLine => ({
          ...currentLine,
          file: data.file,
          notes: data.notes,
          categories: data.categories.map(category => category.id),
          bouquets: data.bouquets.map(bouquet => bouquet.id),
        }));
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit: SubmitHandler<ImportStreamDetailsFormInput> = (
    data: ImportStreamDetailsFormInput,
  ) => {
    if (setVisibleTab) setVisibleTab(2);
    console.log(data);
  };

  return (
    <ImportStreamDetailsFormComponent className={className}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={control}
          name='file'
          rules={{ required: true }}
          render={({ field: { onChange, ...field } }) => (
            <div className='form-item'>
              <span className='form-item-name'>M3U</span>
              <div className='form-item-content'>
                <input
                  {...field}
                  type='file'
                  className='choose-file'
                  value={undefined}
                  onChange={event => {
                    onChange(event.target.files ? event.target.files['0'] : null);
                  }}
                />
              </div>
            </div>
          )}
        />
        <Controller
          control={control}
          name='categories'
          rules={{ required: true }}
          render={({ field }) => (
            <div className='form-item'>
              <span className='form-item-name'>Categories</span>
              <Categories className='form-item-content one-container-in-flex' field={field} />
            </div>
          )}
        />
        <Controller
          control={control}
          name='bouquets'
          rules={{ required: true }}
          render={({ field }) => (
            <div className='form-item'>
              <span className='form-item-name'>Bouquets</span>
              <Bouquets className='form-item-content one-container-in-flex' field={field} />
            </div>
          )}
        />
        <Controller
          control={control}
          name='notes'
          render={({ field }) => (
            <div className='form-item'>
              <span className='form-item-name'>Notes</span>
              <div className='form-item-content'>
                <TextareaAutosize
                  className='form-item-input'
                  minRows={2}
                  autoComplete='off'
                  placeholder='_'
                  {...field}
                />
              </div>
            </div>
          )}
        />
        <FormFooter hidePrev />
      </form>
    </ImportStreamDetailsFormComponent>
  );
};

const ImportStreamDetailsFormComponent = styled.div`
  .choose-file {
    width: 100%;
    padding: 0.5rem;
    border-radius: 0.25rem;
    font-size: 20px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    &:focus {
      outline: none;
      border-color: #80bdff;
      box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
    }
  }
  .one-container-in-flex {
    & > div {
      width: 100%;
    }
  }
`;

export default ImportStreamDetailsForm;
