import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Controller, useForm, SubmitHandler } from 'react-hook-form';
import FormFooter from '../common/FormFooter';
import { Bouquet } from '../../types/model/bouquet';

export interface BouquetsDetailsFormInput {
  bouquetsName: string;
}
interface Props {
  formData?: Bouquet;
  submitFormData?: (data: string) => void;
  isEdit?: boolean;
}

const BouquetsDetailsForm = ({ formData, submitFormData, isEdit = false }: Props) => {
  const { handleSubmit, setValue, control } = useForm<BouquetsDetailsFormInput>({
    defaultValues: {
      bouquetsName: formData?.name ?? '',
    },
  });

  useEffect(() => {
    setValue('bouquetsName', formData?.name ?? '');
  }, [formData, setValue]);

  const onSubmit: SubmitHandler<BouquetsDetailsFormInput> = data => {
    if (submitFormData) submitFormData(data.bouquetsName);
  };

  return (
    <BouquetsDetailsFormComponent>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={control}
          name='bouquetsName'
          rules={{ required: true }}
          render={({ field }) => (
            <div className='form-item'>
              <span className='form-item-name'>Bouquet Name</span>
              <div className='form-item-content'>
                <input type='text' className='form-item-input' autoComplete='off' {...field} />
              </div>
            </div>
          )}
        />

        <FormFooter
          hidePrev
          noIconOnNext
          nextButtonTitle={isEdit ? 'Edit Bouquets' : 'Add Bouquets'}
        />
      </form>
    </BouquetsDetailsFormComponent>
  );
};

const BouquetsDetailsFormComponent = styled.div`
  .eye-button {
    position: absolute;
    right: 0;
    padding: 0 12px;
  }
`;

export default BouquetsDetailsForm;
