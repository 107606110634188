import React from 'react';
import styled from 'styled-components';
import { CheckGreenSvg, XMarkRedSvg } from '../../assets/svg';
import { DashboardSettingItem } from '../../types/settingData';

interface Props {
  data: DashboardSettingItem;
}

const SettingItem = ({ data }: Props) => {
  return (
    <SettingItemComponent background={data.background} color={data.color}>
      <div className='item-icon'>
        <data.icon />
      </div>
      <div className='item-info'>
        <p className='item-title'>{data.title}</p>
        <p className='item-content'>{data.content}</p>
      </div>
      <div className='item-checkbox'>
        <div className='no-box'>
          <XMarkRedSvg />
        </div>
        <div className='yes-box'>
          <CheckGreenSvg />
        </div>
      </div>
    </SettingItemComponent>
  );
};

interface StyleProps {
  background: string;
  color: string;
}

const SettingItemComponent = styled.div<StyleProps>`
  height: 90px;
  width: 100%;
  border-radius: 6px;
  background: ${props => props.background};
  padding: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 2px;

  .item-icon {
    flex-shrink: 0;
    width: 42px;
    height: 42px;
    border-radius: 50%;
    background-color: ${props => props.color};
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .item-info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;

    .item-title {
      font-size: 20px;
    }

    .item-content {
      font-size: 12px;
    }
  }

  .item-checkbox {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 1px;

    .no-box {
      width: 33px;
      height: 27px;
      padding-left: 10px;
      border-radius: 13.5px 0 0 13.5px;
      background-color: #ffffff;

      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    .yes-box {
      width: 33px;
      height: 27px;
      padding-right: 10px;
      border-radius: 0 13.5px 13.5px 0;
      background-color: #f4f4f4;

      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }
`;

export default SettingItem;
