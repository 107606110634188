import React, { useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { logoImage } from '../assets/images';
import { EyeSvg } from '../assets/svg';
import { SubmitFormButton, WhiteBorderButton } from '../components/common/Buttons';

export interface LoginFormInput {
  userName: string;
  password: string;
}

const LoginPage = () => {
  const navigate = useNavigate();
  const [isShowPassword, setIsShowPassword] = useState(false);
  const { handleSubmit, control } = useForm<LoginFormInput>({
    defaultValues: {
      userName: '',
      password: '',
    },
  });
  const onSubmit: SubmitHandler<LoginFormInput> = data => {
    console.log('data: ', data);
    navigate('/dashboard');
  };
  return (
    <LoginPageComponents>
      <div className='login-logo'>
        <img src={logoImage} alt='logo' />
      </div>
      <div className='card login-form'>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            control={control}
            name='userName'
            rules={{ required: true }}
            render={({ field }) => (
              <div className='form-item'>
                <input
                  type='text'
                  className='form-item-input'
                  autoComplete='off'
                  placeholder='Username'
                  {...field}
                />
              </div>
            )}
          />
          <Controller
            control={control}
            name='password'
            rules={{ required: true }}
            render={({ field }) => (
              <div className='form-item'>
                <input
                  type={isShowPassword ? 'text' : 'password'}
                  className='form-item-input'
                  autoComplete='off'
                  placeholder='Password'
                  {...field}
                />
                <WhiteBorderButton
                  onClick={() => {
                    setIsShowPassword(!isShowPassword);
                  }}
                >
                  <EyeSvg />
                </WhiteBorderButton>
              </div>
            )}
          />
          <div className='form-item'>
            <SubmitFormButton className='full-width'>Login</SubmitFormButton>
          </div>
        </form>
      </div>
      <div className='login-space'></div>
    </LoginPageComponents>
  );
};

const LoginPageComponents = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 3%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  background: linear-gradient(180deg, rgba(7, 7, 30, 0.2) 0%, rgba(43, 43, 75, 0.2) 100%),
    #13132c url(${logoImage}) no-repeat center center;
  background-size: 77% auto;
  background-blend-mode: soft-light;

  .login-logo {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      height: 72px;
    }
  }

  .login-space {
    height: 72px;
  }

  .login-form {
    width: 625px;
    max-width: 100%;
    padding: 30px 70px;
    display: flex;
    flex-direction: column;
  }
`;

export default LoginPage;
