import { useEffect } from 'react';
import { insertUser } from '../../lib/api';
import useRequest from '../../lib/hooks/useRequest';
import { isAxiosError } from 'axios';

export const useInsertLine = () => {
  const [_insertUser, , insertUserRes, insertUserErr] = useRequest(insertUser);

  useEffect(() => {
    if (insertUserRes) {
      console.log('insertUserRes: ', insertUserRes);
    }
  }, [insertUserRes]);

  useEffect(() => {
    if (insertUserErr) {
      if (isAxiosError(insertUserErr)) {
        console.log('insertUserErrText: ', insertUserErr.response?.data.error);
        console.log('insertUserErrMessage: ', insertUserErr.response?.data.message);
      } else {
        console.log('insertUserErr: ', insertUserErr);
      }
    }
  }, [insertUserErr]);
  return { insertLine: _insertUser, insertLineRes: insertUserRes, insertLineErr: insertUserErr };
};
