import React, { useEffect, useMemo, useState } from 'react';
import { Column } from 'react-table';
import styled from 'styled-components';
import { SecondaryFormButton } from '../../common/Buttons';
import FormTable from '../../common/FormTable';
import FormFooter from '../../common/FormFooter';
import { User } from '../../../types/model/user';
import { Bouquet } from '../../../types/model/bouquet';
import { useAtom } from 'jotai';
import { bouquetsAtom } from '../../../store/jotai';

interface Props {
  className?: string;
  setVisibleTab?: React.Dispatch<React.SetStateAction<number>>;
  formData?: User;
  setFormData?: React.Dispatch<React.SetStateAction<User>>;
  submitFormData?: (data: User) => void;
  isEdit?: boolean;
}

const LineBouquetsForm = ({
  className,
  setVisibleTab,
  formData,
  setFormData,
  submitFormData,
  isEdit = false,
}: Props) => {
  const [selectedIds, setSelectedIds] = useState<number[]>(
    formData?.bouquets?.map(cell => cell) ?? [],
  );
  const [data] = useAtom(bouquetsAtom);
  const idList: number[] = useMemo(() => data.map(x => x.id), [data]);
  const columns: Column<Bouquet>[] = useMemo(() => bouquetsHeader, []);

  // setFormData before unmount
  useEffect(() => {
    return () => {
      const submitData = selectedIds.map(str => {
        const num = Number(str);

        if (isNaN(num)) {
          console.error(`Invalid number: ${str}`);
          return 0;
        }

        return num;
      });

      if (setFormData) {
        setFormData(currentLine => ({
          ...currentLine,
          bouquets: submitData,
          outputs: [],
        }));
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIds]);
  const onClickRow = (id: unknown) => {
    const selectedIdSet = new Set(selectedIds);
    if (selectedIdSet.has(Number(id))) {
      selectedIdSet.delete(Number(id));
    } else {
      selectedIdSet.add(Number(id));
    }
    setSelectedIds(Array.from(selectedIdSet));
  };
  const handlePrev = () => {
    // if (setVisibleTab) setVisibleTab(3);
    if (setVisibleTab) setVisibleTab(1);
  };
  const handleToggle = () => {
    const selectedIdSet = new Set(selectedIds);
    const newSelectedId = idList.filter(x => !selectedIdSet.has(x));
    setSelectedIds(newSelectedId);
  };
  const handleAdd = () => {
    const submitData = selectedIds.map(str => {
      const num = Number(str);

      if (isNaN(num)) {
        console.error(`Invalid number: ${str}`);
        return 0;
      }

      return num;
    });
    if (submitFormData && formData)
      submitFormData({
        ...formData,
        bouquets: submitData,
        outputs: [],
      });
  };

  return (
    <LineBouquetsFormComponent className={className}>
      <FormTable<Bouquet>
        columns={columns}
        data={data}
        onClickRow={onClickRow}
        selectedId={selectedIds}
      />

      <FormFooter
        notSubmit
        noIconOnNext
        nextButtonTitle={isEdit ? 'Edit' : 'Add'}
        handlePrev={handlePrev}
        handleNext={handleAdd}
      >
        <SecondaryFormButton onClick={handleToggle}>Toggle All</SecondaryFormButton>
      </FormFooter>
    </LineBouquetsFormComponent>
  );
};

const LineBouquetsFormComponent = styled.div``;

export default LineBouquetsForm;

const bouquetsHeader: Column<Bouquet>[] = [
  {
    Header: 'ID',
    accessor: 'id',
  },
  {
    Header: 'Bouquet Name',
    accessor: 'name',
  },
  {
    Header: 'Streams',
    accessor: 'streams',
  },
  {
    Header: 'Movies',
    accessor: 'movies',
  },
  {
    Header: 'Series',
    accessor: 'series',
  },
  {
    Header: 'Stations',
    accessor: 'stations',
  },
];
