import React from 'react';
import styled from 'styled-components';
import { dashboardSettingItems } from '../config/data/settingData';
import { stateItems } from '../config/data/stateData';
import DashboardHeader from '../components/dashboard/DashboardHeader';
import SettingItem from '../components/dashboard/SettingItem';
import DashboardStateItem from '../components/dashboard/DashboardStateItem';
import { device } from '../styles/device';
import { serverStatusItems } from '../config/data/serverStatusData';
import StatusTableCard from '../components/dashboard/StatusTableCard';

const Dashboard = () => {
  return (
    <DashboardComponent>
      <div className='settings-card card'>
        {dashboardSettingItems.map((settingItem, index) => (
          <SettingItem key={index} data={settingItem} />
        ))}
      </div>
      <div className='main-card card'>
        <DashboardHeader />
        <div className='state-container'>
          {stateItems.map((stateItem, index) => (
            <DashboardStateItem
              key={index}
              data={stateItem}
              borderColor={stateItemBorderColor[index]}
            />
          ))}
        </div>
        <div className='status-container'>
          {/* <StatusChartCard className='status-item' /> */}
          {serverStatusItems.map((serverStatusItem, index) => (
            <StatusTableCard key={index} data={serverStatusItem} className='status-item' />
          ))}
        </div>
      </div>
    </DashboardComponent>
  );
};

const DashboardComponent = styled.div`
  width: 100%;
  .settings-card {
    width: 100%;
    padding: 17px 25px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px 30px;

    @media ${device.laptop} {
      grid-template-columns: repeat(2, 1fr);
    }

    @media ${device.tablet} {
      grid-template-columns: 1fr;
    }
  }

  .main-card {
    margin-top: 20px;
    padding: 20px 30px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    gap: 27px;

    .state-container {
      width: 100%;
      padding: 0;
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      gap: 20px;

      @media ${device.laptop} {
        grid-template-columns: repeat(3, 1fr);
      }

      @media ${device.tablet} {
        grid-template-columns: repeat(2, 1fr);
      }

      @media ${device.mobileL} {
        grid-template-columns: 1fr;
      }
    }

    .status-container {
      width: 100%;
      padding: 0;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 20px 30px;

      .status-item {
        width: 100%;
        height: 250px;
        border-radius: 8px;
        background: linear-gradient(269.58deg, #1e1e3f 0.41%, #232349 97.34%);
      }

      @media ${device.laptop} {
        grid-template-columns: 1fr;
      }
    }
  }
`;

const stateItemBorderColor: [string, string][] = [
  ['#63aafdc0', '#63aafd40'],
  ['#6F47EAc0', '#63AAFD40'],
  ['#B73BF5c0', '#FB8CBD40'],
  ['#B538F7c0', '#63AAFD40'],
  ['#FF9696c0', '#FF2D2D40'],
  ['#FF2E2Cc0', '#FFAB2D40'],
];

export default Dashboard;
