import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Controller, useForm, SubmitHandler, useWatch } from 'react-hook-form';
import { WhiteBorderButton } from '../../common/Buttons';
import { EyeSvg } from '../../../assets/svg';
import { addYears, getUnixTime } from 'date-fns';
import { CommonSelectItem } from '../../../types/model/commonSelectItem';
import TextareaAutosize from 'react-textarea-autosize';
import { findMemberGroupOption } from './MemberGroup';
import FormFooter from '../../common/FormFooter';
import DateTimePicker from '../../common/DateTimePicker';
import SlideToggle from '../../common/SlideToggle';
import { User } from '../../../types/model/user';

export interface LineDetailsFormInput {
  userName: string;
  password: string;
  memberGroup: CommonSelectItem;
  expiry: Date;
  isNeverExpire: boolean;
  max_connections: number;
  contactEmail: string;
  adminNotes: string;
  resellerNotes: string;
}
interface Props {
  className?: string;
  setVisibleTab?: React.Dispatch<React.SetStateAction<number>>;
  formData?: User;
  setFormData?: React.Dispatch<React.SetStateAction<User>>;
  role?: 'add' | 'edit';
}

const LineDetailsForm = ({
  className,
  setVisibleTab,
  formData,
  setFormData,
  role = 'add',
}: Props) => {
  const [isShowPassword, setIsShowPassword] = useState(false);
  const { handleSubmit, control, getValues } = useForm<LineDetailsFormInput>({
    defaultValues: {
      userName: formData?.username ?? (role === 'add' ? generateRandomString(10) : ''),
      password: formData?.password ?? (role === 'add' ? generateRandomString(10) : ''),
      memberGroup: {
        value: formData?.group ?? '',
        label: formData?.group ? findMemberGroupOption(formData?.group)?.label : '',
      },
      expiry: formData?.expiry_date
        ? new Date(formData?.expiry_date * 1000)
        : addYears(new Date(), 1),
      isNeverExpire: false,
      max_connections: formData?.max_connections ? formData?.max_connections : 5,
      contactEmail: '',
      adminNotes: '',
      resellerNotes: '',
    },
  });

  const isNeverExpire = useWatch({ control, name: 'isNeverExpire' });

  // setFormData before unmount
  useEffect(() => {
    return () => {
      const data = getValues();
      // if (!data.memberGroup.value) {
      //   setFocus('memberGroup');
      //   return;
      // }
      if (setFormData) {
        setFormData(currentLine => ({
          ...currentLine,
          username: data.userName,
          password: data.password,
          group: 'user', //data.memberGroup.value
          expiry_date: getUnixTime(data.expiry),
          max_connections: parseInt(`${data.max_connections}`),
        }));
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const onSubmit: SubmitHandler<LineDetailsFormInput> = () => {
    if (setVisibleTab) setVisibleTab(2);
  };

  return (
    <LineDetailsFormComponent className={className}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={control}
          name='userName'
          rules={{ required: true }}
          render={({ field }) => (
            <div className='form-item'>
              <span className='form-item-name'>User Name</span>
              <div className='form-item-content'>
                <input
                  type='text'
                  className='form-item-input'
                  autoComplete='off'
                  placeholder='Autogenerated if blank'
                  {...field}
                />
              </div>
            </div>
          )}
        />
        <Controller
          control={control}
          name='password'
          rules={{ required: true }}
          render={({ field }) => (
            <div className='form-item'>
              <span className='form-item-name'>Password</span>
              <div className='form-item-content'>
                <input
                  type={isShowPassword ? 'text' : 'password'}
                  className='form-item-input'
                  autoComplete='off'
                  placeholder='Enter password'
                  {...field}
                />
                <WhiteBorderButton
                  className='eye-button'
                  onClick={() => {
                    setIsShowPassword(!isShowPassword);
                  }}
                >
                  <EyeSvg />
                </WhiteBorderButton>
              </div>
            </div>
          )}
        />
        {/* <Controller
          control={control}
          name='memberGroup'
          rules={{ required: true }}
          render={({ field }) => (
            <div className='form-item'>
              <span className='form-item-name'>Member Group</span>
              <MemberGroup className='form-item-content' field={field} />
            </div>
          )}
        /> */}
        <Controller
          control={control}
          name='expiry'
          rules={{ required: true }}
          render={({ field }) => (
            <div className='form-item'>
              <span className='form-item-name'>Expiry</span>
              <div className='form-item-content'>
                <DateTimePicker
                  disabled={isNeverExpire}
                  value={field.value}
                  onChange={field.onChange}
                />
                <Controller
                  control={control}
                  name='isNeverExpire'
                  rules={{ required: false }}
                  render={({ field }) => (
                    <div className='form-sub-item'>
                      <span className='form-item-name'>Never Expire</span>
                      <SlideToggle value={field.value} onChange={field.onChange} />
                    </div>
                  )}
                />
              </div>
            </div>
          )}
        />
        <Controller
          control={control}
          name='max_connections'
          render={({ field }) => (
            <div className='form-item'>
              <span className='form-item-name'>Max Connections</span>
              <div className='form-item-content'>
                <input
                  type='number'
                  className='form-item-input'
                  autoComplete='off'
                  placeholder='Autogenerated if blank'
                  {...field}
                />
              </div>
            </div>
          )}
        />
        {/* <Controller
          control={control}
          name='contactEmail'
          render={({ field }) => (
            <div className='form-item'>
              <span className='form-item-name'>Contact Email</span>
              <div className='form-item-content'>
                <input
                  type='text'
                  className='form-item-input'
                  autoComplete='off'
                  placeholder='Autogenerated if blank'
                  {...field}
                />
              </div>
            </div>
          )}
        /> */}
        <Controller
          control={control}
          name='adminNotes'
          render={({ field }) => (
            <div className='form-item'>
              <span className='form-item-name'>Admin Notes</span>
              <div className='form-item-content'>
                <TextareaAutosize
                  className='form-item-input'
                  minRows={2}
                  autoComplete='off'
                  placeholder='_'
                  {...field}
                />
              </div>
            </div>
          )}
        />
        {/* <Controller
          control={control}
          name='resellerNotes'
          render={({ field }) => (
            <div className='form-item'>
              <span className='form-item-name'>Reseller Notes</span>
              <div className='form-item-content'>
                <TextareaAutosize
                  className='form-item-input'
                  minRows={2}
                  autoComplete='off'
                  placeholder='_'
                  {...field}
                />
              </div>
            </div>
          )}
        /> */}

        <FormFooter hidePrev />
      </form>
    </LineDetailsFormComponent>
  );
};

const LineDetailsFormComponent = styled.div`
  .eye-button {
    position: absolute;
    right: 0;
    padding: 0 12px;
  }
`;

export default LineDetailsForm;

const generateRandomString = (length: number): string => {
  const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  let randomString = '';

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    randomString += characters.charAt(randomIndex);
  }

  return randomString;
};
