import { useAtom } from 'jotai';
import React, { useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import ImageViewer from 'react-simple-image-viewer';
import TextareaAutosize from 'react-textarea-autosize';
import styled from 'styled-components';
import { EyeSvg } from '../../../assets/svg';
import { bouquetsAtom, categoriesAtom } from '../../../store/jotai';
import { Bouquet } from '../../../types/model/bouquet';
import { Category } from '../../../types/model/category';
import { Stream } from '../../../types/model/stream';
import { WhiteBorderButton } from '../../common/Buttons';
import FormFooter from '../../common/FormFooter';
import Bouquets, { getBouquetsOptions } from './Bouquets';
import Categories, { getCategoriesOptions } from './Categories';

export interface StreamDetailsFormInput {
  file?: File;
  streamName: string;
  streamLogo: string;
  categories: Category[];
  bouquets: Bouquet[];
  notes: string;
}
interface Props {
  className?: string;
  setVisibleTab?: React.Dispatch<React.SetStateAction<number>>;
  formData?: Stream;
  setFormData?: React.Dispatch<React.SetStateAction<Stream>>;
}

const StreamDetailsForm = ({ className, setVisibleTab, formData, setFormData }: Props) => {
  const [imageUrl, setImageUrl] = useState('');
  const [showPreview, setShowPreview] = useState(false);
  const [categories] = useAtom(categoriesAtom);
  const [bouquets] = useAtom(bouquetsAtom);
  const { handleSubmit, control, getValues } = useForm<StreamDetailsFormInput>({
    defaultValues: {
      streamName: formData?.name ?? '',
      streamLogo: formData?.stream_icon ?? '',
      categories: formData?.categories ? getCategoriesOptions(categories, formData.categories) : [],
      bouquets: formData?.bouquets ? getBouquetsOptions(bouquets, formData.bouquets) : [],
      notes: formData?.notes ?? '',
    },
  });

  // If setFormData before unmount
  useEffect(() => {
    return () => {
      const data = getValues();
      if (setFormData) {
        setFormData(currentLine => ({
          ...currentLine,
          name: data.streamName,
          stream_icon: data.streamLogo,
          stream_type: 'Live',
          notes: data.notes,
          categories: data.categories.map(category => category.id),
          bouquets: data.bouquets.map(bouquet => bouquet.id),
        }));
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit: SubmitHandler<StreamDetailsFormInput> = () => {
    if (setVisibleTab) setVisibleTab(2);
  };

  return (
    <StreamDetailsFormComponent className={className}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={control}
          name='streamName'
          rules={{ required: true }}
          render={({ field }) => (
            <div className='form-item'>
              <span className='form-item-name'>Stream Name</span>
              <div className='form-item-content'>
                <input type='text' className='form-item-input' autoComplete='off' {...field} />
              </div>
            </div>
          )}
        />
        <Controller
          control={control}
          name='streamLogo'
          rules={{ required: true }}
          render={({ field }) => (
            <div className='form-item'>
              <span className='form-item-name'>StreamLogo</span>
              <div className='form-item-content'>
                <input
                  type={'text'}
                  className='form-item-input'
                  autoComplete='off'
                  placeholder='Fill stream logo url'
                  {...field}
                  onChange={event => {
                    const { value } = event.target;
                    setImageUrl(value);
                    field.onChange(event);
                  }}
                />
                <WhiteBorderButton
                  className='eye-button'
                  onClick={() => {
                    setShowPreview(true);
                  }}
                >
                  <EyeSvg />
                </WhiteBorderButton>
              </div>
            </div>
          )}
        />
        <Controller
          control={control}
          name='categories'
          rules={{ required: true }}
          render={({ field }) => (
            <div className='form-item'>
              <span className='form-item-name'>Categories</span>
              <Categories className='form-item-content one-container-in-flex' field={field} />
            </div>
          )}
        />
        <Controller
          control={control}
          name='bouquets'
          rules={{ required: true }}
          render={({ field }) => (
            <div className='form-item'>
              <span className='form-item-name'>Bouquets</span>
              <Bouquets className='form-item-content one-container-in-flex' field={field} />
            </div>
          )}
        />
        <Controller
          control={control}
          name='notes'
          render={({ field }) => (
            <div className='form-item'>
              <span className='form-item-name'>Notes</span>
              <div className='form-item-content'>
                <TextareaAutosize
                  className='form-item-input'
                  minRows={2}
                  autoComplete='off'
                  placeholder='_'
                  {...field}
                />
              </div>
            </div>
          )}
        />

        {showPreview && (
          <ImageViewer
            src={[imageUrl]}
            currentIndex={0}
            disableScroll={false}
            closeOnClickOutside={true}
            backgroundStyle={{ backgroundColor: 'rgba(0,0,0,0.2)' }}
            onClose={() => setShowPreview(false)}
          />
        )}
        <FormFooter hidePrev />
      </form>
    </StreamDetailsFormComponent>
  );
};

const StreamDetailsFormComponent = styled.div`
  .eye-button {
    position: absolute;
    right: 0;
    padding: 0 12px;
  }

  .one-container-in-flex {
    & > div {
      width: 100%;
    }
  }
`;

export default StreamDetailsForm;
