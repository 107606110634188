import React from 'react';
import styled from 'styled-components';
import DateTime from 'react-datetime';

interface Props {
  className?: string;
  disabled?: boolean;
  value: Date;
  onChange: (value: Date) => void;
}

const DateTimePicker = ({ className, disabled = false, value, onChange }: Props) => {
  return (
    <DateTimePickerComponents className={className}>
      <DateTime
        inputProps={{ className: 'datetime-picker-container', disabled }}
        dateFormat='YYYY-MM-DD'
        value={value}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onChange={(data: any) => onChange(data._d)}
      />
    </DateTimePickerComponents>
  );
};

const DateTimePickerComponents = styled.div`
  .datetime-picker-container {
    width: 250px;
    background-color: var(--dark-bg);
    font-size: 20px;
    padding: 7px 20px;
    border-radius: 8px;
    color: var(--text-form-content);

    &[disabled] {
      background-color: var(--dark-grey);
      color: var(--text-secondary);
    }
  }
`;

export default DateTimePicker;
