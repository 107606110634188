import React from 'react';
import Select from 'react-select';
import styled from 'styled-components';
import { settingSelectStyles } from '../../../styles/reactSelect';
import { CommonSelectItem } from '../../../types/model/commonSelectItem';

const dateFormatOptions: CommonSelectItem[] = [
  { value: 'Y-m-d', label: 'Y-m-d' },
  { value: 'Y/m/d', label: 'Y/m/d' },
  { value: 'm/d/Y', label: 'm/d/Y' },
];

interface Props {
  className?: string;
  value: string;
  onChange: (value: string) => void;
}

const DateFormat = ({ className, value, onChange }: Props) => {
  return (
    <DateFormatComponents className={className}>
      <Select<CommonSelectItem>
        className='form-item-select'
        options={dateFormatOptions}
        styles={settingSelectStyles}
        value={dateFormatOptions[dateFormatOptions.findIndex(option => option.value === value)]}
        onChange={newValue => onChange(newValue?.value ?? '')}
      />
    </DateFormatComponents>
  );
};

const DateFormatComponents = styled.div``;

export default DateFormat;
