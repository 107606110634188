import axios from 'axios';
import { Category } from '../../types/model/category';
import { Stream } from '../../types/model/stream';
import { User } from '../../types/model/user';
import { Bouquet } from './../../types/model/bouquet';
import { bouquetsURL, categoriesURL, statsURL, streamsURL, usersURL } from './URL';

export const baseURL = process.env.REACT_APP_SERVER_URL || 'http://localhost:3000';
const apiClient = axios.create({
  baseURL: baseURL,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
});

export default apiClient;

// Server stats APIs
export const getServerStats = () => apiClient.get(statsURL.getServerStats);

// Users by stream APIs
export const getUsersByStream = (streamId: string) =>
  apiClient.get(`${statsURL.getUsersByStream}/${streamId}/users`);

// Users APIs
export const insertUser = (params: User) => apiClient.post(usersURL.insertUser, params);

export const getUser = (username: string) => apiClient.get(`${usersURL.getUser}/${username}`);

export const updateUser = (params: User) => apiClient.patch(usersURL.updateUser, params);

export const deleteUser = (username: string) =>
  apiClient.delete(`${usersURL.deleteUser}/${username}`);

export const getAllUsers = () => apiClient.get(usersURL.getAllUsers);

// Streams APIs
export const insertStream = (params: Stream) => apiClient.post(streamsURL.insertStream, params);

export const startStream = (streamId: string) =>
  apiClient.post(`${streamsURL.startStream}/${streamId}/start`);

export const stopStream = (streamId: string) =>
  apiClient.post(`${streamsURL.startStream}/${streamId}/stop`);

export const getStream = (streamId: string) => apiClient.get(`${streamsURL.getStream}/${streamId}`);

export const updateStream = (params: Stream) => apiClient.patch(streamsURL.updateStream, params);

export const deleteStream = (streamId: string) =>
  apiClient.delete(`${streamsURL.deleteStream}/${streamId}`);

export const getAllStreams = () => apiClient.get(streamsURL.getAllStreams);

export const multiPartFormCall = (url: string, formData: FormData) => {
  const multiPartConfig = {
    headers: {
      'content-type': 'multipart/form-data',
    },
  };
  return apiClient.post(url, formData, multiPartConfig);
};
export const importStreams = (params: FormData) =>
  multiPartFormCall(streamsURL.importStreams, params);
  
// Categories APIs
export const insertCategory = (params: string) =>
  apiClient.post(categoriesURL.insertCategory, params);

export const getCategory = (categoryId: number) =>
  apiClient.get(`${categoriesURL.getCategory}/${categoryId}`);

export const editCategory = (params: Category) =>
  apiClient.patch(categoriesURL.editCategory, params);

export const deleteCategory = (categoryId: number) =>
  apiClient.delete(`${categoriesURL.deleteCategory}/${categoryId}`);

export const getAllCategories = () => apiClient.get(categoriesURL.getAllCategories);

// Bouquets APIs
export const insertBouquet = (params: string) => apiClient.post(bouquetsURL.insertBouquet, params);

export const getBouquet = (bouquetId: number) =>
  apiClient.get(`${bouquetsURL.getBouquet}/${bouquetId}`);

export const editBouquet = (params: Bouquet) => apiClient.patch(bouquetsURL.editBouquet, params);

export const deleteBouquet = (bouquetId: number) =>
  apiClient.delete(`${bouquetsURL.deleteBouquet}/${bouquetId}`);

export const getAllBouquets = () => apiClient.get(bouquetsURL.getAllBouquets);
