import React from 'react';
import styled from 'styled-components';
import { Controller, useForm, SubmitHandler } from 'react-hook-form';
import { SubmitFormButton } from '../../common/Buttons';
import SlideToggle from '../../common/SlideToggle';
import CustomVideo from './CustomVideo';
import LoadBalancing from './LoadBalancing';
import ChannelSortingType from './ChannelSortingType';
import FfmpegVersion from './FfmpegVersion';
import FfmpegSegmentType from './FfmpegSegmentType';
import FpsCheckType from './FpsCheckType';
import { CommonSelectItem } from '../../../types/model/commonSelectItem';
import AllowedCountries from './AllowedCountries';
import Tooltip from './../../common/Tooltip';
import { settingStreamingTooltipMessage } from '../../../config/content/tooltipData';

export interface SettingsStreamingFormInput {
  enableIspLock: boolean;
  enableAsnLock: boolean;
  disableMpegTsOutput: boolean;
  allowRestreamersMpegTs: boolean;
  disableHlsOutput: boolean;
  allowRestreamersHls: boolean;
  disableRtmpOutput: boolean;
  allowRestreamersRtmp: boolean;
  caseSensitiveLines: boolean;
  overrideCountryWithFirst: boolean;
  encryptHlsSegments: boolean;
  disallowEmptyUa: boolean;
  vodBitrateBuffer: number;
  vodLimitAt: number;
  autoKickHours: number;
  useDomainNameInApi: boolean;
  encryptPlaylists: boolean;
  encryptRestreamerPlaylists: boolean;
  restrictionsOnPlaylistsAndEpg: boolean;
  ignoreInvalidCredentials: boolean;
  clientPrebuffer: number;
  restreamerPrebuffer: number;
  loadBalancing: string;
  restreamerBypassProxy: boolean;
  channelSortingType: string;
  sortVodByDate: boolean;
  useNginxBuffer: boolean;
  logClientIsps: boolean;
  onlineCapacityInterval: number;
  monitorConnectionStatus: boolean;
  autoRestartCrashedPhpFpm: boolean;
  killRogueFfmpegPids: boolean;
  redirectExpiration: number;
  hlsReadNative: boolean;
  readBufferSize: number;
  redisConnectionSyncTimer: number;
  allowCdnForwarding: boolean;
  maxFailures: number;
  installOff: boolean;
  exitOnFailure: boolean;
  waitTimeout: number;
  requestPrebuffer: boolean;
  balanceAsLive: boolean;
  enableScanner: boolean;
  scanTime: number;
  maxProveTime: number;
  keepLogsFor: number;
  maxMovieEncodes: number;
  maxChannelEncodes: number;
  queueLoopTimer: number;
  segmentDuration: number;
  listSize: number;
  deleteThreshold: number;
  maxSegmentWaitTime: number;
  analysisDuration: number;
  probeSize: number;
  ffmpegVersion: string;
  ffmpegSegmentType: string;
  ffmpegShowWarnings: boolean;
  ignoreKeyframes: boolean;
  dtsUseFfmpegV4: boolean;
  loopbackStreamsViaPhp: boolean;
  restartOnAudioLoss: boolean;
  priorityBackup: boolean;
  probeDuration: number;
  streamFailureSleep: number;
  fpsStartDelay: number;
  fpsCheckType: string;
  probeViaApi: boolean;
  hasStreamDownVideo: boolean;
  streamDownVideo: string;
  hasBannedVideo: boolean;
  bannedVideo: string;
  hasExpiredVideo: boolean;
  expiredVideo: string;
  hasExpiringVideo: boolean;
  expiringVideo: string;
  hasSecondIpConnectedVideo: boolean;
  secondIpConnectedVideo: string;
  allowedCountries: CommonSelectItem[];
}
interface Props {
  className?: string;
  setVisibleTab?: React.Dispatch<React.SetStateAction<number>>;
}

const SettingsStreamingForm = ({ className }: Props) => {
  const { handleSubmit, control } = useForm<SettingsStreamingFormInput>({
    defaultValues: {
      enableIspLock: false,
      enableAsnLock: false,
      disableMpegTsOutput: false,
      allowRestreamersMpegTs: false,
      disableHlsOutput: false,
      allowRestreamersHls: false,
      disableRtmpOutput: false,
      allowRestreamersRtmp: false,
      caseSensitiveLines: false,
      overrideCountryWithFirst: false,
      encryptHlsSegments: false,
      disallowEmptyUa: false,
      vodBitrateBuffer: 400,
      vodLimitAt: 10,
      autoKickHours: 3,
      useDomainNameInApi: false,
      encryptPlaylists: false,
      encryptRestreamerPlaylists: false,
      restrictionsOnPlaylistsAndEpg: false,
      ignoreInvalidCredentials: false,
      clientPrebuffer: 10,
      restreamerPrebuffer: 0,
      loadBalancing: 'connections',
      restreamerBypassProxy: false,
      channelSortingType: 'legacy',
      sortVodByDate: false,
      useNginxBuffer: false,
      logClientIsps: false,
      onlineCapacityInterval: 10,
      monitorConnectionStatus: false,
      autoRestartCrashedPhpFpm: false,
      killRogueFfmpegPids: false,
      redirectExpiration: 5,
      hlsReadNative: false,
      readBufferSize: 8192,
      redisConnectionSyncTimer: 1,
      allowCdnForwarding: false,
      maxFailures: 3,
      installOff: false,
      exitOnFailure: false,
      waitTimeout: 20,
      requestPrebuffer: false,
      balanceAsLive: false,
      enableScanner: false,
      scanTime: 3600,
      maxProveTime: 5,
      keepLogsFor: 604800,
      maxMovieEncodes: 10,
      maxChannelEncodes: 1,
      queueLoopTimer: 1,
      segmentDuration: 10,
      listSize: 6,
      deleteThreshold: 4,
      maxSegmentWaitTime: 20,
      analysisDuration: 5000000,
      probeSize: 5000000,
      ffmpegVersion: 'v4.0',
      ffmpegSegmentType: 'segment',
      ffmpegShowWarnings: false,
      ignoreKeyframes: false,
      dtsUseFfmpegV4: false,
      loopbackStreamsViaPhp: false,
      restartOnAudioLoss: false,
      priorityBackup: false,
      probeDuration: 10,
      streamFailureSleep: 10,
      fpsStartDelay: 600,
      fpsCheckType: 'avgFrameRate',
      probeViaApi: false,
      hasStreamDownVideo: false,
      streamDownVideo: '',
      hasBannedVideo: false,
      bannedVideo: '',
      hasExpiredVideo: false,
      expiredVideo: '',
      hasExpiringVideo: false,
      expiringVideo: '',
      hasSecondIpConnectedVideo: false,
      secondIpConnectedVideo: '',
      allowedCountries: [],
    },
  });

  const onSubmit: SubmitHandler<SettingsStreamingFormInput> = data => {
    console.log('data: ', data);
  };

  return (
    <SettingsStreamingFormComponent className={className}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='form-sub-title'>Preferences</div>
        <div className='form-item-two-col-card element-card'>
          <Controller
            control={control}
            name='enableIspLock'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Enable ISP Lock
                  <Tooltip message={settingStreamingTooltipMessage.enableIspLock} />
                </span>
                <SlideToggle value={field.value} onChange={field.onChange} />
              </div>
            )}
          />
          <Controller
            control={control}
            name='enableAsnLock'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Enable ASN Lock
                  <Tooltip message={settingStreamingTooltipMessage.enableAsnLock} />
                </span>
                <SlideToggle value={field.value} onChange={field.onChange} />
              </div>
            )}
          />
          <Controller
            control={control}
            name='disableMpegTsOutput'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Disable MPEG-TS Output
                  <Tooltip message={settingStreamingTooltipMessage.disableMpegTsOutput} />
                </span>
                <SlideToggle value={field.value} onChange={field.onChange} />
              </div>
            )}
          />
          <Controller
            control={control}
            name='allowRestreamersMpegTs'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Allow Restreamers-MPEG-TS
                  <Tooltip message={settingStreamingTooltipMessage.allowRestreamersMpegTs} />
                </span>
                <SlideToggle value={field.value} onChange={field.onChange} />
              </div>
            )}
          />
          <Controller
            control={control}
            name='disableHlsOutput'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Disable HLS Output
                  <Tooltip message={settingStreamingTooltipMessage.disableHlsOutput} />
                </span>
                <SlideToggle value={field.value} onChange={field.onChange} />
              </div>
            )}
          />
          <Controller
            control={control}
            name='allowRestreamersHls'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Allow Restreamers-HLS
                  <Tooltip message={settingStreamingTooltipMessage.allowRestreamersHls} />
                </span>
                <SlideToggle value={field.value} onChange={field.onChange} />
              </div>
            )}
          />
          <Controller
            control={control}
            name='disableRtmpOutput'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Disable RTMP Output
                  <Tooltip message={settingStreamingTooltipMessage.disableRtmpOutput} />
                </span>
                <SlideToggle value={field.value} onChange={field.onChange} />
              </div>
            )}
          />
          <Controller
            control={control}
            name='allowRestreamersRtmp'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Allow Restreamers-RTMP
                  <Tooltip message={settingStreamingTooltipMessage.allowRestreamersRtmp} />
                </span>
                <SlideToggle value={field.value} onChange={field.onChange} />
              </div>
            )}
          />
          <Controller
            control={control}
            name='caseSensitiveLines'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Case Sensitive Lines
                  <Tooltip message={settingStreamingTooltipMessage.caseSensitiveLines} />
                </span>
                <SlideToggle value={field.value} onChange={field.onChange} />
              </div>
            )}
          />
          <Controller
            control={control}
            name='overrideCountryWithFirst'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Override Country with First
                  <Tooltip message={settingStreamingTooltipMessage.overrideCountryWithFirst} />
                </span>
                <SlideToggle value={field.value} onChange={field.onChange} />
              </div>
            )}
          />
          <Controller
            control={control}
            name='encryptHlsSegments'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Encrypt HLS Segments
                  <Tooltip message={settingStreamingTooltipMessage.encryptHlsSegments} />
                </span>
                <SlideToggle value={field.value} onChange={field.onChange} />
              </div>
            )}
          />
          <Controller
            control={control}
            name='disallowEmptyUa'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Disallow Empty UA
                  <Tooltip message={settingStreamingTooltipMessage.disallowEmptyUa} />
                </span>
                <SlideToggle value={field.value} onChange={field.onChange} />
              </div>
            )}
          />
          <Controller
            control={control}
            name='vodBitrateBuffer'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  VOD Bitrate Buffer
                  <Tooltip message={settingStreamingTooltipMessage.vodBitrateBuffer} />
                </span>
                <input
                  type='number'
                  className='form-item-two-col-card-item-input'
                  autoComplete='off'
                  placeholder=''
                  {...field}
                />
              </div>
            )}
          />
          <Controller
            control={control}
            name='vodLimitAt'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  VOD Limit At
                  <Tooltip message={settingStreamingTooltipMessage.vodLimitAt} />
                </span>
                <input
                  type='number'
                  className='form-item-two-col-card-item-input'
                  autoComplete='off'
                  placeholder=''
                  {...field}
                />
              </div>
            )}
          />
          <Controller
            control={control}
            name='autoKickHours'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Auto-Kick Hours
                  <Tooltip message={settingStreamingTooltipMessage.autoKickHours} />
                </span>
                <input
                  type='number'
                  className='form-item-two-col-card-item-input'
                  autoComplete='off'
                  placeholder=''
                  {...field}
                />
              </div>
            )}
          />
          <Controller
            control={control}
            name='useDomainNameInApi'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Use Domain Name in API
                  <Tooltip message={settingStreamingTooltipMessage.useDomainNameInApi} />
                </span>
                <SlideToggle value={field.value} onChange={field.onChange} />
              </div>
            )}
          />
          <Controller
            control={control}
            name='encryptPlaylists'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Encrypt Playlists
                  <Tooltip message={settingStreamingTooltipMessage.encryptPlaylists} />
                </span>
                <SlideToggle value={field.value} onChange={field.onChange} />
              </div>
            )}
          />
          <Controller
            control={control}
            name='encryptRestreamerPlaylists'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Encrypt Restreamer Playlists
                  <Tooltip message={settingStreamingTooltipMessage.encryptRestreamerPlaylists} />
                </span>
                <SlideToggle value={field.value} onChange={field.onChange} />
              </div>
            )}
          />
          <Controller
            control={control}
            name='restrictionsOnPlaylistsAndEpg'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Restrictions on Playlists & EPG
                  <Tooltip message={settingStreamingTooltipMessage.restrictionsOnPlaylistsAndEpg} />
                </span>
                <SlideToggle value={field.value} onChange={field.onChange} />
              </div>
            )}
          />
          <Controller
            control={control}
            name='ignoreInvalidCredentials'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Ignore Invalid Credentials
                  <Tooltip message={settingStreamingTooltipMessage.ignoreInvalidCredentials} />
                </span>
                <SlideToggle value={field.value} onChange={field.onChange} />
              </div>
            )}
          />
          <Controller
            control={control}
            name='clientPrebuffer'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Client Prebuffer
                  <Tooltip message={settingStreamingTooltipMessage.clientPrebuffer} />
                </span>
                <input
                  type='number'
                  className='form-item-two-col-card-item-input'
                  autoComplete='off'
                  placeholder=''
                  {...field}
                />
              </div>
            )}
          />
          <Controller
            control={control}
            name='restreamerPrebuffer'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Restreamer Prebuffer
                  <Tooltip message={settingStreamingTooltipMessage.restreamerPrebuffer} />
                </span>
                <input
                  type='number'
                  className='form-item-two-col-card-item-input'
                  autoComplete='off'
                  placeholder=''
                  {...field}
                />
              </div>
            )}
          />
          <Controller
            control={control}
            name='loadBalancing'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Load Balancing
                  <Tooltip message={settingStreamingTooltipMessage.loadBalancing} />
                </span>
                <LoadBalancing
                  className='form-item-two-col-card-item-select'
                  value={field.value}
                  onChange={(value: string) => field.onChange(value)}
                />
              </div>
            )}
          />
          <Controller
            control={control}
            name='restreamerBypassProxy'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Restreamer Bypass Proxy
                  <Tooltip message={settingStreamingTooltipMessage.restreamerBypassProxy} />
                </span>
                <SlideToggle value={field.value} onChange={field.onChange} />
              </div>
            )}
          />
          <Controller
            control={control}
            name='channelSortingType'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Channel Sorting Type
                  <Tooltip message={settingStreamingTooltipMessage.channelSortingType} />
                </span>
                <ChannelSortingType
                  className='form-item-two-col-card-item-select'
                  value={field.value}
                  onChange={(value: string) => field.onChange(value)}
                />
              </div>
            )}
          />
          <Controller
            control={control}
            name='sortVodByDate'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Sort VOD by Date
                  <Tooltip message={settingStreamingTooltipMessage.sortVodByDate} />
                </span>
                <SlideToggle value={field.value} onChange={field.onChange} />
              </div>
            )}
          />
          <Controller
            control={control}
            name='useNginxBuffer'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Use Nginx Buffer
                  <Tooltip message={settingStreamingTooltipMessage.useNginxBuffer} />
                </span>
                <SlideToggle value={field.value} onChange={field.onChange} />
              </div>
            )}
          />
          <Controller
            control={control}
            name='logClientIsps'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Lo gClient ISP&#39;s
                  <Tooltip message={settingStreamingTooltipMessage.logClientIsps} />
                </span>
                <SlideToggle value={field.value} onChange={field.onChange} />
              </div>
            )}
          />
          <Controller
            control={control}
            name='onlineCapacityInterval'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Online Capacity Interval
                  <Tooltip message={settingStreamingTooltipMessage.onlineCapacityInterval} />
                </span>
                <input
                  type='number'
                  className='form-item-two-col-card-item-input'
                  autoComplete='off'
                  placeholder=''
                  {...field}
                />
              </div>
            )}
          />
          <Controller
            control={control}
            name='monitorConnectionStatus'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Monitor Connection Status
                  <Tooltip message={settingStreamingTooltipMessage.monitorConnectionStatus} />
                </span>
                <SlideToggle value={field.value} onChange={field.onChange} />
              </div>
            )}
          />
          <Controller
            control={control}
            name='autoRestartCrashedPhpFpm'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Auto Restart Crashed PHP-FPM
                  <Tooltip message={settingStreamingTooltipMessage.autoRestartCrashedPhpFpm} />
                </span>
                <SlideToggle value={field.value} onChange={field.onChange} />
              </div>
            )}
          />
          <Controller
            control={control}
            name='killRogueFfmpegPids'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Kill Rogue FFMPEG PID&#39;s
                  <Tooltip message={settingStreamingTooltipMessage.killRogueFfmpegPids} />
                </span>
                <SlideToggle value={field.value} onChange={field.onChange} />
              </div>
            )}
          />
          <Controller
            control={control}
            name='redirectExpiration'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Redirect Expiration
                  <Tooltip message={settingStreamingTooltipMessage.redirectExpiration} />
                </span>
                <input
                  type='number'
                  className='form-item-two-col-card-item-input'
                  autoComplete='off'
                  placeholder=''
                  {...field}
                />
              </div>
            )}
          />
          <Controller
            control={control}
            name='hlsReadNative'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  HLS Read Native
                  <Tooltip message={settingStreamingTooltipMessage.hlsReadNative} />
                </span>
                <SlideToggle value={field.value} onChange={field.onChange} />
              </div>
            )}
          />
          <Controller
            control={control}
            name='readBufferSize'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Read Buffer Size
                  <Tooltip message={settingStreamingTooltipMessage.readBufferSize} />
                </span>
                <input
                  type='number'
                  className='form-item-two-col-card-item-input'
                  autoComplete='off'
                  placeholder=''
                  {...field}
                />
              </div>
            )}
          />
          <Controller
            control={control}
            name='redisConnectionSyncTimer'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Redis Connection Sync Timer
                  <Tooltip message={settingStreamingTooltipMessage.redisConnectionSyncTimer} />
                </span>
                <input
                  type='number'
                  className='form-item-two-col-card-item-input'
                  autoComplete='off'
                  placeholder=''
                  {...field}
                />
              </div>
            )}
          />
          <Controller
            control={control}
            name='allowCdnForwarding'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Allow CDN/Forwarding
                  <Tooltip message={settingStreamingTooltipMessage.allowCdnForwarding} />
                </span>
                <SlideToggle value={field.value} onChange={field.onChange} />
              </div>
            )}
          />
          <Controller
            control={control}
            name='maxFailures'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Max Failures
                  <Tooltip message={settingStreamingTooltipMessage.maxFailures} />
                </span>
                <input
                  type='number'
                  className='form-item-two-col-card-item-input'
                  autoComplete='off'
                  placeholder=''
                  {...field}
                />
              </div>
            )}
          />
        </div>

        <div className='form-sub-title'>On-Demand Settings</div>
        <div className='form-item-two-col-card element-card'>
          <Controller
            control={control}
            name='installOff'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Install Off
                  <Tooltip message={settingStreamingTooltipMessage.installOff} />
                </span>
                <SlideToggle value={field.value} onChange={field.onChange} />
              </div>
            )}
          />
          <Controller
            control={control}
            name='exitOnFailure'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Exit on Failure
                  <Tooltip message={settingStreamingTooltipMessage.exitOnFailure} />
                </span>
                <SlideToggle value={field.value} onChange={field.onChange} />
              </div>
            )}
          />
          <Controller
            control={control}
            name='waitTimeout'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Wait Timeout
                  <Tooltip message={settingStreamingTooltipMessage.waitTimeout} />
                </span>
                <input
                  type='number'
                  className='form-item-two-col-card-item-input'
                  autoComplete='off'
                  placeholder=''
                  {...field}
                />
              </div>
            )}
          />
          <Controller
            control={control}
            name='requestPrebuffer'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Request Prebuffer
                  <Tooltip message={settingStreamingTooltipMessage.requestPrebuffer} />
                </span>
                <SlideToggle value={field.value} onChange={field.onChange} />
              </div>
            )}
          />
          <Controller
            control={control}
            name='balanceAsLive'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Balance as Live
                  <Tooltip message={settingStreamingTooltipMessage.balanceAsLive} />
                </span>
                <SlideToggle value={field.value} onChange={field.onChange} />
              </div>
            )}
          />
        </div>

        <div className='form-sub-title'>On-Demand Scanner</div>
        <div className='form-item-two-col-card element-card'>
          <Controller
            control={control}
            name='enableScanner'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Enable Scanner
                  <Tooltip message={settingStreamingTooltipMessage.enableScanner} />
                </span>
                <SlideToggle value={field.value} onChange={field.onChange} />
              </div>
            )}
          />
          <Controller
            control={control}
            name='scanTime'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Scan Time
                  <Tooltip message={settingStreamingTooltipMessage.scanTime} />
                </span>
                <input
                  type='number'
                  className='form-item-two-col-card-item-input'
                  autoComplete='off'
                  placeholder=''
                  {...field}
                />
              </div>
            )}
          />
          <Controller
            control={control}
            name='maxProveTime'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Max Prove Time
                  <Tooltip message={settingStreamingTooltipMessage.maxProveTime} />
                </span>
                <input
                  type='number'
                  className='form-item-two-col-card-item-input'
                  autoComplete='off'
                  placeholder=''
                  {...field}
                />
              </div>
            )}
          />
          <Controller
            control={control}
            name='keepLogsFor'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Keep Logs For
                  <Tooltip message={settingStreamingTooltipMessage.keepLogsFor} />
                </span>
                <input
                  type='number'
                  className='form-item-two-col-card-item-input'
                  autoComplete='off'
                  placeholder=''
                  {...field}
                />
              </div>
            )}
          />
        </div>

        <div className='form-sub-title'>Encoding Queue Settings</div>
        <div className='form-item-two-col-card element-card'>
          <Controller
            control={control}
            name='maxMovieEncodes'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Max Movie Encodes
                  <Tooltip message={settingStreamingTooltipMessage.maxMovieEncodes} />
                </span>
                <input
                  type='number'
                  className='form-item-two-col-card-item-input'
                  autoComplete='off'
                  placeholder=''
                  {...field}
                />
              </div>
            )}
          />
          <Controller
            control={control}
            name='maxChannelEncodes'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Max Channel Encodes
                  <Tooltip message={settingStreamingTooltipMessage.maxChannelEncodes} />
                </span>
                <input
                  type='number'
                  className='form-item-two-col-card-item-input'
                  autoComplete='off'
                  placeholder=''
                  {...field}
                />
              </div>
            )}
          />
          <Controller
            control={control}
            name='queueLoopTimer'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Queue Loop Timer
                  <Tooltip message={settingStreamingTooltipMessage.queueLoopTimer} />
                </span>
                <input
                  type='number'
                  className='form-item-two-col-card-item-input'
                  autoComplete='off'
                  placeholder=''
                  {...field}
                />
              </div>
            )}
          />
        </div>

        <div className='form-sub-title'>Segment Settings</div>
        <div className='form-item-two-col-card element-card'>
          <Controller
            control={control}
            name='segmentDuration'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Segment Duration
                  <Tooltip message={settingStreamingTooltipMessage.segmentDuration} />
                </span>
                <input
                  type='number'
                  className='form-item-two-col-card-item-input'
                  autoComplete='off'
                  placeholder=''
                  {...field}
                />
              </div>
            )}
          />
          <Controller
            control={control}
            name='listSize'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  List Size
                  <Tooltip message={settingStreamingTooltipMessage.listSize} />
                </span>
                <input
                  type='number'
                  className='form-item-two-col-card-item-input'
                  autoComplete='off'
                  placeholder=''
                  {...field}
                />
              </div>
            )}
          />
          <Controller
            control={control}
            name='deleteThreshold'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Delete Threshold
                  <Tooltip message={settingStreamingTooltipMessage.deleteThreshold} />
                </span>
                <input
                  type='number'
                  className='form-item-two-col-card-item-input'
                  autoComplete='off'
                  placeholder=''
                  {...field}
                />
              </div>
            )}
          />
          <Controller
            control={control}
            name='maxSegmentWaitTime'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Max Segment Wait Time
                  <Tooltip message={settingStreamingTooltipMessage.maxSegmentWaitTime} />
                </span>
                <input
                  type='number'
                  className='form-item-two-col-card-item-input'
                  autoComplete='off'
                  placeholder=''
                  {...field}
                />
              </div>
            )}
          />
          <Controller
            control={control}
            name='analysisDuration'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Analysis Duration
                  <Tooltip message={settingStreamingTooltipMessage.analysisDuration} />
                </span>
                <input
                  type='number'
                  className='form-item-two-col-card-item-input'
                  autoComplete='off'
                  placeholder=''
                  {...field}
                />
              </div>
            )}
          />
          <Controller
            control={control}
            name='probeSize'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Probe Size
                  <Tooltip message={settingStreamingTooltipMessage.probeSize} />
                </span>
                <input
                  type='number'
                  className='form-item-two-col-card-item-input'
                  autoComplete='off'
                  placeholder=''
                  {...field}
                />
              </div>
            )}
          />
          <Controller
            control={control}
            name='ffmpegVersion'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  FFMPEG Version
                  <Tooltip message={settingStreamingTooltipMessage.ffmpegVersion} />
                </span>
                <FfmpegVersion
                  className='form-item-two-col-card-item-select'
                  value={field.value}
                  onChange={(value: string) => field.onChange(value)}
                />
              </div>
            )}
          />
          <Controller
            control={control}
            name='ffmpegSegmentType'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  FFMPEG Segment Type
                  <Tooltip message={settingStreamingTooltipMessage.ffmpegSegmentType} />
                </span>
                <FfmpegSegmentType
                  className='form-item-two-col-card-item-select'
                  value={field.value}
                  onChange={(value: string) => field.onChange(value)}
                />
              </div>
            )}
          />
          <Controller
            control={control}
            name='ffmpegShowWarnings'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  FFMPEG Show Warnings
                  <Tooltip message={settingStreamingTooltipMessage.ffmpegShowWarnings} />
                </span>
                <SlideToggle value={field.value} onChange={field.onChange} />
              </div>
            )}
          />
          <Controller
            control={control}
            name='ignoreKeyframes'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Ignore Keyframes
                  <Tooltip message={settingStreamingTooltipMessage.ignoreKeyframes} />
                </span>
                <SlideToggle value={field.value} onChange={field.onChange} />
              </div>
            )}
          />
          <Controller
            control={control}
            name='dtsUseFfmpegV4'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  DTS-Use FFMPEG v4.0
                  <Tooltip message={settingStreamingTooltipMessage.dtsUseFfmpegV4} />
                </span>
                <SlideToggle value={field.value} onChange={field.onChange} />
              </div>
            )}
          />
          <Controller
            control={control}
            name='loopbackStreamsViaPhp'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Loopback Streams via PHP
                  <Tooltip message={settingStreamingTooltipMessage.loopbackStreamsViaPhp} />
                </span>
                <SlideToggle value={field.value} onChange={field.onChange} />
              </div>
            )}
          />
        </div>

        <div className='form-sub-title'>Stream Monitor Settings</div>
        <div className='form-item-two-col-card element-card'>
          <Controller
            control={control}
            name='restartOnAudioLoss'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Restart on Audio Loss
                  <Tooltip message={settingStreamingTooltipMessage.restartOnAudioLoss} />
                </span>
                <SlideToggle value={field.value} onChange={field.onChange} />
              </div>
            )}
          />
          <Controller
            control={control}
            name='priorityBackup'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Priority Backup
                  <Tooltip message={settingStreamingTooltipMessage.priorityBackup} />
                </span>
                <SlideToggle value={field.value} onChange={field.onChange} />
              </div>
            )}
          />
          <Controller
            control={control}
            name='probeDuration'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Probe Duration
                  <Tooltip message={settingStreamingTooltipMessage.probeDuration} />
                </span>
                <input
                  type='number'
                  className='form-item-two-col-card-item-input'
                  autoComplete='off'
                  placeholder=''
                  {...field}
                />
              </div>
            )}
          />
          <Controller
            control={control}
            name='streamFailureSleep'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Stream Failure Sleep
                  <Tooltip message={settingStreamingTooltipMessage.streamFailureSleep} />
                </span>
                <input
                  type='number'
                  className='form-item-two-col-card-item-input'
                  autoComplete='off'
                  placeholder=''
                  {...field}
                />
              </div>
            )}
          />
          <Controller
            control={control}
            name='fpsStartDelay'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  FPS Start Delay
                  <Tooltip message={settingStreamingTooltipMessage.fpsStartDelay} />
                </span>
                <input
                  type='number'
                  className='form-item-two-col-card-item-input'
                  autoComplete='off'
                  placeholder=''
                  {...field}
                />
              </div>
            )}
          />
          <Controller
            control={control}
            name='fpsCheckType'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  FPS Check Type
                  <Tooltip message={settingStreamingTooltipMessage.fpsCheckType} />
                </span>
                <FpsCheckType
                  className='form-item-two-col-card-item-select'
                  value={field.value}
                  onChange={(value: string) => field.onChange(value)}
                />
              </div>
            )}
          />
          <Controller
            control={control}
            name='probeViaApi'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Probe via API
                  <Tooltip message={settingStreamingTooltipMessage.probeViaApi} />
                </span>
                <SlideToggle value={field.value} onChange={field.onChange} />
              </div>
            )}
          />
        </div>

        <div className='form-sub-title'>Off Air Videos</div>
        <div className='form-item-two-col-card element-card'>
          <Controller
            control={control}
            name='hasStreamDownVideo'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Stream Down Video
                  <Tooltip message={settingStreamingTooltipMessage.streamDownVideo} />
                </span>
                <SlideToggle value={field.value} onChange={field.onChange} />
              </div>
            )}
          />
          <Controller
            control={control}
            name='streamDownVideo'
            rules={{ required: false }}
            render={({ field }) => (
              <CustomVideo
                control={control}
                watchedName={'hasStreamDownVideo'}
                value={field.value}
                onChange={field.onChange}
              />
            )}
          />
          <Controller
            control={control}
            name='hasBannedVideo'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Banned Video
                  <Tooltip message={settingStreamingTooltipMessage.bannedVideo} />
                </span>
                <SlideToggle value={field.value} onChange={field.onChange} />
              </div>
            )}
          />
          <Controller
            control={control}
            name='bannedVideo'
            rules={{ required: false }}
            render={({ field }) => (
              <CustomVideo
                control={control}
                watchedName={'hasBannedVideo'}
                value={field.value}
                onChange={field.onChange}
              />
            )}
          />
          <Controller
            control={control}
            name='hasExpiredVideo'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Expired Video
                  <Tooltip message={settingStreamingTooltipMessage.expiredVideo} />
                </span>
                <SlideToggle value={field.value} onChange={field.onChange} />
              </div>
            )}
          />
          <Controller
            control={control}
            name='expiredVideo'
            rules={{ required: false }}
            render={({ field }) => (
              <CustomVideo
                control={control}
                watchedName={'hasExpiredVideo'}
                value={field.value}
                onChange={field.onChange}
              />
            )}
          />
          <Controller
            control={control}
            name='hasExpiringVideo'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Expiring Video
                  <Tooltip message={settingStreamingTooltipMessage.expiringVideo} />
                </span>
                <SlideToggle value={field.value} onChange={field.onChange} />
              </div>
            )}
          />
          <Controller
            control={control}
            name='expiringVideo'
            rules={{ required: false }}
            render={({ field }) => (
              <CustomVideo
                control={control}
                watchedName={'hasExpiringVideo'}
                value={field.value}
                onChange={field.onChange}
              />
            )}
          />
          <Controller
            control={control}
            name='hasSecondIpConnectedVideo'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  2nd IP Connected Video
                  <Tooltip message={settingStreamingTooltipMessage.secondIpConnectedVideo} />
                </span>
                <SlideToggle value={field.value} onChange={field.onChange} />
              </div>
            )}
          />
          <Controller
            control={control}
            name='secondIpConnectedVideo'
            rules={{ required: false }}
            render={({ field }) => (
              <CustomVideo
                control={control}
                watchedName={'hasSecondIpConnectedVideo'}
                value={field.value}
                onChange={field.onChange}
              />
            )}
          />
        </div>
        <Controller
          control={control}
          name='allowedCountries'
          rules={{ required: true }}
          render={({ field }) => (
            <div>
              <div className='form-sub-title'>
                Allowed Countries
                <Tooltip message={settingStreamingTooltipMessage.allowedCountries} />
              </div>
              <AllowedCountries className='form-full-row-item' field={field} />
            </div>
          )}
        />

        <SubmitFormButton className='full-width'>Save</SubmitFormButton>
      </form>
    </SettingsStreamingFormComponent>
  );
};

const SettingsStreamingFormComponent = styled.div``;

export default SettingsStreamingForm;
