import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import FormTab from '../common/FormTab';
import FormTitle from '../common/FormTitle';
import { TabData } from '../common/Tab';
// import LineAdvancedForm from './advanced/LineAdvancedForm';
import LineBouquetsForm from './bouquets/LineBouquetsForm';
import LineDetailsForm from './details/LineDetailsForm';
// import LineRestrictionsForm from './restrictions/LineRestrictionsForm';
import { User } from '../../types/model/user';
import { useNavigate } from 'react-router-dom';
import { useInsertLine } from '../../hooks/user/useInsertLine';

const tabData: TabData[] = [
  {
    id: 1,
    tabTitle: 'Details',
    tabComponent: LineDetailsForm,
  },
  {
    id: 2,
  //   tabTitle: 'Advanced',
  //   tabComponent: LineAdvancedForm,
  // },
  // {
  //   id: 3,
  //   tabTitle: 'Restrictions',
  //   tabComponent: LineRestrictionsForm,
  // },
  // {
  //   id: 4,
    tabTitle: 'Bouquets',
    tabComponent: LineBouquetsForm,
  },
];

interface Props {
  className?: string;
}

const AddLineForm = ({ className }: Props) => {
  const navigate = useNavigate();
  const {insertLine, insertLineRes} = useInsertLine();
  const [lineData, setLineData] = useState<User>();

  useEffect(() => {
    if (insertLineRes) {
      navigate('/user/manage-lines');
    }
  }, [insertLineRes, navigate])

  const submitLineData = async (data: User) => {
    insertLine(data);
  };

  return (
    <AddLineFormComponent className={className}>
      <FormTitle>Add Line</FormTitle>
      <FormTab
        data={tabData}
        currentTab={tabData[0].id}
        formData={lineData}
        setFormData={setLineData}
        submitFormData={submitLineData}
      />
    </AddLineFormComponent>
  );
};

const AddLineFormComponent = styled.div`
  padding-top: 50px;
  width: 660px;
  max-width: 100%;
  margin: 2px auto;
`;

export default AddLineForm;
