import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import FormTab from '../common/FormTab';
import FormTitle from '../common/FormTitle';
import { TabData } from '../common/Tab';
import BouquetsDetailsForm from './BouquetsDetailsForm';
import { Bouquet } from '../../types/model/bouquet';
import { useNavigate } from 'react-router-dom';
import { useInsertBouquet } from '../../hooks/bouquet/useInsertBouquet';

const tabData: TabData[] = [
  {
    id: 1,
    tabTitle: 'Details',
    tabComponent: BouquetsDetailsForm,
  },
];

interface Props {
  className?: string;
}

const AddBouquetForm = ({ className }: Props) => {
  const navigate = useNavigate();
  const [bouquetData] = useState<Bouquet>();

  const { insertBouquet, completed } = useInsertBouquet();
  useEffect(() => {
    if (completed) {
      navigate('/management/bouquets/manage-bouquets');
    }
  }, [navigate, completed]);

  const submitBouquetData = async (data: string) => {
    insertBouquet({
      name: data,
    });
  };

  return (
    <AddBouquetFormComponent className={className}>
      <FormTitle>Add Bouquets</FormTitle>
      <FormTab
        data={tabData}
        formData={bouquetData}
        currentTab={tabData[0].id}
        submitFormData={submitBouquetData}
      />
    </AddBouquetFormComponent>
  );
};

const AddBouquetFormComponent = styled.div`
  width: 755px;
  max-width: 100%;
  margin: 2px auto;
`;

export default AddBouquetForm;
