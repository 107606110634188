import { isAxiosError } from 'axios';
import { useEffect, useMemo } from 'react';
import { importStreams } from '../../lib/api';
import useRequest from '../../lib/hooks/useRequest';
import { useBouquets } from '../bouquet/useBouquets';
import { useCategories } from '../category/useCategories';

export const useImportStreams = () => {
  const { getAllCategories, getAllCategoriesRes } = useCategories();
  const { getAllBouquets, getAllBouquetsRes } = useBouquets();
  const completed = useMemo(
    () => (getAllCategoriesRes && getAllBouquetsRes) as boolean,
    [getAllCategoriesRes, getAllBouquetsRes],
  );
  const [_importStreams, , importsStreamRes, importStreamsErr] = useRequest(importStreams);
  useEffect(() => {
    if (importsStreamRes) {
      getAllCategories();
      getAllBouquets();
    }
  }, [getAllBouquets, getAllCategories, importsStreamRes]);
  useEffect(() => {
    if (importStreamsErr) {
      if (isAxiosError(importStreamsErr)) {
        console.log('importStreamsErrText: ', importStreamsErr.response?.data.error);
        console.log('importStreamsErrMessage: ', importStreamsErr.response?.data.message);
      } else {
        console.log('importStreamsErr: ', importStreamsErr);
      }
    }
  }, [importStreamsErr]);
  return { importStreams: _importStreams, completed };
};
