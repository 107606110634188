import React from 'react';
import Select from 'react-select';
import styled from 'styled-components';
import { settingSelectStyles } from '../../../styles/reactSelect';
import { CommonSelectItem } from '../../../types/model/commonSelectItem';

const defaultContainerOptions: CommonSelectItem[] = [
  { value: 'TS', label: 'TS' },
  { value: 'M3U8', label: 'M3U8' },
];

interface Props {
  className?: string;
  value: string;
  onChange: (value: string) => void;
}

const DefaultContainer = ({ className, value, onChange }: Props) => {
  return (
    <DefaultContainerComponents className={className}>
      <Select<CommonSelectItem>
        className='form-item-select'
        options={defaultContainerOptions}
        styles={settingSelectStyles}
        value={
          defaultContainerOptions[
            defaultContainerOptions.findIndex(option => option.value === value)
          ]
        }
        onChange={newValue => onChange(newValue?.value ?? '')}
      />
    </DefaultContainerComponents>
  );
};

const DefaultContainerComponents = styled.div``;

export default DefaultContainer;
