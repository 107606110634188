import React from 'react';
import { ControllerRenderProps } from 'react-hook-form';
import Multiselect from 'multiselect-react-dropdown';
import styled from 'styled-components';
import { countriesList } from '../../../config/constant/countriesList';
import { SettingsStreamingFormInput } from './SettingsStreamingForm';
import { CommonSelectItem } from '../../../types/model/commonSelectItem';
import { multiSelectStyle } from '../../../styles/reactMultiSelect';

interface Props {
  className?: string;
  field: ControllerRenderProps<SettingsStreamingFormInput, 'allowedCountries'>;
}

const AllowedCountries = ({ className, field }: Props) => {
  const countriesListOptions = [
    {
      value: 'allSelected',
      label: 'All Countries',
    },
    {
      value: 'allRemoved',
      label: 'All Unselect',
    },
    ...countriesList,
  ];

  const handleSelect = (selectedList: CommonSelectItem[], selectedItem: CommonSelectItem) => {
    if (selectedItem.value === 'allSelected') {
      field.onChange([
        {
          value: 'allSelected',
          label: 'All Countries',
        },
        ...countriesList,
      ]);
    } else if (selectedItem.value === 'allRemoved') {
      field.onChange([]);
    } else {
      const newList = selectedList.filter(
        item => item.value !== 'allSelected' && item.value !== 'allRemoved',
      );
      console.log('newList.length: ', newList.length);
      if (newList.length === countriesList.length) {
        field.onChange([
          {
            value: 'allSelected',
            label: 'All Countries',
          },
          ...countriesList,
        ]);
      } else {
        field.onChange(newList);
      }
    }
  };
  const handleRemove = (selectedList: CommonSelectItem[]) => {
    const newList = selectedList.filter(
      item => item.value !== 'allSelected' && item.value !== 'allRemoved',
    );
    field.onChange(newList);
  };

  return (
    <AllowedCountriesComponents className={className}>
      <Multiselect
        id={'allowed_countries'}
        displayValue='label'
        options={countriesListOptions}
        selectedValues={field.value}
        onSelect={(selectedList: CommonSelectItem[], selectedItem: CommonSelectItem) =>
          handleSelect(selectedList, selectedItem)
        }
        onRemove={(selectedList: CommonSelectItem[]) => handleRemove(selectedList)}
        style={multiSelectStyle}
        showCheckbox
        avoidHighlightFirstOption
      />
    </AllowedCountriesComponents>
  );
};

const AllowedCountriesComponents = styled.div``;

export default AllowedCountries;
