import React from 'react';
import styled from 'styled-components';
import { Controller, useForm, SubmitHandler } from 'react-hook-form';
import { SubmitFormButton } from '../../common/Buttons';
import SlideToggle from '../../common/SlideToggle';
import LogKeepPeriod from './LogKeepPeriod';
import Tooltip from '../../common/Tooltip';
import { settingLogsTooltipMessage } from '../../../config/content/tooltipData';

export interface SettingsLogsFormInput {
  allowActivityLogs: boolean;
  activityLogs: string;
  allowClientLogs: boolean;
  clientLogs: string;
  allowLoginLogs: boolean;
  loginLogs: string;
  allowStreamErrorLogs: boolean;
  streamErrorLogs: string;
  allowStreamRestartLogs: boolean;
  streamRestartLogs: string;
}
interface Props {
  className?: string;
  setVisibleTab?: React.Dispatch<React.SetStateAction<number>>;
}

const SettingsLogsForm = ({ className }: Props) => {
  const { handleSubmit, control } = useForm<SettingsLogsFormInput>({
    defaultValues: {
      allowActivityLogs: false,
      activityLogs: 'forever',
      allowClientLogs: false,
      clientLogs: 'forever',
      allowLoginLogs: false,
      loginLogs: 'forever',
      allowStreamErrorLogs: false,
      streamErrorLogs: 'forever',
      allowStreamRestartLogs: false,
      streamRestartLogs: 'forever',
    },
  });

  const onSubmit: SubmitHandler<SettingsLogsFormInput> = data => {
    console.log('data: ', data);
  };

  return (
    <SettingsLogsFormComponent className={className}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='form-sub-title'>Preferences</div>
        <div className='form-item-two-col-card element-card'>
          <Controller
            control={control}
            name='allowActivityLogs'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Activity Logs
                  <Tooltip message={settingLogsTooltipMessage.activityLogs} />
                </span>
                <SlideToggle value={field.value} onChange={field.onChange} />
              </div>
            )}
          />
          <Controller
            control={control}
            name='activityLogs'
            rules={{ required: false }}
            render={({ field }) => (
              <LogKeepPeriod
                control={control}
                watchedName={'allowActivityLogs'}
                value={field.value}
                onChange={field.onChange}
              />
            )}
          />
          <Controller
            control={control}
            name='allowClientLogs'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Client Logs
                  <Tooltip message={settingLogsTooltipMessage.clientLogs} />
                </span>
                <SlideToggle value={field.value} onChange={field.onChange} />
              </div>
            )}
          />
          <Controller
            control={control}
            name='clientLogs'
            rules={{ required: false }}
            render={({ field }) => (
              <LogKeepPeriod
                control={control}
                watchedName={'allowClientLogs'}
                value={field.value}
                onChange={field.onChange}
              />
            )}
          />
          <Controller
            control={control}
            name='allowLoginLogs'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Login Logs
                  <Tooltip message={settingLogsTooltipMessage.loginLogs} />
                </span>
                <SlideToggle value={field.value} onChange={field.onChange} />
              </div>
            )}
          />
          <Controller
            control={control}
            name='loginLogs'
            rules={{ required: false }}
            render={({ field }) => (
              <LogKeepPeriod
                control={control}
                watchedName={'allowLoginLogs'}
                value={field.value}
                onChange={field.onChange}
              />
            )}
          />
          <Controller
            control={control}
            name='allowStreamErrorLogs'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Stream Error Logs
                  <Tooltip message={settingLogsTooltipMessage.streamErrorLogs} />
                </span>
                <SlideToggle value={field.value} onChange={field.onChange} />
              </div>
            )}
          />
          <Controller
            control={control}
            name='streamErrorLogs'
            rules={{ required: false }}
            render={({ field }) => (
              <LogKeepPeriod
                control={control}
                watchedName={'allowStreamErrorLogs'}
                value={field.value}
                onChange={field.onChange}
              />
            )}
          />
          <Controller
            control={control}
            name='allowStreamRestartLogs'
            rules={{ required: false }}
            render={({ field }) => (
              <div className='form-item-two-col-card-item'>
                <span className='form-item-two-col-card-item-name font-size-20'>
                  Stream Restart Logs
                  <Tooltip message={settingLogsTooltipMessage.streamRestartLogs} />
                </span>
                <SlideToggle value={field.value} onChange={field.onChange} />
              </div>
            )}
          />
          <Controller
            control={control}
            name='streamRestartLogs'
            rules={{ required: false }}
            render={({ field }) => (
              <LogKeepPeriod
                control={control}
                watchedName={'allowStreamRestartLogs'}
                value={field.value}
                onChange={field.onChange}
              />
            )}
          />
        </div>

        <SubmitFormButton className='full-width'>Save</SubmitFormButton>
      </form>
    </SettingsLogsFormComponent>
  );
};

const SettingsLogsFormComponent = styled.div``;

export default SettingsLogsForm;
