import React from 'react';
import Select from 'react-select';
import styled from 'styled-components';
import { settingSelectStyles } from '../../../styles/reactSelect';
import { CommonSelectItem } from '../../../types/model/commonSelectItem';

const showEntriesOptions: CommonSelectItem[] = [
  { value: '10', label: '10' },
  { value: '25', label: '25' },
  { value: '50', label: '50' },
  { value: '250', label: '250' },
  { value: '500', label: '500' },
  { value: '1000', label: '1000' },
];

interface Props {
  className?: string;
  value: string;
  onChange: (value: string) => void;
}

const ShowEntries = ({ className, value, onChange }: Props) => {
  return (
    <ShowEntriesComponents className={className}>
      <Select<CommonSelectItem>
        className='form-item-select'
        options={showEntriesOptions}
        styles={settingSelectStyles}
        value={showEntriesOptions[showEntriesOptions.findIndex(option => option.value === value)]}
        onChange={newValue => onChange(newValue?.value ?? '')}
      />
    </ShowEntriesComponents>
  );
};

const ShowEntriesComponents = styled.div``;

export default ShowEntries;
