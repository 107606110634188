import React from 'react';
import styled from 'styled-components';

interface CommonButtonProps {
  onClick?: () => void;
  type?: 'submit' | 'button';
  className?: string;
  children: React.ReactNode;
}

export const CommonButton = ({
  onClick,
  type = 'button',
  className,
  children,
}: CommonButtonProps) => {
  return (
    <CommonButtonComponent type={type} onClick={onClick} className={className}>
      {children}
    </CommonButtonComponent>
  );
};

const CommonButtonComponent = styled.button`
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  position: relative;
  overflow: hidden;
  background-color: transparent;
  box-shadow: 0px 2px 13px 1px #1e1e3f;

  &:hover {
    opacity: 0.8;
  }

  &:focus {
    outline: white solid 1px;
    outline-offset: 0px;
  }

  &:active {
    box-shadow: 0px 2px 13px 0px #1e1e3f;
    opacity: 0.7;
  }
`;

export const RadiusButton = styled(CommonButton)`
  padding: 2px;
  border-radius: 8px;
`;

export const MiddleButton = styled(CommonButton)<{ bgColor?: string }>`
  padding: 6px 21px;
  background-color: ${props => props.bgColor || 'inherit'};
  border-radius: 8px;
  font-size: 13px;
`;

export const ComboboxButton = styled(CommonButton)`
  padding: 2px;
  border-radius: 8px;
  border: 2px solid #ffffff;
`;

export const WhiteBorderButton = styled(CommonButton)`
  height: 38px;
  padding: 0 25px;
  border-radius: 8px;
  border: 2px solid #d6d6ff;
  font-weight: 600;
  font-size: 16px;
  color: var(--text-form-content);
`;

interface FormButtonProps {
  onClick?: () => void;
  type?: 'submit' | 'button';
  className?: string;
  children: React.ReactNode;
}

export const FormButton = ({ onClick, type = 'button', className, children }: FormButtonProps) => {
  return (
    <FormButtonComponent type={type} onClick={onClick} className={className}>
      {children}
    </FormButtonComponent>
  );
};

const FormButtonComponent = styled(CommonButton)`
  gap: 46px;
  padding: 9px 16px;
  border-radius: 8px;
  background: var(--placeholder-dark);
  font-weight: 600;
  font-size: 16px;
  color: var(--text-button);
`;

export const ColorableButton = styled(FormButton)<{ bgColor?: string }>`
  background-color: ${props => props.bgColor || 'inherit'};
`;

export const PrimaryFormButton = styled(FormButton)`
  background: var(--primary-button-bg);
`;

export const SecondaryFormButton = styled(FormButton)`
  background: var(--dark-bg);
`;

interface SubmitFormButtonProps {
  className?: string;
  children: React.ReactNode;
}

export const SubmitFormButton = ({ className, children }: SubmitFormButtonProps) => {
  return (
    <SubmitFormButtonComponent type='submit' className={className}>
      {children}
    </SubmitFormButtonComponent>
  );
};

const SubmitFormButtonComponent = styled(FormButton)`
  background: var(--primary-button-bg);
`;

interface IconButtonProps {
  onClick: () => void;
  color?: string;
  size?: number;
  className?: string;
  children: React.ReactNode;
}

export const IconButton = ({ onClick, color, size, className, children }: IconButtonProps) => {
  return (
    <IconButtonComponent onClick={onClick} color={color} size={size} className={className}>
      {children}
    </IconButtonComponent>
  );
};

const IconButtonComponent = styled(CommonButton)<{ size?: number; color?: string }>`
  width: ${props => (props.size ? props.size + 'px' : '')};
  height: ${props => (props.size ? props.size + 'px' : '')};
  border-radius: 50%;
  background-color: ${props => props.color || 'inherit'};
`;

export const IconSimpleButton = styled(IconButton)`
  box-shadow: none;

  &:active {
    box-shadow: none;
  }
  &:focus {
    outline: none;
  }
`;

interface ChipsButtonProps {
  onClick: () => void;
  color?: string;
  height?: number;
  className?: string;
  children: React.ReactNode;
}

export const ChipsButton = ({ onClick, color, height, className, children }: ChipsButtonProps) => {
  return (
    <ChipsButtonComponent onClick={onClick} color={color} height={height} className={className}>
      {children}
    </ChipsButtonComponent>
  );
};

const ChipsButtonComponent = styled(CommonButton)<{ height?: number; color?: string }>`
  height: ${props => (props.height ? props.height + 'px' : '32px')};
  padding-left: 10px;
  padding-right: 10px;
  gap: 6px;
  border-radius: ${props => (props.height ? props.height / 2 + 'px' : '16px')};
  background-color: ${props => props.color || 'inherit'};
  font-weight: 600;
  font-size: 12px;
`;
