import { useEffect } from 'react';
import useRequest from '../../lib/hooks/useRequest';
import { Stream } from '../../types/model/stream';
import { getAllStreams } from '../../lib/api';
import { useAtom } from 'jotai';
import { streamsAtom } from '../../store/jotai';
import { isAxiosError } from 'axios';

export const useStreams = (prefetch = true) => {
  const [, setStreams] = useAtom(streamsAtom);
  const [_getAllStreams, , _getAllStreamsRes, _getAllStreamsErr] = useRequest(getAllStreams);
  useEffect(() => {
    if (_getAllStreamsRes) setStreams(_getAllStreamsRes.Records as Stream[]);
  }, [_getAllStreamsRes, setStreams]);

  useEffect(() => {
    if (prefetch) _getAllStreams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (_getAllStreamsErr) {
        if (isAxiosError(_getAllStreamsErr)) {
          console.log('_getAllStreamsErrText: ', _getAllStreamsErr.response?.data.error);
          console.log('_getAllStreamsErrMessage: ', _getAllStreamsErr.response?.data.message);
        } else {
          console.log('_getAllStreamsErr: ', _getAllStreamsErr);
        }
      }
  }, [_getAllStreamsErr])
  return { getAllStreams: _getAllStreams };
};
