import React from 'react';
import Select from 'react-select';
import styled from 'styled-components';
import { settingSelectStyles } from '../../../styles/reactSelect';
import { CommonSelectItem } from '../../../types/model/commonSelectItem';

const appendMovieYearOptions: CommonSelectItem[] = [
  { value: 'brackets', label: 'Brackets' },
  { value: 'hyphen', label: 'Hyphen' },
  { value: 'disabled', label: 'Disabled' },
];

interface Props {
  className?: string;
  value: string;
  onChange: (value: string) => void;
}

const AppendMovieYear = ({ className, value, onChange }: Props) => {
  return (
    <AppendMovieYearComponents className={className}>
      <Select<CommonSelectItem>
        className='form-item-select'
        options={appendMovieYearOptions}
        styles={settingSelectStyles}
        value={
          appendMovieYearOptions[appendMovieYearOptions.findIndex(option => option.value === value)]
        }
        onChange={newValue => onChange(newValue?.value ?? '')}
      />
    </AppendMovieYearComponents>
  );
};

const AppendMovieYearComponents = styled.div``;

export default AppendMovieYear;
