import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ClassesStyles from './styles/ClassesStyles';
import CssClassesStyles from './styles/CssClassesStyles';
import ElementsStyles from './styles/ElementsStyles';
import GlobalStyles from './styles/GlobalStyle';
import ModalStyles from './styles/ModalStyles';
import ReactDateTimeStyles from './styles/ReactDatetimeStyles';
import ReactPopupStyle from './styles/ReactPopupStyle';
import Typography from './styles/Typography';
import VariablesStyles from './styles/VariablesStyles';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <>
    <GlobalStyles />
    <VariablesStyles />
    <Typography />
    <ElementsStyles />
    <ClassesStyles />
    <CssClassesStyles />
    <ReactDateTimeStyles />
    <ModalStyles />
    <ReactPopupStyle />
    <App />
  </>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
