import React from 'react';
import { Column, useSortBy, useTable } from 'react-table';
import styled from 'styled-components';
import { SortAscSvg, SortDescSvg, SortSvg } from '../../assets/svg';

export interface Props<T extends object> {
  columns: Column<T>[];
  data: T[];
  onClickRow?: (id: unknown) => void;
  selectedId?: unknown[];
  headFontSize?: number;
  bodyFontSize?: number;
  rowHeight?: number;
}

const FormTable = <T extends object>({
  columns,
  data,
  onClickRow,
  selectedId,
  headFontSize = 20,
  bodyFontSize = 20,
  rowHeight,
}: Props<T>) => {
  const isViewOnly = onClickRow ? false : true;

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    { columns, data },
    useSortBy,
  );

  return (
    <FormTableComponents
      $isViewOnly={isViewOnly}
      $headFontSize={headFontSize}
      $bodyFontSize={bodyFontSize}
      $rowHeight={rowHeight}
    >
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr
              {...headerGroup.getHeaderGroupProps()}
              key={headerGroup.getHeaderGroupProps()['key']}
            >
              {headerGroup.headers.map(column => {
                return (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    key={column.getHeaderProps()['key']}
                  >
                    <div className='th-container'>
                      {column.render('Header')}
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <SortDescSvg />
                        ) : (
                          <SortAscSvg />
                        )
                      ) : (
                        <SortSvg />
                      )}
                    </div>
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                key={row.getRowProps()['key']}
                onClick={() => onClickRow && onClickRow(row.values.id)}
                className={
                  selectedId ? (selectedId.includes(row.values.id) ? 'selected-row' : '') : ''
                }
              >
                {row.cells.map(cell => (
                  <td {...cell.getCellProps()} key={cell.getCellProps()['key']}>
                    {cell.render('Cell')}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </FormTableComponents>
  );
};

const FormTableComponents = styled.div<{
  $isViewOnly: boolean;
  $headFontSize: number;
  $bodyFontSize: number;
  $rowHeight?: number;
}>`
  table {
    margin: 12px 0;
    width: 100%;
    text-align: center;
    border-collapse: collapse;

    thead {
      background: var(--card-bg);
      font-size: ${props => props.$headFontSize + 'px'};

      tr {
        width: 100%;
        border-bottom: 1px solid var(--element-card-bg);
        box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 8px;

        th {
          .th-container {
            padding: 7px 0;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 4px;
          }
        }
      }
    }

    tbody {
      font-size: ${props => props.$bodyFontSize + 'px'};

      &::before {
        content: '';
        display: block;
        height: 24px;
      }

      tr {
        height: ${props => (props.$rowHeight ? props.$rowHeight + 'px' : '')};
        background: var(--card-bg);

        &:nth-of-type(odd) {
          background: var(--table-odd-row);
        }
        &.selected-row {
          background: var(--combobox-on);
        }
        &.selected-row:nth-of-type(odd) {
          background: #845bed;
        }

        &:hover {
          filter: ${props => (props.$isViewOnly ? '' : 'brightness(2)')};
          cursor: ${props => (props.$isViewOnly ? '' : 'pointer')};
        }

        td {
          padding: 4px 0;
        }
      }
    }
  }
`;

export default FormTable;
