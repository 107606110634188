import { createGlobalStyle } from 'styled-components';

const ReactPopupStyle = createGlobalStyle` 
  .popup-content{
    margin:auto;
    background: var(--dark-bg);
    width:50%;
    padding:5px;
    border:1.5px solid #ffffff;
    
    & *{
      color: var(--text-form-content);
    }
  }
    
  [role=tooltip].popup-content{
    width: fit-content;
    max-width:350px;
    box-shadow:0 0 3px rgba(0,0,0,.16);
  }
    
  .popup-overlay{
    background:rgba(0,0,0,.5);
  }
    
  [data-popup=tooltip].popup-overlay{
    background:transparent;
  }
    
  .popup-arrow{
    -webkit-filter:drop-shadow(0 -3px 3px rgba(0,0,0,.16));
    filter:drop-shadow(0 -3px 3px rgba(0,0,0,.16));
    stroke-width:3px;
    stroke:#ffffff;
    stroke-dasharray:30px;
    stroke-dashoffset:-54px;
    
    path{
      fill: #13132c;
    }
  }
`;

export default ReactPopupStyle;
